import * as actionTypes from "./actionTypes";

const defaultState = {
    token: null,
    selectedSite: null,
    selectedProject: null,
    selectedBoard: null,
    selectedSprint: null,
    selectedIssueType: null,
    selectedLabels: [],
    selectedProjectComponents: [],
    sites: [],
    projects: [],
    boards: [],
    sprints: [],
    issueTypes: [],
    labels: [],
    projectComponents: []
};

const handleSetToken = (state, { token }) => {  
    return {
        ...state,
        token
    };
};

const handleSetSelectedSite = (state, { selectedSite }) => {  
    return {
        ...state,
        selectedSite
    };
};

const handleSetSelectedProject = (state, { selectedProject }) => {  
    return {
        ...state,
        selectedProject
    };
};

const handleSetSelectedBoard = (state, { selectedBoard }) => {  
    return {
        ...state,
        selectedBoard
    };
};

const handleSetSelectedSprint = (state, { selectedSprint }) => {  
    return {
        ...state,
        selectedSprint
    };
};

const handleSetSelectedIssueType = (state, { selectedIssueType }) => {  
    return {
        ...state,
        selectedIssueType
    };
};

const handleSetSelectedLabels = (state, { selectedLabels }) => {  
    return {
        ...state,
        selectedLabels: [
            ...selectedLabels
        ]
    };
};

const handleSetSelectedProjectComponents = (state, { selectedProjectComponents }) => {  
    return {
        ...state,
        selectedProjectComponents: [
            ...selectedProjectComponents
        ]
    };
};

const handleSetSites = (state, { sites }) => {  
    return {
        ...state,
        sites: [
            ...sites
        ]
    };
};

const handleSetProjects = (state, { projects }) => {  
    return {
        ...state,
        projects: [
            ...projects
        ]
    };
};

const handleSetBoards = (state, { boards }) => {  
    return {
        ...state,
        boards: [
            ...boards
        ]
    };
};

const handleSetSprints = (state, { sprints }) => {  
    return {
        ...state,
        sprints: [
            ...sprints
        ]
    };
};

const handleSetIssueTypes = (state, { issueTypes }) => {  
    return {
        ...state,
        issueTypes: [
            ...issueTypes
        ]
    };
};

const handleSetLabels = (state, { labels }) => {  
    return {
        ...state,
        labels: [
            ...labels
        ]
    };
};

const handleSetProjectComponents = (state, { projectComponents }) => {  
    return {
        ...state,
        projectComponents: [
            ...projectComponents
        ]
    };
};

const reducers = (state = defaultState, action) => {
    switch (action.type) {
    case actionTypes.SET_TOKEN:
        return handleSetToken(state, action);   
    case actionTypes.SET_SELECTED_SITE:
        return handleSetSelectedSite(state, action);
    case actionTypes.SET_SELECTED_PROJECT:
        return handleSetSelectedProject(state, action); 
    case actionTypes.SET_SELECTED_BOARD:
        return handleSetSelectedBoard(state, action); 
    case actionTypes.SET_SELECTED_SPRINT:
        return handleSetSelectedSprint(state, action); 
    case actionTypes.SET_SELECTED_ISSUE_TYPE:
        return handleSetSelectedIssueType(state, action);    
    case actionTypes.SET_SELECTED_LABELS:
        return handleSetSelectedLabels(state, action);     
    case actionTypes.SET_SELECTED_PROJECT_COMPONENTS:
        return handleSetSelectedProjectComponents(state, action);       
    case actionTypes.SET_SITES:
        return handleSetSites(state, action);
    case actionTypes.SET_PROJECTS:
        return handleSetProjects(state, action);  
    case actionTypes.SET_BOARDS:
        return handleSetBoards(state, action);  
    case actionTypes.SET_SPRINTS:
        return handleSetSprints(state, action);      
    case actionTypes.SET_ISSUE_TYPES:
        return handleSetIssueTypes(state, action);        
    case actionTypes.SET_LABELS:
        return handleSetLabels(state, action);        
    case actionTypes.SET_PROJECT_COMPONENTS:
        return handleSetProjectComponents(state, action);     
    default:
        return state;
    }
};

export default reducers;