import * as actionTypes from "./actionTypes";

const defaultState = {
    token: null,
    selectedProject: null,
    workspaces: [],
    teams: [],
    projects: []
};

const handleSetToken = (state, { token }) => {  
    return {
        ...state,
        token
    };
};

const handleSetSelectedWorkspace = (state, { selectedWorkspace }) => {  
    return {
        ...state,
        selectedWorkspace
    };
};

const handleSetSelectedTeam = (state, { selectedTeam }) => {  
    return {
        ...state,
        selectedTeam
    };
};

const handleSetSelectedProject = (state, { selectedProject }) => {  
    return {
        ...state,
        selectedProject
    };
};

const handleSetWorkspaces = (state, { workspaces }) => {  
    return {
        ...state,
        workspaces: [
            ...workspaces
        ]
    };
};

const handleSetTeams = (state, { teams }) => {  
    return {
        ...state,
        teams: [
            ...teams
        ]
    };
};

const handleSetProjects = (state, { projects }) => {  
    return {
        ...state,
        projects: [
            ...projects
        ]
    };
};

const reducers = (state = defaultState, action) => {
    switch (action.type) {
    case actionTypes.SET_TOKEN:
        return handleSetToken(state, action);
    case actionTypes.SET_SELECTED_WORKSPACE:
        return handleSetSelectedWorkspace(state, action); 
    case actionTypes.SET_SELECTED_TEAM:
        return handleSetSelectedTeam(state, action); 
    case actionTypes.SET_SELECTED_PROJECT:
        return handleSetSelectedProject(state, action);  
    case actionTypes.SET_WORKSPACES:
        return handleSetWorkspaces(state, action); 
    case actionTypes.SET_TEAMS:
        return handleSetTeams(state, action);  
    case actionTypes.SET_PROJECTS:
        return handleSetProjects(state, action);   
    default:
        return state;
    }
};

export default reducers;