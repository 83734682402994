import React from "react";
import PropTypes from "prop-types";

import "./header.scss";

const Header = ({ children, className }) => {
    return (
        <header className={className}>
            {children}
        </header>
    );
};

Header.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

export default Header;