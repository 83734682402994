import { Fragment, ReactElement } from "react";
import { AppInsightsErrorBoundary as ErrorBoundary } from "@microsoft/applicationinsights-react-js";

import Logo from "svg/logo/neatro.svg";

import FullButton from "components/button/FullButton";
import OutlineButton from "components/button/OutlineButton";

import languages from "common/languages";

import { useAppSelector } from "infrastructure/hooks/redux";

import Header from "layout/components/Header";

import IllustrationImage from "img/listEmptyState.png";

import { reactPlugin } from "./appInsights";

import style from  "./appInsightsErrorBoundary.module.scss";

type AppInsightsErrorBoundaryProps = {
    children: ReactElement,
    language: string
}

const AppInsightsErrorBoundary = ({ children, language }: AppInsightsErrorBoundaryProps) => {
    const isMemberConnected = useAppSelector(state => state.user && state.user.id);

    const handleHeaderButtonClick = () => {
        if (!isMemberConnected) {
            window.location.href = "/login";
        }
        else {
            window.location.href = "/dashboard";
        }
    };

    return <ErrorBoundary onError={() => <div className={style.component}>
        <Header>
            <Logo className={style.neatroLogo} onClick={() => window.location.href = "/"} />
            <FullButton onClick={handleHeaderButtonClick}>
                {
                    isMemberConnected ? 
                        language === languages.french ? "Retour à l'accueil" : "Back to dashboard" : 
                        language === languages.french ? "Se connecter" : "Back to login"
                }
            </FullButton>
        </Header>
        <div className={style.container}>
            <div className={style.errorTextSection}>
                <div className={style.errorTitle}>{language === languages.french ? "Erreur" : "Error"}</div>
                <div className={style.errorText}>
                    {language === languages.french ? 
                        <Fragment>
                            Votre version de Neatro n'est pas à jour, merci de rafraichir la page pour obtenir la dernière version.<br /><br />
                            Si ce problème persiste, contactez-nous à <a style={{cursor: "pointer", textDecoration: "underline"}} href="mailto:support@neatro.io">support@neatro.io</a>
                        </Fragment>:
                        <Fragment>
                            Your Neatro version is out of date, please refresh the page to get the latest version.<br />
                            If this problem persists, you can contact us at <a style={{cursor: "pointer", textDecoration: "underline"}} href="mailto:support@neatro.io">support@neatro.io</a>
                        </Fragment>}
                </div>
                <OutlineButton onClick={() => window.location.reload()} className={style.refreshButton}>
                    {language === languages.french ? "Rafraichir la page" : "Refresh the page"}
                </OutlineButton>
            </div>
            <img src={IllustrationImage} className={style.illustration} />
        </div>
    </div>} appInsights={reactPlugin}>
        {children}
    </ErrorBoundary>;
};

export default AppInsightsErrorBoundary;