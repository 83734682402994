import { Reducer } from "redux";
import retrospectiveStatus from "common/retrospectiveStatus";

import * as CommonTypes from "types/common";

import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import GroupingSuggestionRule from "./groupingSuggestionRule";
import GroupingRestrictionRule from "./groupingRestrictionRule";
import GroupingFilteringRule from "./groupingFilteringRule";
import { LabelIds } from "./data/demoData";

import * as Types from "./types";

const defaultState: Types.Retrospective = {
    comments: [],
    commentTypes: [],
    id: "",
    activeStep: {
        id: "64095c2c-f899-482d-a521-475db0da6d6e",
        name: "collect",
        position: 0
    },
    retrospectiveTypeId: "",
    steps: [{
        id: "64095c2c-f899-482d-a521-475db0da6d6e",
        name: "collect",
        position: 0
    },
    {
        id: "5fdd9d53-7ee5-47f0-b72f-ab548e764e50",
        name: "aggregate",
        position: 1
    },
    {
        id: "d20533e1-1aa3-4be6-abc4-d88609cc5971",
        name: "vote",
        position: 2
    },
    {
        id: "2a3d438f-1e0a-4895-94ce-a27ffec4c740",
        name: "actionPlan",
        position: 3
    }],
    labels: [],
    members: [
        {
            id: "1b207c62-a597-47e6-b0eb-e8d3fa40972b",
            firstName: "Curious",
            lastName: "User",
            email: "test@test.fr",
            avatar: "https://app.neatro.io/static/media/member-avatars/10f5.png",
            color: "FF0000",
            jobTitle: "",
            language: "",
            isAdministrator: true,
            isFacilitator: true,
            isCollectDone: false,
            totalVoteCount: 0,
            connected: true
        }
    ],
    actionItems: [],
    teamLabels: [
        {
            id: LabelIds.Collaboration,
            color: "D2E7E8",
            name: "collaboration",
            count: 0
        },
        {
            id: LabelIds.Fun,
            color: "D5EBE3",
            name: "fun",
            count: 0
        },
        {
            id: LabelIds.Learnings,
            color: "F5E1CE",
            name: "learning",
            count: 0
        },
        {
            id: LabelIds.Mission,
            color: "FFE6E0",
            name: "mission",
            count: 0
        },
        {
            id: LabelIds.Ownership,
            color: "DFE7C5",
            name: "ownership",
            count: 0
        },
        {
            id: LabelIds.Process,
            color: "E4E5DE",
            name: "processes",
            count: 0
        },
        {
            id: LabelIds.Resources,
            color: "E6EBEF",
            name: "resources",
            count: 0
        },
        {
            id: LabelIds.Roles,
            color: "E9E7F1",
            name: "roles",
            count: 0
        },
        {
            id: "80ca7649-4a6b-4bb1-a039-e15486fe4670",
            color: "CFE9F8",
            name: "speed",
            count: 0
        },
        {
            id: LabelIds.Value,
            color: "FAE5B5",
            name: "value",
            count: 0
        }
    ],
    timerEnd: undefined,
    timerSound: 0,
    timerPause: undefined,
    maxVoteCount: 3,
    filter: {
        isTopicView: false,
        commentTypeIds: [],
        minVoteCount: 1
    },
    team: {
        id: Math.random().toString(),
        avatar: "",
        name: "Team Demo",
        organization: {
            id: Math.random().toString(),
            name: "Orga Demo"
        },
        members: []
    },
    selectedCommentTypeId: undefined,
    commentTypeInputs: [],
    roti: {
        isWaitingForAnswer: true,
        isSkipped: false,
        noteCount: 0
    },
    isClosing: false,
    groupingRestrictionRule: GroupingRestrictionRule.Everyone,
    groupingSuggestionRule: GroupingSuggestionRule.SameColumn,
    groupingFilteringRule: GroupingFilteringRule.Facilitators,
    creationDate: new Date(),
    isAnonymous: false,
    isCustomRetrospectiveType: false,
    isIcebreakerEnabled: true,
    name: "",
    icebreaker: {
        selectedQuestionCategoryIds: [
            "76bb677c-4496-4115-aafc-295a0bd9e6ed",
            "84c30274-6ea8-4c72-942f-6483f77ee1a8",
            "b0d81453-1171-4c9c-a12c-16409a1e0d0c",
            "de38f213-10b9-43a4-8c6b-264ad0ced953"
        ],
        isDone: false
    },
    isDarkMode: false,
    areCommentsHidden: true,
    status: retrospectiveStatus.inProgress,
    retrospectiveType: "",
    background: "",
    member: {
        id: "1b207c62-a597-47e6-b0eb-e8d3fa40972b",
        firstName: "Curious",
        lastName: "User",
        email: "test@test.fr",
        avatar: "https://app.neatro.io/static/media/member-avatars/10f5.png",
        color: "FF0000",
        jobTitle: "",
        language: "",
        isAdministrator: true,
        isFacilitator: true
    }
};

const handleSuggestParentId = (state: Types.Retrospective, { childId, parentId, isSameColumn }: ReturnType<typeof actions.suggestParentId>): Types.Retrospective => {
    return {
        ...state,
        comments: state.comments.map((comment) => {
            if (comment.id === childId && !comment.parentCommentId) {
                if (isSameColumn) {
                    comment.sameCommentTypeSuggestedParentCommentId = parentId;
                }
                else if (!comment.parentCommentId) {
                    comment.allCommentTypesSuggestedParentCommentId = parentId;
                }
            }

            return comment;
        })
    };
};

const handleSkipRoti  = (state: Types.Retrospective) => {
    return {
        ...state,
        roti: {
            ...state.roti,
            isWaitingForAnswer: false,
            isSkipped: true
        }
    };
};

const handleAddRotiNote = (state: Types.Retrospective, { note, comment }: ReturnType<typeof actions.addRotiNote>): Types.Retrospective => {
    return {
        ...state,
        roti: {
            ...state.roti,
            noteCount: state.roti.noteCount + 1,
            rotiAnswers: state.roti.rotiAnswers ? [
                ...state.roti.rotiAnswers,
                {
                    note,
                    comment
                }
            ] :
                [
                    {
                        note,
                        comment
                    }
                ] 
        }
    };
};

const handleSeedRetrospective = (state: Types.Retrospective, { retrospective }: ReturnType<typeof actions.seedRetrospective>): Types.Retrospective => {
    return {
        ...defaultState,
        commentTypes: retrospective.commentTypes,
        name: retrospective.name,
        retrospectiveTypeId: retrospective.retrospectiveTypeId,
        retrospectiveType: retrospective.retrospectiveType,
        filter: retrospective.filter,
        commentTypeInputs: retrospective.commentTypeInputs,
        background: retrospective.background,
        member: retrospective.member,
        members: [ {
            ...retrospective.member,
            isAdministrator: true,
            isFacilitator: true,
            isCollectDone: false,
            totalVoteCount: 0,
            connected: true
        } ],
        team: {
            ...defaultState.team,
            members: [ {
                ...retrospective.member,
                isAdministrator: true,
                isFacilitator: true
            } ]
        }
    };
};

const handleJoinRetrospective = (state: Types.Retrospective, { member }: ReturnType<typeof actions.joinRetrospective>): Types.Retrospective => {
    let isMemberUpdated = false;

    const members = state.members.map(x => {
        if (x.id === member.id) {
            x.connected = true;
            isMemberUpdated = true;
        }

        return x;
    });

    if (!isMemberUpdated) {
        members.push({
            id: member.id,
            firstName: member.firstName,
            lastName: member.lastName,
            color: member.color,
            email: member.email,
            avatar: member.avatar,
            connected: true,
            totalVoteCount: 0,
            isCollectDone: false,
            language: "",
            isAdministrator: false,
            isFacilitator: false,
            jobTitle: ""
        });
    }

    const teamMembers = [...state.team.members] as Types.RetrospectiveMember[];

    if (teamMembers.every(x => x.id !== member.id)) {
        teamMembers.push({
            id: member.id,
            firstName: member.firstName,
            lastName: member.lastName,
            color: member.color,
            email: member.email,
            avatar: member.avatar,
            connected: true,
            totalVoteCount: 0,
            isCollectDone: false,
            language: "",
            isAdministrator: false,
            isFacilitator: false,
            jobTitle: ""
        });
    }
    
    return {
        ...state,
        members,
        team: {
            ...state.team,
            members: teamMembers
        }
    };
};

const handleLeaveRetrospective = (state: Types.Retrospective, { memberId }: ReturnType<typeof actions.leaveRetrospective>): Types.Retrospective => {
    const members = state.members.map(member => {
        if (member.id === memberId) {
            member.connected = false;
        }

        return member;
    });
    
    return {
        ...state,
        members
    };
};

const handleAddComment = (state: Types.Retrospective, { commentId, text, commentTypeId, isAnonymous, memberId, authorColor, authorFirstName, authorLastName, authorEmail, isMemberComment, isNew, authorAvatar }: ReturnType<typeof actions.addComment>): Types.Retrospective => {
    const comment = state.comments.find(x => x.id === commentId);

    if (comment) {
        return state;
    }
    
    const commentTypeComments = state.comments.filter(x => x.commentTypeId === commentTypeId);
    const commentTypeName = state.commentTypes.find(x => x.id === commentTypeId)?.name || "";

    return {
        ...state,
        comments: [
            {
                id: commentId,
                text,
                commentTypeId,
                commentTypeName,
                isAnonymous,
                memberId,
                authorColor,
                authorFirstName,
                authorLastName,
                authorEmail,
                authorAvatar,
                isMemberComment,
                labels: [],
                isNew,
                position: 0,
                totalVoteCount: 0,
                myVoteCount: 0
            },
            ...state.comments.filter(x => x.commentTypeId !== commentTypeId),
            ...commentTypeComments.map(x => ({
                ...x,
                position: x.position + 1
            }))
        ]
    };
};

const handleUpdateComment = (state: Types.Retrospective, { commentId, text, isAnonymous, memberId, memberColor, memberFirstName, memberLastName, memberEmail, memberAvatar }: ReturnType<typeof actions.updateComment>) => {
    return {
        ...state,
        comments: state.comments.map(comment => {
            if (comment.id === commentId) {
                comment.text = text;
                comment.isAnonymous = isAnonymous;
                comment.memberId = memberId;
                comment.authorColor = memberColor;
                comment.authorFirstName = memberFirstName;
                comment.authorLastName = memberLastName;
                comment.authorEmail = memberEmail;
                comment.authorAvatar = memberAvatar;
            }

            return { ...comment };
        })
    };
};

const handleUpdateActiveStep = (state: Types.Retrospective, { activeStepId }: ReturnType<typeof actions.updateActiveStep>): Types.Retrospective => {
    const activeStep = state.steps.find((step) => step.id === activeStepId);

    if (!activeStep) return state;

    return {
        ...state,
        activeStep: {
            ...activeStep
        }
    };
};

const handleRevealComments = (state: Types.Retrospective, { activeStepId }: ReturnType<typeof actions.revealComments>): Types.Retrospective => {
    const activeStep = state.steps.find((step) => step.id === activeStepId);

    if (!activeStep) return state;

    return {
        ...state,
        areCommentsHidden: false,
        activeStep: {
            ...activeStep
        }
    };
};

const handleDeleteComment = (state: Types.Retrospective, { commentId }: ReturnType<typeof actions.deleteComment>): Types.Retrospective => {
    const comments = [];

    const comment = state.comments.find(x => x.id === commentId);

    if (!comment) return state;

    comments.push(...state.comments.filter(x => x.commentTypeId !== comment.commentTypeId));

    const sourceComments = state.comments.filter(x => x.commentTypeId === comment.commentTypeId);

    sourceComments.splice(comment.position, 1);

    sourceComments.forEach((comment, index) => {
        comments.push({ ...comment,
            position: index
        });
    });

    return {
        ...state,
        comments: comments.map(x => {
            if (x.parentCommentId === commentId) {
                x.parentCommentId = undefined;
            }

            return x;
        })
    };
};

const handleMoveComment = (state: Types.Retrospective, { newCommentTypeId, newPosition, commentId }: ReturnType<typeof actions.moveComment>): Types.Retrospective => {
    const targetComment: CommonTypes.Comment | undefined = state.comments.find(x => x.id === commentId);

    if (!targetComment) return state;

    const oldCommentTypeId = targetComment.commentTypeId;
    const oldPosition = targetComment.position;

    const sourceComments = state.comments.filter(comment => comment.commentTypeId === oldCommentTypeId);

    let targetComments = sourceComments;

    if (oldCommentTypeId !== newCommentTypeId) {
        targetComments = state.comments.filter(comment => comment.commentTypeId === newCommentTypeId);
    }
    
    const [removed] = sourceComments.splice(oldPosition, 1);
    targetComments.splice(newPosition, 0, removed);

    const orderedSourceComments: CommonTypes.Comment[] = [];
    const orderedTargetComments: CommonTypes.Comment[] = [];

    if (oldCommentTypeId !== newCommentTypeId) {
        sourceComments.forEach((comment, index) => {
            orderedSourceComments.push({ ...comment,
                position: index,
                isNew: false
            });
        });
    }

    targetComments.forEach((comment, index) => {
        orderedTargetComments.push({ ...comment,
            position: index,
            commentTypeId: newCommentTypeId,
            commentTypeName: state.commentTypes.find(x => x.id === newCommentTypeId)?.name || "",
            isNew: false
        });
    });

    const comments = [
        ...state.comments.filter(comment => comment.commentTypeId !== oldCommentTypeId && comment.commentTypeId !== newCommentTypeId),
        ...orderedSourceComments,
        ...orderedTargetComments
    ];

    return {
        ...state,
        comments
    };
};

const handleAddLabel = (state: Types.Retrospective, { id, color, name, teamId }: ReturnType<typeof actions.addLabel>): Types.Retrospective => {
    if (state.teamLabels.some(x => x.id === id)) {
        return {
            ...state
        };
    }
    
    return {
        ...state,
        teamLabels: [
            ...state.teamLabels,
            {
                id,
                color,
                name,
                teamId,
                count: 0
            }
        ]
    };
};

const handleDeleteLabel = (state: Types.Retrospective, { labelId }: ReturnType<typeof actions.deleteLabel>): Types.Retrospective => {
    return {
        ...state,
        teamLabels: state.teamLabels.filter(x => x.id !== labelId)
    };
};

const handleAddLabelToComment = (state: Types.Retrospective, { commentId, labelId }: ReturnType<typeof actions.addLabelToComment>): Types.Retrospective => {
    const label = state.teamLabels.find(l => l.id === labelId);

    if (!label) return state;

    const isLabelInRetrospective = state.labels.find(l => l.id === labelId);

    const labels = isLabelInRetrospective ? state.labels.map(x => {
        if (x.id === labelId) {
            x.count++;
        }

        return x;
    }) : [
        ...state.labels,
        {
            ...label,
            votes: [],
            count: 1
        }
    ];

    return {
        ...state,
        comments: state.comments.map(comment => {
            if (comment.id === commentId || comment.parentCommentId === commentId) {
                if (!comment.labels.some(x => x.id === label?.id)) {
                    comment.labels = [
                        ...comment.labels,
                        {
                            ...label,
                            votes: []
                        }
                    ].sort((a, b) => {
                        if (a.name === undefined || b.name === undefined) { return 0; }
                        if (a.name.toUpperCase() < b.name.toUpperCase()) { return -1; }
                        if (a.name.toUpperCase() > b.name.toUpperCase()) { return 1; }
    
                        return 0;
                    });
                }
            }

            return { ...comment };
        }),
        labels
    };
};

const handleRemoveLabelFromComment = (state: Types.Retrospective, { commentId, labelId }: ReturnType<typeof actions.removeLabelFromComment>): Types.Retrospective => {
    const labels = state.labels.filter(label => {
        if (label.id === labelId) {
            if (label.count > 1) {
                label.count--;

                return true;
            }
        }
        else {
            return true;
        }

        return false;
    });

    return {
        ...state,
        comments: state.comments.map(comment => {
            if (comment.id === commentId || comment.parentCommentId === commentId) {
                comment.labels = comment.labels.filter(label => label.id !== labelId);
            }

            return { ...comment };
        }),
        labels
    };
};

const handleAddVoteToComment = (state: Types.Retrospective, { commentId, memberId }: ReturnType<typeof actions.addVoteToComment>): Types.Retrospective => {
    return {
        ...state,
        comments: state.comments.map(comment => {
            if (comment.id === commentId) {
                comment.myVoteCount++;
                comment.totalVoteCount++;
            }

            return { ...comment };
        }),
        members: state.members.map(member => {
            if (member.id === memberId) {
                member.totalVoteCount++;
            }

            return member;
        })
    };
};

const handleSpendMemberVote = (state: Types.Retrospective, { commentId, memberId }: ReturnType<typeof actions.addVoteToComment>): Types.Retrospective => {
    const memberVotes = state.members.find(x => x.id === memberId)?.totalVoteCount;

    if (memberVotes === state.maxVoteCount) {
        return state;
    }
    
    return {
        ...state,
        comments: state.comments.map(comment => {
            if (comment.id === commentId) {
                comment.totalVoteCount++;
            }

            return { ...comment };
        }),
        members: state.members.map(member => {
            if (member.id === memberId) {
                member.totalVoteCount++;
            }

            return member;
        })
    };
};

const handleRemoveVoteFromComment = (state: Types.Retrospective, { commentId, memberId }: ReturnType<typeof actions.removeVoteFromComment>): Types.Retrospective => {
    return {
        ...state,
        comments: state.comments.map(comment => {
            if (comment.id === commentId) {
                comment.myVoteCount--;
                comment.totalVoteCount--;
            }

            return { ...comment };
        }),
        members: state.members.map(member => {
            if (member.id === memberId) {
                member.totalVoteCount--;
            }

            return member;
        })
    };
};

const handleAddActionItem = (state: Types.Retrospective, { text, memberId, memberFirstName, memberLastName, memberColor, memberEmail, labelId, labelName, labelColor, labelTeamId, commentTypeId, memberAvatar, description, dueDate, comments }: ReturnType<typeof actions.addActionItem>): Types.Retrospective => {
    return {
        ...state,
        actionItems: [
            {
                id: (memberFirstName || "") + memberLastName + memberColor + memberEmail + text,
                text,
                commentTypeId,
                status: 0,
                memberId: memberId || undefined,
                member: {
                    id: memberId || "",
                    firstName: memberFirstName || "",
                    lastName: memberLastName || "",
                    color: memberColor || "",
                    email: memberEmail || "",
                    avatar: memberAvatar || "",
                    language: "",
                    isAdministrator: false,
                    isFacilitator: false,
                    jobTitle: ""
                },
                labelId: labelId || undefined,
                label: labelId ? {
                    id: labelId,
                    name: labelName || "",
                    color: labelColor || "",
                    teamId: labelTeamId || "",
                    count: 0
                } : undefined,
                description,
                dueDate,
                commentIds: comments ? comments.map(x => x.id) : [],
            },
            ...state.actionItems
        ]
    };
};

const handleUpdateActionItem = (state: Types.Retrospective, 
    { actionItemId, text, memberId, memberFirstName, memberLastName, memberColor, memberEmail, labelId, labelName, labelColor, labelTeamId, memberAvatar, description, dueDate, comments }: ReturnType<typeof actions.updateActionItem>): Types.Retrospective => {
    return {
        ...state,
        actionItems: [
            ...state.actionItems.map(actionItem => {
                if (actionItem.id === actionItemId) {
                    actionItem.text = text;
                    actionItem.description = description;
                    actionItem.dueDate = dueDate;
                    actionItem.memberId = memberId || undefined;
                    actionItem.member = {
                        id: memberId || "",
                        firstName: memberFirstName|| "",
                        lastName: memberLastName|| "",
                        color: memberColor|| "",
                        email: memberEmail|| "",
                        avatar: memberAvatar,
                        language: "",
                        isAdministrator: false,
                        isFacilitator: false,
                        jobTitle: ""
                    };

                    actionItem.labelId = labelId || undefined;

                    actionItem.commentIds = comments ? comments.map(x => x.id) : [];

                    if (labelId) {
                        actionItem.label = {
                            id: labelId,
                            name: labelName || "",
                            color: labelColor || "",
                            teamId: labelTeamId || "",
                            count: 0
                        };
                    }
                    else {
                        actionItem.label = undefined;
                    }
                }

                return actionItem;
            })
        ]
    };
};

const handleDeleteActionItem = (state: Types.Retrospective, { actionItemId }: ReturnType<typeof actions.deleteActionItem>): Types.Retrospective => {
    return {
        ...state,
        actionItems: [...state.actionItems.filter(actionItem => actionItem.id !== actionItemId)]
    };
};

const handleStackComment = (state: Types.Retrospective, { sourceCommentId, targetCommentId }: ReturnType<typeof actions.stackComment>): Types.Retrospective => {  
    const sourceComment = state.comments.find(x => x.id === sourceCommentId);
    const targetComment = state.comments.find(x => x.id === targetCommentId);

    let sourceCommentLabels: CommonTypes.Label[] = [];
    let targetCommentLabels: CommonTypes.Label[] = [];

    if (sourceComment) {
        sourceCommentLabels = sourceComment.labels;
    }
    else {
        return state;
    }

    if (targetComment) {
        targetCommentLabels = targetComment.labels;
    }
    else {
        return state;
    }

    return {
        ...state,
        comments: state.comments.map(comment => {
            if (comment.id === sourceCommentId) {
                comment.parentCommentId = targetCommentId;
    
                targetCommentLabels.forEach(targetLabel => {
                    if (!comment.labels.some(x => x.id === targetLabel.id)) {
                        comment.labels.push(targetLabel);
                    }
                });
            }
    
            if (comment.id === targetCommentId) {
                comment.parentCommentId = undefined;
    
                sourceCommentLabels.forEach(sourceLabel => {
                    if (!comment.labels.some(x => x.id === sourceLabel.id)) {
                        comment.labels.push(sourceLabel);
                    }
                });
            }
    
            if (comment.parentCommentId === sourceCommentId) {
                comment.parentCommentId = targetCommentId;
            }
    
            return { ...comment };
        })
    };
};

const handleUnstackComment = (state: Types.Retrospective, { commentId }: ReturnType<typeof actions.unstackComment>): Types.Retrospective => {  
    return {
        ...state,
        comments: state.comments.map(comment => {
            if (comment.id === commentId) {
                comment.parentCommentId = undefined;
            }

            return { ...comment };
        })
    };
};

const handleChangeMaxVoteCount = (state: Types.Retrospective, { maxVoteCount }: ReturnType<typeof actions.changeMaxVoteCount>): Types.Retrospective => {
    return {
        ...state,
        maxVoteCount
    };
};

const handleUpdateRetrospectiveName = (state: Types.Retrospective, { name }: ReturnType<typeof actions.updateRetrospectiveName>): Types.Retrospective => {
    return {
        ...state,
        name
    };
};

const handleUpdateLabelFilter = (state: Types.Retrospective, { labelId }: ReturnType<typeof actions.updateLabelFilter>): Types.Retrospective => {
    return {
        ...state,
        filter: {
            ...state.filter,
            labelId,
            commentTypeId: undefined
        }
    };
};

const handleUpdateMemberFilter = (state: Types.Retrospective, { memberId }: ReturnType<typeof actions.updateMemberFilter>): Types.Retrospective => {
    return {
        ...state,
        filter: {
            ...state.filter,
            memberId
        }
    };
};

const handleUpdateCommentTypeFilter = (state: Types.Retrospective, { commentTypeId }: ReturnType<typeof actions.updateCommentTypeFilter>): Types.Retrospective => {
    return {
        ...state,
        filter: {
            ...state.filter,
            commentTypeId,
            labelId: undefined
        }
    };
};

const handleUpdateCommentTypesFilter = (state: Types.Retrospective, { commentTypeIds }: ReturnType<typeof actions.updateCommentTypesFilter>): Types.Retrospective => {  
    return {
        ...state,
        filter: {
            ...state.filter,
            commentTypeIds
        }
    };
};

const handleUpdateTopicViewFilter = (state: Types.Retrospective, { isTopicView }: ReturnType<typeof actions.updateTopicViewFilter>): Types.Retrospective => {
    return {
        ...state,
        filter: {
            ...state.filter,
            isTopicView,
            labelId: undefined,
            commentTypeId: undefined
        }
    };
};

const handleUpdateMinVoteCountFilter = (state: Types.Retrospective, { minVoteCount }: ReturnType<typeof actions.updateMinVoteCountFilter>): Types.Retrospective => {
    return {
        ...state,
        filter: {
            ...state.filter,
            minVoteCount
        }
    };
};

const handleRemoveMemberFromRetrospective = (state: Types.Retrospective, { memberId }: ReturnType<typeof actions.removeMemberFromRetrospective>): Types.Retrospective => {
    return {
        ...state,
        members: state.members.filter(x => x.id !== memberId)
    };
};

const handleMemberCollectDone = (state: Types.Retrospective, { memberId, isCollectDone }: ReturnType<typeof actions.updateMemberCollectDone>): Types.Retrospective => {
    return {
        ...state,
        members: state.members.map(x => {
            if (x.id === memberId) {
                x.isCollectDone = isCollectDone;
            }

            return x;
        })
    };
};

const handleUpdateIcebreakerStatus = (state: Types.Retrospective, { isDone }: ReturnType<typeof actions.updateIcebreakerStatus>): Types.Retrospective => {
    return {
        ...state,
        icebreaker: {
            ...state.icebreaker,
            isDone
        }
    };
};

const handleUpdateIcebreakerQuestion = (state: Types.Retrospective, { questionId }: ReturnType<typeof actions.updateIcebreakerQuestion>): Types.Retrospective => {
    return {
        ...state,
        icebreaker: {
            ...state.icebreaker,
            questionId
        }
    };
};

const handleCloseRetrospective = (state: Types.Retrospective): Types.Retrospective => {
    return {
        ...state,
        status: retrospectiveStatus.closed,
        isClosing: true
    };
};

const handleHighlightComment = (state: Types.Retrospective, { commentId, memberId, memberColor, memberFirstName, memberLastName, memberEmail, memberAvatar }: ReturnType<typeof actions.highlightComment>): Types.Retrospective => {
    return {
        ...state,
        comments: state.comments.map(comment => {
            if (comment.id === commentId) {
                comment.highlightMember = memberId ? {
                    color: memberColor,
                    firstName: memberFirstName,
                    lastName: memberLastName,
                    email: memberEmail,
                    avatar: memberAvatar,
                    id: memberId,
                    language: "",
                    isAdministrator: false,
                    isFacilitator: false,
                    jobTitle: ""
                } : undefined;
            }
            else if (comment.highlightMember && comment.highlightMember.id === memberId) {
                comment.highlightMember = undefined;
            }

            return { ...comment };
        })
    };
};

const handleChangeCommentTypeInputText = (state: Types.Retrospective, { commentTypeId, text }: ReturnType<typeof actions.changeCommentTypeInputText>): Types.Retrospective => {
    return {
        ...state,
        commentTypeInputs: state.commentTypeInputs.map(x => {
            if (x.commentTypeId === commentTypeId) {
                x.text = text;
            }

            return x;
        })
    };
};

const handleSelectCommentType = (state: Types.Retrospective, { commentTypeId }: ReturnType<typeof actions.selectCommentType>): Types.Retrospective => {
    return {
        ...state,
        selectedCommentTypeId: commentTypeId
    };
};

const handleUnselectCommentType = (state: Types.Retrospective, { commentTypeId }: ReturnType<typeof actions.unselectCommentType>): Types.Retrospective => {
    return {
        ...state,
        selectedCommentTypeId: state.selectedCommentTypeId === commentTypeId ? undefined : state.selectedCommentTypeId
    };
};

const handleResetVotes = (state: Types.Retrospective) => {
    return {
        ...state,
        comments: state.comments.map(comment => {
            comment.totalVoteCount = 0;
            comment.myVoteCount = 0;

            return { ...comment };
        }),
        members: state.members.map(member => {
            member.totalVoteCount = 0;

            return member;
        })
    };
};

const handleStartTimer = (state: Types.Retrospective, { timerEnd, timerSound }: ReturnType<typeof actions.startTimer>): Types.Retrospective => {
    return {
        ...state,
        timerEnd,
        timerSound
    };
};

const handleStopTimer = (state: Types.Retrospective): Types.Retrospective => {
    return {
        ...state,
        timerEnd: undefined
    };
};

const handlePauseTimer = (state: Types.Retrospective, { timerPause }: ReturnType<typeof actions.pauseTimer>): Types.Retrospective => {
    return {
        ...state,
        timerPause
    };
};

const handleResumeTimer = (state: Types.Retrospective, { timerEnd }: ReturnType<typeof actions.resumeTimer>): Types.Retrospective => {
    return {
        ...state,
        timerEnd,
        timerPause: undefined
    };
};

const handleChangeGroupingRestrictionRule = (state: Types.Retrospective, { rule }: ReturnType<typeof actions.changeGroupingRestrictionRule>): Types.Retrospective => {
    return {
        ...state,
        groupingRestrictionRule: rule
    };
};

const handleChangeGroupingSuggestionRule = (state: Types.Retrospective, { rule }: ReturnType<typeof actions.changeGroupingSuggestionRule>): Types.Retrospective => {
    return {
        ...state,
        groupingSuggestionRule: rule
    };
};

const handleChangeGroupingFilteringRule = (state: Types.Retrospective, { rule }: ReturnType<typeof actions.changeGroupingFilteringRule>): Types.Retrospective => {
    return {
        ...state,
        groupingFilteringRule: rule
    };
};

const handleChangeGroupSuggestionStatus = (state: Types.Retrospective, { parentCommentId, isApproved }: ReturnType<typeof actions.changeGroupSuggestionStatus>): Types.Retrospective => {
    if (state.groupingSuggestionRule === GroupingSuggestionRule.None) return state;

    const parentComment = state.comments.find(x => x.id === parentCommentId);

    if (!parentComment) return state;

    var childIdsToReset: string[] = [];

    const comments = state.comments.map(x => {
        if (x.id === parentCommentId) {
            x.allCommentTypesSuggestedParentCommentId = undefined;
            x.sameCommentTypeSuggestedParentCommentId = undefined;
            x.parentCommentId = undefined;
        }

        if ((x.allCommentTypesSuggestedParentCommentId === parentCommentId && state.groupingSuggestionRule === GroupingSuggestionRule.AllColumns) || 
            (x.sameCommentTypeSuggestedParentCommentId === parentCommentId && state.groupingSuggestionRule === GroupingSuggestionRule.SameColumn)) {
            if (isApproved && parentComment) {
                const commentChildrenIds = state.comments.filter(y => y.parentCommentId === x.id).map(y => y.id);

                commentChildrenIds.forEach(childId => {
                    childIdsToReset.push(childId);
                });

                x.parentCommentId = parentComment.id;
            }

            x.allCommentTypesSuggestedParentCommentId = undefined;
            x.sameCommentTypeSuggestedParentCommentId = undefined;
        }

        return x;
    });

    return {
        ...state,
        comments: comments.map(x => {
            if (childIdsToReset.some(y => y === x.id)) {
                x.parentCommentId = undefined;
            }

            return x;
        })
    };
};

const handleRemoveGroupSuggestionComment = (state: Types.Retrospective, { commentId }: ReturnType<typeof actions.removeGroupSuggestionComment>): Types.Retrospective => {
    if (state.groupingSuggestionRule === GroupingSuggestionRule.None) return state;

    const comment = state.comments.find(x => x.id === commentId);

    let newParentId: string | undefined = undefined;

    if (comment && !comment.allCommentTypesSuggestedParentCommentId && state.groupingSuggestionRule === GroupingSuggestionRule.AllColumns) {
        const children = state.comments.filter(x => x.allCommentTypesSuggestedParentCommentId === comment.id);

        if (children.length > 1) {
            newParentId = children[0].id;
        }
    }

    if (comment && !comment.sameCommentTypeSuggestedParentCommentId && state.groupingSuggestionRule === GroupingSuggestionRule.SameColumn) {
        const children = state.comments.filter(x => x.sameCommentTypeSuggestedParentCommentId === comment.id);

        if (children.length > 1) {
            newParentId = children[0].id;
        }
    }

    const comments = state.comments.map(x => {
        if (state.groupingSuggestionRule === GroupingSuggestionRule.AllColumns) {
            if (x.allCommentTypesSuggestedParentCommentId === commentId) {
                x.allCommentTypesSuggestedParentCommentId = newParentId;
            }

            if (x.id === newParentId || x.id === commentId) {
                x.allCommentTypesSuggestedParentCommentId = undefined;
            }
        }

        if (state.groupingSuggestionRule === GroupingSuggestionRule.SameColumn) {
            if (x.sameCommentTypeSuggestedParentCommentId === commentId) {
                x.sameCommentTypeSuggestedParentCommentId = newParentId;
            }

            if (x.id === newParentId|| x.id === commentId) {
                x.sameCommentTypeSuggestedParentCommentId = undefined;
            }
        }

        return x;
    });

    return {
        ...state,
        comments
    };
};

const handleUpdateIcebreakerSelectedQuestionCategories = (state: Types.Retrospective, { selectedQuestionCategoryIds }: ReturnType<typeof actions.updateIcebreakerSelectedQuestionCategories>): Types.Retrospective => {
    return {
        ...state,
        icebreaker: {
            ...state.icebreaker,
            selectedQuestionCategoryIds: selectedQuestionCategoryIds
        }
    };
};

const reducers : Reducer<Types.Retrospective, any> = (state = defaultState, action: any) => {
    switch (action.type) {      
    case actionTypes.SUGGEST_PARENT_ID:
        return handleSuggestParentId(state, action);  
    case actionTypes.SKIP_ROTI:
        return handleSkipRoti(state);        
    case actionTypes.ADD_ROTI_NOTE:
        return handleAddRotiNote(state, action);        
    case actionTypes.SPEND_MEMBER_VOTE:
        return handleSpendMemberVote(state, action);    
    case actionTypes.SEED_RETROSPECTIVE:
        return handleSeedRetrospective(state, action);    
    case actionTypes.JOIN_RETROSPECTIVE:
        return handleJoinRetrospective(state, action);   
    case actionTypes.LEAVE_RETROSPECTIVE:
        return handleLeaveRetrospective(state, action);
    case actionTypes.ADD_COMMENT:
        return handleAddComment(state, action);
    case actionTypes.UPDATE_COMMENT:
        return handleUpdateComment(state, action);
    case actionTypes.DELETE_COMMENT:
        return handleDeleteComment(state, action);
    case actionTypes.MOVE_COMMENT:
        return handleMoveComment(state, action);
    case actionTypes.UPDATE_ACTIVE_STEP:
        return handleUpdateActiveStep(state, action);
    case actionTypes.ADD_LABEL:
        return handleAddLabel(state, action);
    case actionTypes.DELETE_LABEL:
        return handleDeleteLabel(state, action);
    case actionTypes.ADD_LABEL_TO_COMMENT:
        return handleAddLabelToComment(state, action);
    case actionTypes.REMOVE_LABEL_FROM_COMMENT:
        return handleRemoveLabelFromComment(state, action);
    case actionTypes.ADD_VOTE_TO_COMMENT:
        return handleAddVoteToComment(state, action);
    case actionTypes.REMOVE_VOTE_FROM_COMMENT:
        return handleRemoveVoteFromComment(state, action);
    case actionTypes.ADD_ACTION_ITEM:
        return handleAddActionItem(state, action);
    case actionTypes.UPDATE_ACTION_ITEM:
        return handleUpdateActionItem(state, action);
    case actionTypes.DELETE_ACTION_ITEM:
        return handleDeleteActionItem(state, action);
    case actionTypes.STACK_COMMENT:
        return handleStackComment(state, action);
    case actionTypes.UNSTACK_COMMENT:
        return handleUnstackComment(state, action);
    case actionTypes.CHANGE_MAX_VOTE_COUNT:
        return handleChangeMaxVoteCount(state, action);
    case actionTypes.UPDATE_RETROSPECTIVE_NAME:
        return handleUpdateRetrospectiveName(state, action);
    case actionTypes.UPDATE_LABEL_FILTER:
        return handleUpdateLabelFilter(state, action);
    case actionTypes.UPDATE_MEMBER_FILTER:
        return handleUpdateMemberFilter(state, action);
    case actionTypes.UPDATE_COMMENT_TYPE_FILTER:
        return handleUpdateCommentTypeFilter(state, action);
    case actionTypes.UPDATE_TOPIC_VIEW_FILTER:
        return handleUpdateTopicViewFilter(state, action);
    case actionTypes.UPDATE_COMMENT_TYPES_FILTER:
        return handleUpdateCommentTypesFilter(state, action);
    case actionTypes.UPDATE_MIN_VOTE_COUNT_FILTER:
        return handleUpdateMinVoteCountFilter(state, action);
    case actionTypes.REMOVE_MEMBER_FROM_RETROSPECTIVE:
        return handleRemoveMemberFromRetrospective(state, action);
    case actionTypes.REVEAL_COMMENTS:
        return handleRevealComments(state, action);
    case actionTypes.UPDATE_MEMBER_COLLECT_DONE:
        return handleMemberCollectDone(state, action);
    case actionTypes.UPDATE_ICEBREAKER_STATUS:
        return handleUpdateIcebreakerStatus(state, action);
    case actionTypes.UPDATE_ICEBREAKER_QUESTION:
        return handleUpdateIcebreakerQuestion(state, action);
    case actionTypes.CLOSE_RETROSPECTIVE:
        return handleCloseRetrospective(state);
    case actionTypes.HIGHLIGHT_COMMENT:
        return handleHighlightComment(state, action);
    case actionTypes.CHANGE_COMMENT_TYPE_INPUT_TEXT:
        return handleChangeCommentTypeInputText(state, action);
    case actionTypes.SELECT_COMMENT_TYPE:
        return handleSelectCommentType(state, action);
    case actionTypes.UNSELECT_COMMENT_TYPE:
        return handleUnselectCommentType(state, action);
    case actionTypes.RESET_VOTES:
        return handleResetVotes(state);
    case actionTypes.START_TIMER:
        return handleStartTimer(state, action);
    case actionTypes.STOP_TIMER:
        return handleStopTimer(state);
    case actionTypes.PAUSE_TIMER:
        return handlePauseTimer(state, action);
    case actionTypes.RESUME_TIMER:
        return handleResumeTimer(state, action);
    case actionTypes.CHANGE_GROUPING_RESTRICTION_RULE:
        return handleChangeGroupingRestrictionRule(state, action);
    case actionTypes.CHANGE_GROUPING_SUGGESTION_RULE:
        return handleChangeGroupingSuggestionRule(state, action);
    case actionTypes.CHANGE_GROUPING_FILTERING_RULE:
        return handleChangeGroupingFilteringRule(state, action);
    case actionTypes.CHANGE_GROUP_SUGGESTION_STATUS:
        return handleChangeGroupSuggestionStatus(state, action);
    case actionTypes.REMOVE_GROUP_SUGGESTION_COMMENT:
        return handleRemoveGroupSuggestionComment(state, action);
    case actionTypes.UPDATE_ICEBREAKER_SELECTED_QUESTION_CATEGORIES:
        return handleUpdateIcebreakerSelectedQuestionCategories(state, action);
    default:
        return state;
    }
};

export default reducers;
