import { useEffect } from "react";

const useCrisp = () => {
    useEffect(() => {
        if (!window.$crisp && window.screen && window.screen.width >= 768) {
            window.$crisp = [];
            window.CRISP_WEBSITE_ID = "709a4f23-0b1e-4bca-b9e8-9bf82397705e";
            
            (function() {
                var d = document;
                var s = d.createElement("script");
              
                s.id = "crispScript";
                s.src = "https://client.crisp.chat/l.js";
                s.async = 1;
                d.getElementsByTagName("head")[0].appendChild(s);
            })();

            window.$crisp.push(["do", "chat:hide"]);

            window.$crisp.push(["on", "chat:closed", () => window.$crisp.push(["do", "chat:hide"])]);
        }
    }, []);
};

export default useCrisp;