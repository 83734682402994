import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Loading from "svg/loading-small.svg";

import { ButtonSize } from "./styling";

import style from "./button.module.scss";

const propTypes = {
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onMouseLeave: PropTypes.func,
    size: PropTypes.oneOf(Object.values(ButtonSize)),
    noPadding: PropTypes.bool,
    id: PropTypes.string,
    containerClassName: PropTypes.string
};

type ButtonProps = PropTypes.InferProps<typeof propTypes> & {
    type?: "button" | "submit" | "reset" | undefined,
    onMouseDown?: Function,
    onMouseUp?: Function,
    onMouseLeave?: Function
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ isLoading, className, containerClassName, onClick, children, size, disabled, type, noPadding, id, ...props }: ButtonProps, ref) => {
    const buttonClassName = classnames(style.component, className, {
        [style.componentLoading]: isLoading,
        [style.componentSmall]: size === ButtonSize.Small,
        [style.componentNoPadding]: noPadding,
        [style.componentLoadingSmall]: isLoading && size === ButtonSize.Small
    });

    return <button
        type={type as "button" | "submit" | "reset" | undefined}
        ref={ref} 
        disabled={isLoading || disabled || false} 
        className={buttonClassName}
        id={id || undefined}
        onClick={(e) => onClick && onClick(e)} 
        {...props} >
        {isLoading ? <Loading /> : <div className={classnames(style.container, containerClassName)}>{children}</div>}
    </button>;
});

Button.propTypes = propTypes;

Button.defaultProps = {
    type: "button"
};

export default Button;