import { Member } from "types/common";

import { getDakiActionPlanActions, getDakiCommentActions, getDakiGroupActions, getDakiTemplate, getDakiVoteActions } from "./dakiData";
import { getEndOfYearActionPlanActions, getEndOfYearCommentActions, getEndOfYearGroupActions, getEndOfYearTemplate, getEndOfYearVoteActions } from "./endOfYearData";
import { getLearningMatrixActionPlanActions, getLearningMatrixCommentActions, getLearningMatrixGroupActions, getLearningMatrixTemplate, getLearningMatrixVoteActions } from "./learningMatrixData";

enum TemplateType {
    LearningMatrix = "learningMatrix",
    Daki = "daki",
    EndOfYear = "endOfYear"
}

enum LabelIds {
    Collaboration = "collaboration",
    Process = "process",
    Resources = "resources",
    Fun = "fun",
    Value = "value",
    Roles = "roles",
    Mission = "mission",
    Ownership = "ownership",
    Learnings = "learnings"
}

const billy: Member = {
    id: "billyTheBot",
    firstName: "Billy",
    lastName: "the Bot",
    email: "",
    avatar: "https://app.neatro.io/static/media/member-avatars/2m5.png",
    color: "00FF00",
    jobTitle: "",
    isFacilitator: false,
    isAdministrator: false,
    language: "en"
};

const botilda: Member = {
    id: "3bf20783-757b-47f5-a78c-38690099b6c3",
    firstName: "Botilda",
    lastName: "",
    email: "",
    avatar: "https://app.neatro.io/static/media/member-avatars/10f4.png",
    color: "0000FF",
    jobTitle: "",
    isFacilitator: false,
    isAdministrator: false,
    language: "en"
};

const tiboCop: Member = {
    id: "47b9acaf-a56f-4534-9502-49ed23d4ee41",
    firstName: "TiboCop",
    lastName: "",
    email: "",
    avatar: "https://app.neatro.io/static/media/member-avatars/4m3.png",
    color: "0000FF",
    jobTitle: "",
    isFacilitator: false,
    isAdministrator: false,
    language: "en"
};

const iAnne: Member = {
    id: "94816f40-bb57-40bd-9a8b-cb81a70067bb",
    firstName: "IAnne",
    lastName: "",
    email: "",
    avatar: "https://app.neatro.io/static/media/member-avatars/1w6.png",
    color: "0000FF",
    jobTitle: "",
    isFacilitator: false,
    isAdministrator: false,
    language: "en"
};

const demoMembers = [
    billy,
    botilda,
    iAnne,
    tiboCop
];

enum CommentIds {
    Comment1 = "Comment1",
    Comment2 = "Comment2",
    Comment3 = "Comment3",
    Comment4 = "Comment4",
    Comment5 = "Comment5",
    Comment6 = "Comment6",
    Comment7 = "Comment7",
    Comment8 = "Comment8",
    Comment9 = "Comment9",
    Comment10 = "Comment10",
    Comment11 = "Comment11",
    Comment12 = "Comment12",
    Comment13 = "Comment13",
    Comment14 = "Comment14",
    Comment15 = "Comment15",
    Comment16 = "Comment16",
    Comment17 = "Comment17",
    Comment18 = "Comment18",
    Comment19 = "Comment19",
    Comment20 = "Comment20",
    Comment21 = "Comment21",
    Comment22 = "Comment22",
    Comment23 = "Comment23"
}

const getTemplate = (templateType: string, language?: string) => {
    switch (templateType) {
    case TemplateType.LearningMatrix:
        return getLearningMatrixTemplate(language);
    case TemplateType.Daki:
        return getDakiTemplate(language);
    case TemplateType.EndOfYear:
        return getEndOfYearTemplate(language);
    }

    return getLearningMatrixTemplate(language);
};

const getCommentActions = (language: string, templateType: string) => {
    switch (templateType) {
    case TemplateType.LearningMatrix:
        return getLearningMatrixCommentActions(language);
    case TemplateType.Daki:
        return getDakiCommentActions(language);
    case TemplateType.EndOfYear:
        return getEndOfYearCommentActions(language);
    }

    return getLearningMatrixCommentActions(language);
};

const getGroupActions = (templateType: string) => {
    switch (templateType) {
    case TemplateType.LearningMatrix:
        return getLearningMatrixGroupActions();
    case TemplateType.Daki:
        return getDakiGroupActions();
    case TemplateType.EndOfYear:
        return getEndOfYearGroupActions();
    }

    return getLearningMatrixGroupActions();
};

const getVoteActions = (templateType: string) => {
    switch (templateType) {
    case TemplateType.LearningMatrix:
        return getLearningMatrixVoteActions();
    case TemplateType.Daki:
        return getDakiVoteActions();
    case TemplateType.EndOfYear:
        return getEndOfYearVoteActions();
    }

    return getLearningMatrixVoteActions();
};

const getActionPlanActions = (templateType: string, language: string) => {
    switch (templateType) {
    case TemplateType.LearningMatrix:
        return getLearningMatrixActionPlanActions(language);
    case TemplateType.Daki:
        return getDakiActionPlanActions(language);
    case TemplateType.EndOfYear:
        return getEndOfYearActionPlanActions(language);
    }

    return getLearningMatrixActionPlanActions();
};

export { getCommentActions, getTemplate, TemplateType, getGroupActions, getVoteActions, getActionPlanActions, demoMembers, billy, botilda, iAnne, tiboCop, LabelIds, CommentIds };