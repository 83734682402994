import React from "react";

const contextObject = {
    current: {
        executeAsync: async () => ""
    }
};

const RecaptchaContext = React.createContext(contextObject);

export default RecaptchaContext;

export { contextObject as RecaptchaContextObject };