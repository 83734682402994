const NAMESPACE = "RETROSPECTIVE";

export const START_WEB_SOCKET_WATCH = `${NAMESPACE}/START_WEB_SOCKET_WATCH`;
export const STOP_WEB_SOCKET_WATCH = `${NAMESPACE}/STOP_WEB_SOCKET_WATCH`;

export const JOIN_RETROSPECTIVE = `${NAMESPACE}/JOIN_RETROSPECTIVE`;
export const LEAVE_RETROSPECTIVE = `${NAMESPACE}/LEAVE_RETROSPECTIVE`;
export const ADD_COMMENT = `${NAMESPACE}/ADD_COMMENT`;
export const MOVE_COMMENT = `${NAMESPACE}/MOVE_COMMENT`;
export const STACK_COMMENT = `${NAMESPACE}/STACK_COMMENT`;
export const UNSTACK_COMMENT = `${NAMESPACE}/UNSTACK_COMMENT`;
export const UPDATE_COMMENT = `${NAMESPACE}/UPDATE_COMMENT`;
export const DELETE_COMMENT = `${NAMESPACE}/DELETE_COMMENT`;
export const UPDATE_ACTIVE_STEP = `${NAMESPACE}/UPDATE_ACTIVE_STEP`;
export const ADD_LABEL = `${NAMESPACE}/ADD_LABEL`;
export const DELETE_LABEL = `${NAMESPACE}/DELETE_LABEL`;
export const ADD_LABEL_TO_COMMENT = `${NAMESPACE}/ADD_LABEL_TO_COMMENT`;
export const REMOVE_LABEL_FROM_COMMENT = `${NAMESPACE}/REMOVE_LABEL_FROM_COMMENT`;
export const ADD_VOTE_TO_COMMENT = `${NAMESPACE}/ADD_VOTE_TO_COMMENT`;
export const REMOVE_VOTE_FROM_COMMENT = `${NAMESPACE}/REMOVE_VOTE_FROM_COMMENT`;
export const ADD_ACTION_ITEM = `${NAMESPACE}/ADD_ACTION_ITEM`;
export const UPDATE_ACTION_ITEM = `${NAMESPACE}/UPDATE_ACTION_ITEM`;
export const DELETE_ACTION_ITEM = `${NAMESPACE}/DELETE_ACTION_ITEM`;
export const CLOSE_RETROSPECTIVE = `${NAMESPACE}/CLOSE_RETROSPECTIVE`;
export const CHANGE_MAX_VOTE_COUNT = `${NAMESPACE}/CHANGE_MAX_VOTE_COUNT`;
export const UPDATE_RETROSPECTIVE_NAME = `${NAMESPACE}/UPDATE_RETROSPECTIVE_NAME`;
export const UPDATE_TOPIC_VIEW_FILTER = `${NAMESPACE}/UPDATE_TOPIC_VIEW_FILTER`;
export const UPDATE_LABEL_FILTER = `${NAMESPACE}/UPDATE_LABEL_FILTER`;
export const UPDATE_MEMBER_FILTER = `${NAMESPACE}/UPDATE_MEMBER_FILTER`;
export const UPDATE_COMMENT_TYPE_FILTER = `${NAMESPACE}/UPDATE_COMMENT_TYPE_FILTER`;
export const UPDATE_COMMENT_TYPES_FILTER = `${NAMESPACE}/UPDATE_COMMENT_TYPES_FILTER`;
export const UPDATE_MIN_VOTE_COUNT_FILTER = `${NAMESPACE}/UPDATE_MIN_VOTE_COUNT_FILTER`;
export const REMOVE_MEMBER_FROM_RETROSPECTIVE = `${NAMESPACE}/REMOVE_MEMBER_FROM_RETROSPECTIVE`;
export const UPDATE_RETROSPECTIVE = `${NAMESPACE}/UPDATE_RETROSPECTIVE`;
export const UPDATE_OTHERS_RETROSPECTIVE = `${NAMESPACE}/UPDATE_OTHERS_RETROSPECTIVE`;
export const REVEAL_COMMENTS = `${NAMESPACE}/REVEAL_COMMENTS`;
export const UPDATE_MEMBER_COLLECT_DONE = `${NAMESPACE}/UPDATE_MEMBER_COLLECT_DONE`;
export const UPDATE_ICEBREAKER_STATUS = `${NAMESPACE}/UPDATE_ICEBREAKER_STATUS`;
export const UPDATE_ICEBREAKER_QUESTION = `${NAMESPACE}/UPDATE_ICEBREAKER_QUESTION`;
export const HIGHLIGHT_COMMENT = `${NAMESPACE}/HIGHLIGHT_COMMENT`;
export const SELECT_COMMENT_TYPE = `${NAMESPACE}/SELECT_COMMENT_TYPE`;
export const UNSELECT_COMMENT_TYPE = `${NAMESPACE}/UNSELECT_COMMENT_TYPE`;
export const CHANGE_COMMENT_TYPE_INPUT_TEXT = `${NAMESPACE}/CHANGE_COMMENT_TYPE_INPUT_TEXT`;
export const RESET_VOTES = `${NAMESPACE}/RESET_VOTES`;
export const START_TIMER = `${NAMESPACE}/START_TIMER`;
export const STOP_TIMER = `${NAMESPACE}/STOP_TIMER`;
export const PAUSE_TIMER = `${NAMESPACE}/PAUSE_TIMER`;
export const RESUME_TIMER = `${NAMESPACE}/RESUME_TIMER`;
export const CHANGE_GROUPING_RESTRICTION_RULE = `${NAMESPACE}/CHANGE_GROUPING_RESTRICTION_RULE`;
export const CHANGE_GROUPING_SUGGESTION_RULE = `${NAMESPACE}/CHANGE_GROUPING_SUGGESTION_RULE`;
export const CHANGE_GROUPING_FILTERING_RULE = `${NAMESPACE}/CHANGE_GROUPING_FILTERING_RULE`;
export const CHANGE_GROUP_SUGGESTION_STATUS = `${NAMESPACE}/CHANGE_GROUP_SUGGESTION_STATUS`;
export const REMOVE_GROUP_SUGGESTION_COMMENT = `${NAMESPACE}/REMOVE_GROUP_SUGGESTION_COMMENT`;
export const UPDATE_ICEBREAKER_SELECTED_QUESTION_CATEGORIES = `${NAMESPACE}/UPDATE_ICEBREAKER_SELECTED_QUESTION_CATEGORIES`;

export const FETCH_RETROSPECTIVE = `${NAMESPACE}/FETCH_RETROSPECTIVE`;
export const FETCH_MEMBERS_VOTE = `${NAMESPACE}/FETCH_MEMBERS_VOTE`;
export const FETCH_ACTION_ITEMS = `${NAMESPACE}/FETCH_ACTION_ITEMS`;
export const FETCH_LABELS = `${NAMESPACE}/FETCH_LABELS`;

export const SET_SCROLL_VIEW = `${NAMESPACE}/SET_SCROLL_VIEW`;

export const BROADCAST_COMMENT_VOTES_UPDATED = `${NAMESPACE}/BROADCAST_COMMENT_VOTES_UPDATED`;
export const BROADCAST_ACTIVE_STEP_UPDATED = `${NAMESPACE}/BROADCAST_ACTIVE_STEP_UPDATED`;
export const BROADCAST_RETROSPECTIVE_LEFT = `${NAMESPACE}/BROADCAST_RETROSPECTIVE_LEFT`;
export const BROADCAST_RETROSPECTIVE_CLOSED = `${NAMESPACE}/BROADCAST_RETROSPECTIVE_CLOSED`;
export const BROADCAST_COMMENT_ADDED = `${NAMESPACE}/BROADCAST_COMMENT_ADDED`;
export const BROADCAST_COMMENT_MOVED = `${NAMESPACE}/BROADCAST_COMMENT_MOVED`;
export const BROADCAST_COMMENT_STACKED = `${NAMESPACE}/BROADCAST_COMMENT_STACKED`;
export const BROADCAST_COMMENT_UNSTACKED = `${NAMESPACE}/BROADCAST_COMMENT_UNSTACKED`;
export const BROADCAST_COMMENT_UPDATED = `${NAMESPACE}/BROADCAST_COMMENT_UPDATED`;
export const BROADCAST_COMMENT_DELETED = `${NAMESPACE}/BROADCAST_COMMENT_DELETED`;
export const BROADCAST_LABEL_ADDED = `${NAMESPACE}/BROADCAST_LABEL_ADDED`;
export const BROADCAST_LABEL_DELETED = `${NAMESPACE}/BROADCAST_LABEL_DELETED`;
export const BROADCAST_LABEL_ADDED_TO_COMMENT = `${NAMESPACE}/BROADCAST_LABEL_ADDED_TO_COMMENT`;
export const BROADCAST_LABEL_REMOVED_FROM_COMMENT = `${NAMESPACE}/BROADCAST_LABEL_REMOVED_FROM_COMMENT`;
export const BROADCAST_ACTION_ITEM_ADDED = `${NAMESPACE}/BROADCAST_ACTION_ITEM_ADDED`;
export const BROADCAST_ACTION_ITEM_UPDATED = `${NAMESPACE}/BROADCAST_ACTION_ITEM_UPDATED`;
export const BROADCAST_ACTION_ITEM_DELETED = `${NAMESPACE}/BROADCAST_ACTION_ITEM_DELETED`;
export const BROADCAST_MAX_VOTE_COUNT_CHANGED = `${NAMESPACE}/BROADCAST_MAX_VOTE_COUNT_CHANGED`;
export const BROADCAST_RETROSPECTIVE_NAME_UPDATED = `${NAMESPACE}/BROADCAST_RETROSPECTIVE_NAME_UPDATED`;
export const BROADCAST_MEMBER_REMOVED_FROM_RETROSPECTIVE = `${NAMESPACE}/BROADCAST_MEMBER_REMOVED_FROM_RETROSPECTIVE`;
export const BROADCAST_COMMENT_TYPE_FILTER_UPDATED = `${NAMESPACE}/BROADCAST_COMMENT_TYPE_FILTER_UPDATED`;
export const BROADCAST_COMMENT_TYPES_FILTER_UPDATED = `${NAMESPACE}/BROADCAST_COMMENT_TYPES_FILTER_UPDATED`;
export const BROADCAST_LABEL_FILTER_UPDATED = `${NAMESPACE}/BROADCAST_LABEL_FILTER_UPDATED`;
export const BROADCAST_MEMBER_FILTER_UPDATED = `${NAMESPACE}/BROADCAST_MEMBER_FILTER_UPDATED`;
export const BROADCAST_MIN_VOTE_COUNT_FILTER_UPDATED = `${NAMESPACE}/BROADCAST_MIN_VOTE_COUNT_FILTER_UPDATED`;
export const BROADCAST_TOPIC_VIEW_FILTER_UPDATED = `${NAMESPACE}/BROADCAST_TOPIC_VIEW_FILTER_UPDATED`;
export const BROADCAST_MEMBER_COLLECT_DONE_UPDATED = `${NAMESPACE}/BROADCAST_MEMBER_COLLECT_DONE_UPDATED`;
export const BROADCAST_ICEBREAKER_STATUS_UPDATED = `${NAMESPACE}/BROADCAST_ICEBREAKER_STATUS_UPDATED`;
export const BROADCAST_ICEBREAKER_QUESTION_UPDATED = `${NAMESPACE}/BROADCAST_ICEBREAKER_QUESTION_UPDATED`;
export const BROADCAST_COMMENT_HIGHLIGHTED = `${NAMESPACE}/BROADCAST_COMMENT_HIGHLIGHTED`;
export const BROADCAST_VOTES_RESET = `${NAMESPACE}/BROADCAST_VOTES_RESET`;

export const LISTEN_COMMENTS_UPDATED = `${NAMESPACE}/LISTEN_COMMENTS_UPDATED`;
export const LISTEN_COMMENT_ADDED = `${NAMESPACE}/LISTEN_COMMENT_ADDED`;
export const LISTEN_ACTIVE_STEP_UPDATED = `${NAMESPACE}/LISTEN_ACTIVE_STEP_UPDATED`;
export const LISTEN_RETROSPECTIVE_JOINED = `${NAMESPACE}/LISTEN_RETROSPECTIVE_JOINED`;
export const LISTEN_RETROSPECTIVE_LEFT = `${NAMESPACE}/LISTEN_RETROSPECTIVE_LEFT`;
export const LISTEN_COMMENTS_REVEALED = `${NAMESPACE}/LISTEN_COMMENTS_REVEALED`;
export const LISTEN_COMMENT_STACKED = `${NAMESPACE}/LISTEN_COMMENT_STACKED`;
export const LISTEN_COMMENT_UNSTACKED = `${NAMESPACE}/LISTEN_COMMENT_UNSTACKED`;
export const LISTEN_COMMENT_UPDATED = `${NAMESPACE}/LISTEN_COMMENT_UPDATED`;
export const LISTEN_LABEL_ADDED = `${NAMESPACE}/LISTEN_LABEL_ADDED`;
export const LISTEN_LABEL_DELETED = `${NAMESPACE}/LISTEN_LABEL_DELETED`;
export const LISTEN_LABEL_ADDED_TO_COMMENT = `${NAMESPACE}/LISTEN_LABEL_ADDED_TO_COMMENT`;
export const LISTEN_LABEL_REMOVED_FROM_COMMENT = `${NAMESPACE}/LISTEN_LABEL_REMOVED_FROM_COMMENT`;
export const LISTEN_ACTION_ITEM_UPDATED = `${NAMESPACE}/LISTEN_ACTION_ITEM_UPDATED`;
export const LISTEN_ACTION_ITEM_DELETED = `${NAMESPACE}/LISTEN_ACTION_ITEM_DELETED`;
export const LISTEN_MAX_VOTE_COUNT_CHANGED = `${NAMESPACE}/LISTEN_MAX_VOTE_COUNT_CHANGED`;
export const LISTEN_RETROSPECTIVE_NAME_UPDATED = `${NAMESPACE}/LISTEN_RETROSPECTIVE_NAME_UPDATED`;
export const LISTEN_MEMBER_REMOVED_FROM_RETROSPECTIVE = `${NAMESPACE}/LISTEN_MEMBER_REMOVED_FROM_RETROSPECTIVE`;
export const LISTEN_COMMENT_TYPE_FILTER_UPDATED = `${NAMESPACE}/LISTEN_COMMENT_TYPE_FILTER_UPDATED`;
export const LISTEN_COMMENT_TYPES_FILTER_UPDATED = `${NAMESPACE}/LISTEN_COMMENT_TYPES_FILTER_UPDATED`;
export const LISTEN_LABEL_FILTER_UPDATED = `${NAMESPACE}/LISTEN_LABEL_FILTER_UPDATED`;
export const LISTEN_MEMBER_FILTER_UPDATED = `${NAMESPACE}/LISTEN_MEMBER_FILTER_UPDATED`;
export const LISTEN_MIN_VOTE_COUNT_FILTER_UPDATED = `${NAMESPACE}/LISTEN_MIN_VOTE_COUNT_FILTER_UPDATED`;
export const LISTEN_TOPIC_VIEW_FILTER_UPDATED = `${NAMESPACE}/LISTEN_TOPIC_VIEW_FILTER_UPDATED`;
export const LISTEN_MEMBER_COLLECT_DONE_UPDATED = `${NAMESPACE}/LISTEN_MEMBER_COLLECT_DONE_UPDATED`;
export const LISTEN_ICEBREAKER_STATUS_UPDATED = `${NAMESPACE}/LISTEN_ICEBREAKER_STATUS_UPDATED`;
export const LISTEN_ICEBREAKER_QUESTION_UPDATED = `${NAMESPACE}/LISTEN_ICEBREAKER_QUESTION_UPDATED`;
export const LISTEN_RETROSPECTIVE_CLOSED = `${NAMESPACE}/LISTEN_RETROSPECTIVE_CLOSED`;
export const LISTEN_COMMENT_HIGHLIGHTED = `${NAMESPACE}/LISTEN_COMMENT_HIGHLIGHTED`;
export const LISTEN_VOTES_RESET = `${NAMESPACE}/LISTEN_VOTES_RESET`;
export const LISTEN_START_TIMER = `${NAMESPACE}/LISTEN_START_TIMER`;
export const LISTEN_STOP_TIMER = `${NAMESPACE}/LISTEN_STOP_TIMER`;
export const LISTEN_PAUSE_TIMER = `${NAMESPACE}/LISTEN_PAUSE_TIMER`;
export const LISTEN_RESUME_TIMER = `${NAMESPACE}/LISTEN_RESUME_TIMER`;
export const LISTEN_GROUPING_RESTRICTION_RULE_CHANGED = `${NAMESPACE}/LISTEN_GROUPING_RESTRICTION_RULE_CHANGED`;
export const LISTEN_GROUPING_SUGGESTION_RULE_CHANGED = `${NAMESPACE}/LISTEN_GROUPING_SUGGESTION_RULE_CHANGED`;
export const LISTEN_GROUPING_FILTERING_RULE_CHANGED = `${NAMESPACE}/LISTEN_GROUPING_FILTERING_RULE_CHANGED`;
export const LISTEN_GROUP_SUGGESTION_STATUS_CHANGED = `${NAMESPACE}/LISTEN_GROUP_SUGGESTION_STATUS_CHANGED`;
export const LISTEN_GROUP_SUGGESTION_COMMENT_REMOVED = `${NAMESPACE}/LISTEN_GROUP_SUGGESTION_COMMENT_REMOVED`;
export const LISTEN_ICEBREAKER_SELECTED_QUESTION_CATEGORIES_UPDATED = `${NAMESPACE}/LISTEN_ICEBREAKER_SELECTED_QUESTION_CATEGORIES_UPDATED`;