const NAMESPACE = "INTEGRATIONS/ASANA";

export const SET_TOKEN = `${NAMESPACE}/SET_TOKEN`;

export const SET_SELECTED_WORKSPACE = `${NAMESPACE}/SET_SELECTED_WORKSPACE`;
export const SET_SELECTED_TEAM = `${NAMESPACE}/SET_SELECTED_TEAM`;
export const SET_SELECTED_PROJECT = `${NAMESPACE}/SET_SELECTED_PROJECT`;

export const SET_WORKSPACES = `${NAMESPACE}/SET_WORKSPACES`;
export const SET_TEAMS = `${NAMESPACE}/SET_TEAMS`;
export const SET_PROJECTS = `${NAMESPACE}/SET_PROJECTS`;