import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "react-toastify/dist/ReactToastify.min.css";
import "layout/toastifyToast.scss";

import lazyRetry from "infrastructure/react/lazyRetry";
import AppInsightsErrorBoundary from "infrastructure/tracking/app-insights/AppInsightsErrorBoundary";
import PageViewTracking from "infrastructure/tracking/PageViewTracking";
import { isDevEnvironment, isStagingEnvironment } from "infrastructure/environment";

import CustomZoom from "layout/CustomZoom";
import TooltipId from "common/tooltipId";

import RecaptchaContextProvider from "contexts/recaptcha/RecaptchaContextProvider";

import AppLoading from "components/app-loading/AppLoading";
import SupportChatButton from "components/support-chat-button/SupportChatButton";

import languages from "common/languages";

import initAmplitude from "./bootstrapping/amplitude";
import initi18next from "./bootstrapping/i18next";

import store from "./store";

import "./fonts/fonts.scss";
import "./index.scss";

const App = lazyRetry(() => import("./App"));
const Sso = lazyRetry(() => import("./authentication/sso/Sso"));
const Login = lazyRetry(() => import("./authentication/login/Login"));
const Register = lazyRetry(() => import("./authentication/register/Register"));
const Join = lazyRetry(() => import("./authentication/join/Join"));
const Invite = lazyRetry(() => import("./authentication/invite/Invite"));
const ForgotPassword = lazyRetry(() => import("./authentication/forgot-password/ForgotPassword"));
const ResetPassword = lazyRetry(() => import("./authentication/reset-password/ResetPassword"));
const JiraConfirmSignup = lazyRetry(() => import("./integrations/jira/JiraConfirmSignup"));
const AzureConfirmSignup = lazyRetry(() => import("./integrations/azure/AzureConfirmSignup"));
const IceScrumConfirmSignup = lazyRetry(() => import("./integrations/iceScrum/IceScrumConfirmSignup"));
const AsanaConfirmSignup = lazyRetry(() => import("./integrations/asana/AsanaConfirmSignup"));
const GithubConfirmSignup = lazyRetry(() => import("./integrations/github/GithubConfirmSignup"));
const MondayConfirmSignup = lazyRetry(() => import("./integrations/monday/MondayConfirmSignup"));
const RetrospectiveDemoIntro = lazyRetry(() => import("./demo/RetrospectiveDemoIntro"));
const RetrospectiveDemo = lazyRetry(() => import("./demo/RetrospectiveDemo"));
const RetrospectiveDemoReport = lazyRetry(() => import("./demo/report/RetrospectiveReport"));

initAmplitude();
const language = initi18next();

const root = ReactDOM.createRoot(document.getElementById("app-container"));

if (window.navigator.userAgent.match(/(MSIE|Trident)/)) {
    toast.error(language === languages.english ? "Your browser version is not supported by Neatro." : "La version de votre navigateur Internet n'est pas supportée par Neatro.");
}

root.render(<Provider store={store}>
    <BrowserRouter>
        <SupportChatButton />
        <AppInsightsErrorBoundary language={language}>
            <PageViewTracking>
                <RecaptchaContextProvider>
                    <GoogleOAuthProvider clientId={(isDevEnvironment || isStagingEnvironment) ? "1058059732941-k2cd8oqqq3o3qpivt1vhcvi091gjtahi.apps.googleusercontent.com" : "304314481226-rhir0h8pl20usqouqnviitll45jrsc99.apps.googleusercontent.com"}>
                        <CustomZoom>
                            <Suspense fallback={<AppLoading/>}>
                                <Switch>
                                    <Route exact path="/sso" component={Sso} />
                                    <Route exact path="/login" component={Login} />
                                    <Route exact path="/register" component={Register} />
                                    <Route exact path="/join" component={Join} />
                                    <Route exact path="/invite" component={Invite} />
                                    <Route exact path="/forgot-password" component={ForgotPassword} />
                                    <Route exact path="/reset-password" component={ResetPassword} />
                                    <Route exact path="/jira-confirm-signup" component={JiraConfirmSignup} />
                                    <Route exact path="/azure-confirm-signup" component={AzureConfirmSignup} />
                                    <Route exact path="/icescrum-confirm-signup" component={IceScrumConfirmSignup} />
                                    <Route exact path="/asana-confirm-signup" component={AsanaConfirmSignup} />
                                    <Route exact path="/github-confirm-signup" component={GithubConfirmSignup} />
                                    <Route exact path="/monday-confirm-signup" component={MondayConfirmSignup} />
                                    <Route exact path="/demo" component={RetrospectiveDemoIntro} />
                                    <Route exact path="/demo/report" component={RetrospectiveDemoReport} />
                                    <Route exact path="/demo/:templateType" component={RetrospectiveDemo} />
                                    <Route path="/" component={App} />
                                </Switch>
                            </Suspense>
                        </CustomZoom>
                    </GoogleOAuthProvider>
                </RecaptchaContextProvider>
            </PageViewTracking>
            <ToastContainer autoClose={5000} position={toast.POSITION.TOP_RIGHT} className="toastify-toast" style={{ fontSize: "16px" }} />
            <Tooltip id={TooltipId.Main} className="main-tooltip" noArrow />
        </AppInsightsErrorBoundary>
    </BrowserRouter>
</Provider>);