import { useEffect } from "react";
import { toast } from "react-toastify";

const CustomZoom = ({ children }) => {
    const zoomKey = "NeatroZoom";

    const handleZoomShortcut = (e) => {
        e = e || window.event;

        if (e.ctrlKey && window.localStorage) {
            var keyCode = e.keyCode || e.which;
            var arrow = { up: 38, down: 40 };

            if (keyCode === arrow.up || keyCode === arrow.down) {
                var htmlElement = document.getElementsByTagName("html")[0];

                let currentFontSizePercent = parseInt(window.localStorage.getItem(zoomKey));
        
                if (!currentFontSizePercent || isNaN(currentFontSizePercent) || currentFontSizePercent < 50 || currentFontSizePercent > 200) {
                    currentFontSizePercent = 100;
                    window.localStorage.setItem(zoomKey, 100);
                }
    
                switch (keyCode) {
                case arrow.up:
                    if (currentFontSizePercent < 150) {
                        currentFontSizePercent = currentFontSizePercent + 10;
                        htmlElement.setAttribute("style", `font-size: ${currentFontSizePercent}%`);
                        window.localStorage.setItem(zoomKey, currentFontSizePercent);
                    }
                    break;
                case arrow.down:
                    if (currentFontSizePercent > 50) {
                        currentFontSizePercent = currentFontSizePercent - 10;
                        htmlElement.setAttribute("style", `font-size: ${currentFontSizePercent}%`);
                        window.localStorage.setItem(zoomKey, currentFontSizePercent);
                    }
                    break;
                }
                
                if (toast.isActive(zoomKey)) {
                    toast.update(zoomKey, {render: `Zoom ${currentFontSizePercent}%`});
                }
                else {
                    toast.success(`Zoom ${currentFontSizePercent}%`, { toastId: zoomKey });
                }
            }
        }
    };

    useEffect(() => {  
        if (window.localStorage) {
            var htmlElement = document.getElementsByTagName("html")[0];
    
            let currentFontSizePercent = parseInt(window.localStorage.getItem(zoomKey));
            
            if (currentFontSizePercent && !isNaN(currentFontSizePercent) && currentFontSizePercent >= 50 && currentFontSizePercent <= 200) {
                htmlElement.setAttribute("style", `font-size: ${currentFontSizePercent}%`);
            }
    
            window.addEventListener("keydown", handleZoomShortcut);  
        }
        
        return () => window.removeEventListener("keydown", handleZoomShortcut);
    }, []);

    return children;
};

export default CustomZoom;