import { delay, call, put, takeLatest, fork, cancel, select, join, takeEvery } from "redux-saga/effects";

import ApiClient from "infrastructure/api-client/ApiClient";

import * as actionTypes from "./actionTypes";

import * as actions from "./actions";

function *handleRetrospectiveLeft(retrospectiveId, { memberId }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/leaveRetrospective", { memberId, retrospectiveId }));
    
    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastRetrospectiveLeft(memberId));
}

function *handleRetrospectiveClosed(retrospectiveId, { history }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/closeRetrospective", { retrospectiveId }));
    
    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastRetrospectiveClosed());

    history.push(`/retrospective/report/${retrospectiveId}`);
}

function *handleCommentAdded(retrospectiveId, { commentId, memberId, text, commentTypeId, isAnonymous, authorColor, authorFirstName, authorLastName, authorEmail, isMemberComment, authorAvatar, recaptchaRef }) {
    const apiClient = new ApiClient();

    const token = yield call(() => recaptchaRef.current.executeAsync());
    
    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/addComment", { commentId, commentTypeId, memberId, text, isAnonymous, retrospectiveId, token }));

    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastCommentAdded(commentId, memberId, text, commentTypeId, isAnonymous, authorColor, authorFirstName, authorLastName, authorEmail, isMemberComment, authorAvatar));
}

function *handleCommentMoved(retrospectiveId, { commentId, newCommentTypeId, newPosition }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/moveComment", { commentId, newCommentTypeId, newPosition, retrospectiveId }));
    
    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastCommentMoved());
}

function *handleCommentStacked(retrospectiveId, { sourceCommentId, targetCommentId }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/stackComment", { sourceCommentId, targetCommentId, retrospectiveId }));
    
    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastCommentStacked(sourceCommentId, targetCommentId));
}

function *handleCommentUnstacked(retrospectiveId, { commentId }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/unstackComment", { commentId, retrospectiveId }));
    
    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastCommentUnstacked(commentId));
}

function *handleCommentUpdated(retrospectiveId, { commentId, text, isAnonymous, memberId, memberColor, memberFirstName, memberLastName, memberEmail, memberAvatar }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/updateComment", { commentId, memberId, text, isAnonymous, retrospectiveId }));
    
    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastCommentUpdated(commentId, text, isAnonymous, memberId, memberColor, memberFirstName, memberLastName, memberEmail, memberAvatar));
}

function *handleCommentDeleted(retrospectiveId, { commentId }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/deleteComment", { commentId, retrospectiveId }));
    
    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastCommentDeleted(commentId));
}

function *handleLabelDeleted(retrospectiveId, { labelId }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/deleteLabel", { labelId }));
    
    if (!isSuccess) {
        return;
    }

    yield put(actions.broadcastLabelDeleted(labelId));
}

function *handleLabelAddedToComment(retrospectiveId, { labelId, commentId }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/addLabelToComment", { labelId, commentId, retrospectiveId }));
    
    if (!isSuccess) {
        return;
    }

    yield put(actions.broadcastLabelAddedToComment(commentId, labelId));
}

function *handleLabelRemovedFromComment(retrospectiveId, { labelId, commentId }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/removeLabelFromComment", { labelId, commentId, retrospectiveId }));

    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastLabelRemovedFromComment(commentId, labelId));
}

function *handleActionItemAdded(retrospectiveId, { text, commentTypeId, memberId, labelId, description, dueDate, comments }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/addActionItem", { text, commentTypeId, memberId, labelId, retrospectiveId, description, dueDate, commentIds: comments.map(x => x.id) }));

    if (!isSuccess) {
        return;
    }
    
    const actionItems = yield call(() => apiClient.get(`/actionItems?retrospectiveId=${retrospectiveId}`));

    if (!actionItems) {
        return;
    }
    
    yield put(actions.fetchActionItems(actionItems));

    yield put(actions.broadcastActionItemAdded());
}

function *handleActionItemUpdated(retrospectiveId, { actionItemId, text, memberId, labelId, description, dueDate, comments }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/updateActionItem", { actionItemId, memberId, text, labelId, retrospectiveId, description, dueDate, commentIds: comments.map(x => x.id) }));

    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastActionItemUpdated());
}

function *handleActionItemDeleted(retrospectiveId, { actionItemId }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/deleteActionItem", { actionItemId, retrospectiveId }));

    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastActionItemDeleted(actionItemId));
}

function *handleMaxVoteCountChanged(retrospectiveId, { maxVoteCount }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/changeMaxVoteCount", { maxVoteCount, retrospectiveId }));

    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastMaxVoteCountChanged(maxVoteCount));
}

function *handleRetrospectiveNameUpdated(retrospectiveId, { name }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/updateRetrospectiveName", { name, retrospectiveId }));

    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastRetrospectiveNameUpdated(name));
}

function *handleMemberRemovedFromRetrospective(retrospectiveId, { memberId }) {
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/removeMemberFromRetrospective", { memberId, retrospectiveId }));

    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastMemberRemovedFromRetrospective(memberId));
}

function *handleCommentTypesFilterUpdated(retrospectiveId, { commentTypeIds }) {
    yield delay(500);

    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/updateCommentTypesFilter", { commentTypeIds, retrospectiveId }));

    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastCommentTypesFilterUpdated(commentTypeIds));
}

function *handleCommentTypeFilterUpdated(retrospectiveId, { commentTypeId }) {
    yield delay(500);
    
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/updateCommentTypeFilter", { commentTypeId, retrospectiveId }));

    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastCommentTypeFilterUpdated(commentTypeId));
}

function *handleLabelFilterUpdated(retrospectiveId, { labelId }) {
    yield delay(500);
    
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/updateLabelFilter", { labelId, retrospectiveId }));

    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastLabelFilterUpdated(labelId));
}

function *handleMemberFilterUpdated(retrospectiveId, { memberId }) {
    yield delay(500);
    
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/updateMemberFilter", { memberId, retrospectiveId }));

    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastMemberFilterUpdated(memberId));
}

function *handleMinVoteCountFilterUpdated(retrospectiveId, { minVoteCount }) {
    yield delay(500);
    
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/updateMinVoteCountFilter", { minVoteCount, retrospectiveId }));

    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastMinVoteCountFilterUpdated(minVoteCount));
}

function *handleTopicViewFilterUpdated(retrospectiveId, { isTopicView }) {
    yield delay(500);
    
    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/updateTopicViewFilter", { isTopicView, retrospectiveId }));

    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastTopicViewFilterUpdated(isTopicView));
}

function *handleUpdateActiveStep(retrospectiveId, { activeStepId }) {
    yield delay(500);

    const apiClient = new ApiClient();

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/updateActiveStep", { retrospectiveId, activeStepId }));

    if (!isSuccess) {
        return;
    }
    
    yield put(actions.broadcastActiveStepUpdated(activeStepId));
}

function *handleCommentVoteChanged({ commentId }) {
    yield delay(1000);

    const apiClient = new ApiClient();

    const updatedComment = yield select(state => state.retrospective.comments.find(x => x.id === commentId));

    if (!updatedComment) {
        return;
    }

    const voteCount = updatedComment.myVoteCount;

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/updateCommentVote", { voteCount, commentId }));

    if (!isSuccess) {
        return;
    }

    yield put(actions.broadcastCommentVotesUpdated(commentId));
}

function *handleMemberCollectDoneUpdated(retrospectiveId, { memberId }) {
    yield delay(500);

    const apiClient = new ApiClient(); 

    const members = yield select(state => state.retrospective.members);

    const member = members.find(x => x.id === memberId);

    if (member) {
        const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/updateIsMemberCollectDone", { retrospectiveId, isCollectDone: member.isCollectDone }));
    
        if (!isSuccess) {
            return;
        }
        
        yield put(actions.broadcastMemberCollectDoneUpdated(member.id, member.isCollectDone));
    }
}

function *handleIcebreakerStatusUpdated(retrospectiveId, { isDone }) {
    yield delay(500);

    const apiClient = new ApiClient(); 

    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/updateIcebreakerStatus", { retrospectiveId, isDone }));
    
    if (!isSuccess) {
        return;
    }
        
    yield put(actions.broadcastIcebreakerStatusUpdated(isDone));
}

function *handleIcebreakerQuestionUpdated(retrospectiveId, { questionId }) {
    yield delay(500);

    const apiClient = new ApiClient(); 
    
    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/updateIcebreakerQuestion", { retrospectiveId, questionId }));
    
    if (!isSuccess) {
        return;
    }
        
    yield put(actions.broadcastIcebreakerQuestionUpdated(questionId));
}

function *handleCommentHighlighted(retrospectiveId, { commentId, memberId, memberEmail, memberFirstName, memberLastName, memberColor, memberAvatar }) {
    yield delay(500);

    const apiClient = new ApiClient(); 
    
    const isSuccess = yield call(() => apiClient.post("/retrospectiveActivity/highlightComment", { retrospectiveId, commentId, memberId }));
    
    if (!isSuccess) {
        return;
    }
        
    yield put(actions.broadcastCommentHighlighted(commentId, memberId, memberEmail, memberFirstName, memberLastName, memberColor, memberAvatar));
}

function *handleResetVotes(retrospectiveId) {
    const apiClient = new ApiClient(); 
    
    const isSuccess = yield call(() => apiClient.post(`/retrospectiveActivity/resetVotes?retrospectiveId=${retrospectiveId}`));
    
    if (!isSuccess) {
        return;
    }
        
    yield put(actions.broadcastVotesReset());
}

function takeLatestPerKey(patternOrChannel, worker, keySelector, ...args) {
    return fork(function* () {
        const tasks = {};

        yield takeEvery(patternOrChannel, function* (action) {
            const key = yield call(keySelector, action);

            if (tasks[key]) {
                yield cancel(tasks[key]);
            }

            tasks[key] = yield fork(worker, ...args, action); // eslint-disable-line

            yield join(tasks[key]);

            if (tasks[key] && !tasks[key].isRunning()) {
                delete tasks[key];
            }
        });
    });
}

function selectCommentId({ commentId }) {
    return commentId;
}

function *watch(retrospectiveId) {
    yield takeLatest(actionTypes.ADD_COMMENT, handleCommentAdded, retrospectiveId);
    yield takeLatest(actionTypes.UPDATE_COMMENT, handleCommentUpdated, retrospectiveId);
    yield takeLatest(actionTypes.DELETE_COMMENT, handleCommentDeleted, retrospectiveId);
    yield takeLatest(actionTypes.MOVE_COMMENT, handleCommentMoved, retrospectiveId);
    yield takeLatest(actionTypes.STACK_COMMENT, handleCommentStacked, retrospectiveId);
    yield takeLatest(actionTypes.UNSTACK_COMMENT, handleCommentUnstacked, retrospectiveId);
    yield takeLatest(actionTypes.ADD_LABEL_TO_COMMENT, handleLabelAddedToComment, retrospectiveId);
    yield takeLatest(actionTypes.REMOVE_LABEL_FROM_COMMENT, handleLabelRemovedFromComment, retrospectiveId);
    yield takeLatest(actionTypes.LEAVE_RETROSPECTIVE, handleRetrospectiveLeft, retrospectiveId);
    yield takeLatest(actionTypes.UPDATE_ACTIVE_STEP, handleUpdateActiveStep, retrospectiveId);
    yield takeLatest(actionTypes.DELETE_LABEL, handleLabelDeleted, retrospectiveId);
    yield takeLatest(actionTypes.ADD_ACTION_ITEM, handleActionItemAdded, retrospectiveId);
    yield takeLatest(actionTypes.UPDATE_ACTION_ITEM, handleActionItemUpdated, retrospectiveId);
    yield takeLatest(actionTypes.DELETE_ACTION_ITEM, handleActionItemDeleted, retrospectiveId);
    yield takeLatest(actionTypes.CLOSE_RETROSPECTIVE, handleRetrospectiveClosed, retrospectiveId);
    yield takeLatest(actionTypes.CHANGE_MAX_VOTE_COUNT, handleMaxVoteCountChanged, retrospectiveId);
    yield takeLatest(actionTypes.UPDATE_RETROSPECTIVE_NAME, handleRetrospectiveNameUpdated, retrospectiveId);
    yield takeLatest(actionTypes.UPDATE_LABEL_FILTER, handleLabelFilterUpdated, retrospectiveId);
    yield takeLatest(actionTypes.UPDATE_MEMBER_FILTER, handleMemberFilterUpdated, retrospectiveId);
    yield takeLatest(actionTypes.UPDATE_COMMENT_TYPE_FILTER, handleCommentTypeFilterUpdated, retrospectiveId);
    yield takeLatest(actionTypes.UPDATE_TOPIC_VIEW_FILTER, handleTopicViewFilterUpdated, retrospectiveId);
    yield takeLatest(actionTypes.UPDATE_COMMENT_TYPES_FILTER, handleCommentTypesFilterUpdated, retrospectiveId);
    yield takeLatest(actionTypes.UPDATE_MIN_VOTE_COUNT_FILTER, handleMinVoteCountFilterUpdated, retrospectiveId);
    yield takeLatest(actionTypes.REMOVE_MEMBER_FROM_RETROSPECTIVE, handleMemberRemovedFromRetrospective, retrospectiveId);
    yield takeLatest(actionTypes.UPDATE_MEMBER_COLLECT_DONE, handleMemberCollectDoneUpdated, retrospectiveId);
    yield takeLatest(actionTypes.UPDATE_ICEBREAKER_STATUS, handleIcebreakerStatusUpdated, retrospectiveId);
    yield takeLatest(actionTypes.UPDATE_ICEBREAKER_QUESTION, handleIcebreakerQuestionUpdated, retrospectiveId);
    yield takeLatest(actionTypes.HIGHLIGHT_COMMENT, handleCommentHighlighted, retrospectiveId);
    yield takeLatest(actionTypes.RESET_VOTES, handleResetVotes, retrospectiveId);
    yield takeLatestPerKey(action => action.type === actionTypes.ADD_VOTE_TO_COMMENT || action.type === actionTypes.REMOVE_VOTE_FROM_COMMENT, handleCommentVoteChanged, selectCommentId);
}

function *startWatch({ retrospectiveId }) {
    yield fork(watch, retrospectiveId);
}

function *saga() {
    yield takeLatest(actionTypes.START_WEB_SOCKET_WATCH, startWatch);
}

export default saga;