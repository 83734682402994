import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import queryString from "query-string";
import Backend from "i18next-http-backend";

import languages from "common/languages";

const initi18next = () => {
    const { language } = queryString.parse(window.location.search);
    const cleanLanguage = language ? language.toString().substring(0, 2) : languages.english;
    
    i18next
        .use(Backend)
        .use(initReactI18next)
        .init({
            interpolation: { escapeValue: false },
            lng: (cleanLanguage !== languages.english && cleanLanguage !== languages.french) ? languages.english : cleanLanguage,
            fallbackLng: null,
            ns: ["errors", "integrations", "common"]
        });

    return cleanLanguage;
};

export default initi18next;