import axios from "axios";
import { toast } from "react-toastify";
import i18next from "i18next";

export class ApiClient {
    constructor() {
        this._client = axios.create({
            baseURL: process.env.API_URL,
            withCredentials: true
        });
    }
    
    async get(url, data, ignoreError) {
        try {
            const response = await this._client.get(url, data);
            
            return response.data;
        }
        catch (error) {
            if (!ignoreError) {
                toast.error(i18next.t(error.response.data && error.response.data.error ? `errors:${error.response.data.error}` : "errors:generic_error"));
            }

            return null;
        }
    }

    async post(url, data, ignoreError) {
        try {
            const response = await this._client.post(url, data);

            return response.data || true;
        }
        catch (error) {
            if (!ignoreError) {
                toast.error(i18next.t(error.response.data && error.response.data.error ? `errors:${error.response.data.error}` : "errors:generic_error"));
            }

            return false;
        }
    }

    async put(url, data, ignoreError) {
        try {
            const response = await this._client.put(url, data);

            return response.data || true;
        }
        catch (error) {
            if (!ignoreError) {
                toast.error(i18next.t(error.response.data && error.response.data.error ? `errors:${error.response.data.error}` : "errors:generic_error"));
            }
            
            return false;
        }
    }

    async delete(url, data) {
        try {
            const response = await this._client.delete(url, data);

            return response.data || true;
        }
        catch (error) {
            toast.error(i18next.t(error.response.data && error.response.data.error ? `errors:${error.response.data.error}` : "errors:generic_error"));

            return false;
        }
    }

    async getCsrfToken() {
        try {
            await this._client.get("/antiForgery");
            
            return true;
        }
        catch (error) {
            return false;
        }
    }
}

export default ApiClient;