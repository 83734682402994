import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { Member } from "types/common";

const initialState: Member = {
    id: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    language: "",
    avatar: "",
    selectedTeam: undefined,
    isNewFeatureBannerDismissed: false,
    color: "",
    email: "",
    isTemporaryAccount: false,
    isAdminWithNoTeams: false,
    billing: undefined,
    isFacilitator: false,
    isAdministrator: false,
    isDeleted: false,
    isMultiTeam: false,
    isEmailConfirmed: false,
    isUserOnboarding: false
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        fetchUserContext(state: Member, action: PayloadAction<Member>) {
            return {
                ...state,
                ...action.payload
            };
        },
        updateUserFirstName(state: Member, action: PayloadAction<string>) {
            return {
                ...state,
                firstName: action.payload
            };
        },
        updateUserLastName(state: Member, action: PayloadAction<string>) {
            return {
                ...state,
                lastName: action.payload
            };
        },
        updateUserJobTitle(state: Member, action: PayloadAction<string>) {
            return {
                ...state,
                jobTitle: action.payload
            };
        },
        updateUserLanguage(state: Member, action: PayloadAction<string>) {
            return {
                ...state,
                language: action.payload
            };
        },
        updateUserAvatar(state: Member, action: PayloadAction<string>) {
            return {
                ...state,
                avatar: action.payload
            };
        },
        updateSelectedTeamName(state: Member, action: PayloadAction<string>) {
            if (!state.selectedTeam) {
                return {
                    ...state
                };
            }
        
            return {
                ...state,
                selectedTeam: {
                    ...state.selectedTeam,
                    name: action.payload
                }
            };
        },
        updateSelectedTeamAvatar(state: Member, action: PayloadAction<string>) {
            if (!state.selectedTeam) {
                return {
                    ...state
                };
            }
        
            return {
                ...state,
                selectedTeam: {
                    ...state.selectedTeam,
                    avatar: action.payload
                }
            };
        },
        updateSelectedTeamOrganizationName(state: Member, action: PayloadAction<string>) {
            if (!state.selectedTeam) {
                return {
                    ...state
                };
            }
        
            return {
                ...state,
                selectedTeam: {
                    ...state.selectedTeam,
                    organization: {
                        ...state.selectedTeam.organization,
                        name: action.payload
                    }
                }
            };
        },
        dismissNewFeatureBanner(state: Member) {
            return {
                ...state,
                isNewFeatureBannerDismissed: true
            };
        }
    },
});
  
export const { fetchUserContext, 
    updateUserFirstName, 
    updateUserLastName,
    dismissNewFeatureBanner,
    updateSelectedTeamAvatar,
    updateSelectedTeamName,
    updateSelectedTeamOrganizationName,
    updateUserAvatar,
    updateUserJobTitle,
    updateUserLanguage } = userSlice.actions;

export default userSlice.reducer;