import * as actionTypes from "./actionTypes";
import RetrospectiveService from "./retrospectiveService";

const retrospectiveMiddleware = () => {
    return (next) => (action) => {
        switch (action.type) {
        case actionTypes.BROADCAST_COMMENT_ADDED:
            RetrospectiveService.addComment(action);
            break;
        case actionTypes.BROADCAST_COMMENT_MOVED:
            RetrospectiveService.moveComment(action);
            break;
        case actionTypes.BROADCAST_COMMENT_STACKED:
            RetrospectiveService.stackComment(action);
            break;
        case actionTypes.BROADCAST_COMMENT_UNSTACKED:
            RetrospectiveService.unstackComment(action);
            break;
        case actionTypes.BROADCAST_COMMENT_UPDATED:
            RetrospectiveService.updateComment(action);
            break;
        case actionTypes.BROADCAST_COMMENT_DELETED:
            RetrospectiveService.deleteComment(action);
            break;
        case actionTypes.BROADCAST_LABEL_ADDED:
            RetrospectiveService.addLabel(action);
            break;
        case actionTypes.BROADCAST_LABEL_DELETED:
            RetrospectiveService.deleteLabel(action);
            break;
        case actionTypes.BROADCAST_LABEL_ADDED_TO_COMMENT:
            RetrospectiveService.addLabelToComment(action);
            break;
        case actionTypes.BROADCAST_LABEL_REMOVED_FROM_COMMENT:
            RetrospectiveService.removeLabelFromComment(action);
            break;
        case actionTypes.BROADCAST_ACTION_ITEM_ADDED:
            RetrospectiveService.addActionItem(action);
            break;
        case actionTypes.BROADCAST_ACTION_ITEM_UPDATED:
            RetrospectiveService.updateActionItem(action);
            break;
        case actionTypes.BROADCAST_ACTION_ITEM_DELETED:
            RetrospectiveService.deleteActionItem(action);
            break;
        case actionTypes.BROADCAST_MAX_VOTE_COUNT_CHANGED:
            RetrospectiveService.changeMaxVoteCount(action);
            break;
        case actionTypes.BROADCAST_RETROSPECTIVE_NAME_UPDATED:
            RetrospectiveService.updateRetrospectiveName(action);
            break;
        case actionTypes.BROADCAST_MEMBER_REMOVED_FROM_RETROSPECTIVE:
            RetrospectiveService.removeMemberFromRetrospective(action);
            break;
        case actionTypes.BROADCAST_COMMENT_TYPE_FILTER_UPDATED:
            RetrospectiveService.updateCommentTypeFilter(action);
            break;
        case actionTypes.BROADCAST_COMMENT_TYPES_FILTER_UPDATED:
            RetrospectiveService.updateCommentTypesFilter(action);
            break;
        case actionTypes.BROADCAST_LABEL_FILTER_UPDATED:
            RetrospectiveService.updateLabelFilter(action);
            break;
        case actionTypes.BROADCAST_MEMBER_FILTER_UPDATED:
            RetrospectiveService.updateMemberFilter(action);
            break;
        case actionTypes.BROADCAST_MIN_VOTE_COUNT_FILTER_UPDATED:
            RetrospectiveService.updateMinVoteCountFilter(action);
            break;
        case actionTypes.BROADCAST_ACTIVE_STEP_UPDATED:
            RetrospectiveService.updateActiveStep(action);
            break;
        case actionTypes.BROADCAST_TOPIC_VIEW_FILTER_UPDATED:
            RetrospectiveService.updateTopicViewFilter(action);
            break;
        case actionTypes.BROADCAST_COMMENT_VOTES_UPDATED:
            RetrospectiveService.updateCommentVotes(action);
            break;
        case actionTypes.JOIN_RETROSPECTIVE:
            RetrospectiveService.joinRetrospective(action);
            break;
        case actionTypes.BROADCAST_RETROSPECTIVE_LEFT:
            RetrospectiveService.leaveRetrospective(action);
            break;
        case actionTypes.BROADCAST_RETROSPECTIVE_CLOSED:
            RetrospectiveService.closeRetrospective(action);
            break;
        case actionTypes.REVEAL_COMMENTS:
            RetrospectiveService.revealComments(action);
            break;
        case actionTypes.BROADCAST_MEMBER_COLLECT_DONE_UPDATED:
            RetrospectiveService.updateMemberCollectDone(action);
            break;
        case actionTypes.BROADCAST_ICEBREAKER_STATUS_UPDATED:
            RetrospectiveService.updateIcebreakerStatus(action);
            break;
        case actionTypes.BROADCAST_ICEBREAKER_QUESTION_UPDATED:
            RetrospectiveService.updateIcebreakerQuestion(action);
            break;
        case actionTypes.BROADCAST_COMMENT_HIGHLIGHTED:
            RetrospectiveService.highlightComment(action);
            break;
        case actionTypes.BROADCAST_VOTES_RESET:
            RetrospectiveService.resetVotes(action);
            break;
        case actionTypes.START_TIMER:
            RetrospectiveService.startTimer(action);
            break;
        case actionTypes.STOP_TIMER:
            RetrospectiveService.stopTimer(action);
            break;
        case actionTypes.PAUSE_TIMER:
            RetrospectiveService.pauseTimer(action);
            break;
        case actionTypes.RESUME_TIMER:
            RetrospectiveService.resumeTimer(action);
            break;
        case actionTypes.CHANGE_GROUPING_RESTRICTION_RULE:
            RetrospectiveService.changeGroupingRestrictionRule(action);
            break;
        case actionTypes.CHANGE_GROUPING_SUGGESTION_RULE:
            RetrospectiveService.changeGroupingSuggestionRule(action);
            break;
        case actionTypes.CHANGE_GROUPING_FILTERING_RULE:
            RetrospectiveService.changeGroupingFilteringRule(action);
            break;
        case actionTypes.CHANGE_GROUP_SUGGESTION_STATUS:
            RetrospectiveService.changeGroupSuggestionStatus(action);
            break;
        case actionTypes.REMOVE_GROUP_SUGGESTION_COMMENT:
            RetrospectiveService.removeGroupSuggestionComment(action);
            break;
        case actionTypes.UPDATE_ICEBREAKER_SELECTED_QUESTION_CATEGORIES:
            if (!action.preventWebSocketBroadcast) {
                RetrospectiveService.updateIcebreakerSelectedQuestionCategories(action);
            }
            break;
        }

        return next(action);
    };
};

export default retrospectiveMiddleware;