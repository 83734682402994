const NAMESPACE = "INTEGRATIONS/JIRA";

export const SET_TOKEN = `${NAMESPACE}/SET_TOKEN`;

export const SET_SELECTED_SITE = `${NAMESPACE}/SET_SELECTED_SITE`;
export const SET_SELECTED_PROJECT = `${NAMESPACE}/SET_SELECTED_PROJECT`;
export const SET_SELECTED_BOARD = `${NAMESPACE}/SET_SELECTED_BOARD`;
export const SET_SELECTED_SPRINT = `${NAMESPACE}/SET_SELECTED_SPRINT`;
export const SET_SELECTED_ISSUE_TYPE = `${NAMESPACE}/SET_SELECTED_ISSUE_TYPE`;
export const SET_SELECTED_LABELS = `${NAMESPACE}/SET_SELECTED_LABELS`;
export const SET_SELECTED_PROJECT_COMPONENTS = `${NAMESPACE}/SET_SELECTED_PROJECT_COMPONENTS`;

export const SET_SITES = `${NAMESPACE}/SET_SITES`;
export const SET_PROJECTS = `${NAMESPACE}/SET_PROJECTS`;
export const SET_BOARDS = `${NAMESPACE}/SET_BOARDS`;
export const SET_SPRINTS = `${NAMESPACE}/SET_SPRINTS`;
export const SET_ISSUE_TYPES = `${NAMESPACE}/SET_ISSUE_TYPES`;
export const SET_LABELS = `${NAMESPACE}/SET_LABELS`;
export const SET_PROJECT_COMPONENTS = `${NAMESPACE}/SET_PROJECT_COMPONENTS`;