import * as actionTypes from "./actionTypes";

const defaultState = {
    token: null,
    selectedSite: null,
    selectedProject: null,
    selectedIssueType: null,
    sites: [],
    projects: [],
    issueTypes: []
};

const handleSetToken = (state, { token }) => {  
    return {
        ...state,
        token
    };
};

const handleSetSelectedSite = (state, { selectedSite }) => {  
    return {
        ...state,
        selectedSite
    };
};

const handleSetSelectedProject = (state, { selectedProject }) => {  
    return {
        ...state,
        selectedProject
    };
};

const handleSetSelectedIssueType = (state, { selectedIssueType }) => {  
    return {
        ...state,
        selectedIssueType
    };
};

const handleSetSites = (state, { sites }) => {  
    return {
        ...state,
        sites: [
            ...sites
        ]
    };
};

const handleSetProjects = (state, { projects }) => {  
    return {
        ...state,
        projects: [
            ...projects
        ]
    };
};

const handleSetIssueTypes = (state, { issueTypes }) => {  
    return {
        ...state,
        issueTypes: [
            ...issueTypes
        ]
    };
};

const reducers = (state = defaultState, action) => {
    switch (action.type) {
    case actionTypes.SET_TOKEN:
        return handleSetToken(state, action);   
    case actionTypes.SET_SELECTED_SITE:
        return handleSetSelectedSite(state, action);    
    case actionTypes.SET_SELECTED_PROJECT:
        return handleSetSelectedProject(state, action);    
    case actionTypes.SET_SELECTED_ISSUE_TYPE:
        return handleSetSelectedIssueType(state, action);   
    case actionTypes.SET_SITES:
        return handleSetSites(state, action);      
    case actionTypes.SET_PROJECTS:
        return handleSetProjects(state, action);      
    case actionTypes.SET_ISSUE_TYPES:
        return handleSetIssueTypes(state, action);
    default:
        return state;
    }
};

export default reducers;