import { History } from "history";

import TimerSound from "components/timer/timerSound";
import { Member, Comment } from "types/common";

import * as actionTypes from "./actionTypes";
import GroupingRestrictionRule from "./groupingRestrictionRule";
import GroupingSuggestionRule from "./groupingSuggestionRule";
import GroupingFilteringRule from "./groupingFilteringRule";

import { RetrospectiveSeed } from "./types";

export const suggestParentId = (childId: string, parentId: string, isSameColumn: boolean) => ({ 
    type: actionTypes.SUGGEST_PARENT_ID,
    childId,
    parentId,
    isSameColumn
});

export const skipRoti = () => ({ 
    type: actionTypes.SKIP_ROTI
});

export const addRotiNote = (note: number, comment: string) => ({ 
    type: actionTypes.ADD_ROTI_NOTE,
    note,
    comment
});

export const spendMemberVote = (memberId: string, commentId: string) => ({ 
    type: actionTypes.SPEND_MEMBER_VOTE,
    memberId,
    commentId
});

export const seedRetrospective = (retrospective: RetrospectiveSeed) => ({ 
    type: actionTypes.SEED_RETROSPECTIVE,
    retrospective
});

export const joinRetrospective = (member: Member) => ({ 
    type: actionTypes.JOIN_RETROSPECTIVE,
    member
});

export const leaveRetrospective = (memberId: string) => ({
    type: actionTypes.LEAVE_RETROSPECTIVE,
    memberId
});

export const addComment = (commentId: string, text: string, commentTypeId: string, isAnonymous: boolean, memberId: string, authorColor: string, authorFirstName: string, authorLastName: string, authorEmail: string, isMemberComment: boolean, authorAvatar: string) => ({ 
    type: actionTypes.ADD_COMMENT,
    commentId,
    memberId,
    text,
    commentTypeId,
    isAnonymous,
    authorColor,
    authorFirstName,
    authorLastName,
    authorEmail,
    authorAvatar,
    isMemberComment,
    isNew: true
});

export const moveComment = (oldCommentTypeId: string, newCommentTypeId: string, oldPosition: number, newPosition: number, commentId: string) => ({ 
    type: actionTypes.MOVE_COMMENT,
    oldCommentTypeId,
    newCommentTypeId,
    oldPosition,
    newPosition,
    commentId
});

export const stackComment = (sourceCommentId: string, targetCommentId: string) => ({ 
    type: actionTypes.STACK_COMMENT,
    sourceCommentId,
    targetCommentId
});

export const unstackComment = (commentId: string) => ({ 
    type: actionTypes.UNSTACK_COMMENT,
    commentId
});

export const updateComment = (commentId: string, text: string, isAnonymous: boolean, memberId: string, memberColor: string, memberFirstName: string, memberLastName: string, memberEmail: string, memberAvatar: string) => ({
    type: actionTypes.UPDATE_COMMENT,
    commentId,
    text,
    isAnonymous,
    memberId,
    memberColor, 
    memberFirstName, 
    memberLastName,
    memberEmail,
    memberAvatar
});

export const deleteComment = (commentId: string) => ({
    type: actionTypes.DELETE_COMMENT,
    commentId
});

export const revealComments = (activeStepId: string) => ({
    type: actionTypes.REVEAL_COMMENTS,
    activeStepId
});

export const updateActiveStep = (activeStepId: string) => ({ 
    type: actionTypes.UPDATE_ACTIVE_STEP,
    activeStepId
});

export const addLabel = (id: string, name: string, color: string, teamId: string) => ({ 
    type: actionTypes.ADD_LABEL,
    id,
    name, 
    color,
    teamId
});

export const deleteLabel = (teamId: string, labelId: string) => ({ 
    type: actionTypes.DELETE_LABEL,
    teamId,
    labelId
});

export const addLabelToComment = (commentId: string, labelId: string) => ({ 
    type: actionTypes.ADD_LABEL_TO_COMMENT,
    commentId,
    labelId
});

export const removeLabelFromComment = (commentId: string, labelId: string) => ({ 
    type: actionTypes.REMOVE_LABEL_FROM_COMMENT,
    commentId,
    labelId
});

export const addVoteToComment = (commentId: string, memberId: string) => ({ 
    type: actionTypes.ADD_VOTE_TO_COMMENT,
    commentId,
    memberId
});

export const removeVoteFromComment = (commentId: string, memberId: string) => ({ 
    type: actionTypes.REMOVE_VOTE_FROM_COMMENT,
    commentId,
    memberId
});

export const addActionItem = (
    text: string, 
    labelId: string | null, 
    labelName: string | null, 
    labelColor: string | null, 
    labelTeamId: string | null | undefined, 
    commentTypeId: string, 
    memberId: string | null, 
    memberFirstName: string | null | undefined, 
    memberLastName: string | null | undefined, 
    memberColor: string | null | undefined, 
    memberEmail: string | null | undefined, 
    memberAvatar: string | null | undefined, 
    description: string, 
    dueDate?: Date, 
    comments?: Comment[]) => ({ 
    type: actionTypes.ADD_ACTION_ITEM,
    text,
    memberId,
    labelId,
    labelName,
    labelColor,
    labelTeamId,
    commentTypeId,
    memberFirstName, 
    memberLastName, 
    memberColor, 
    memberEmail,
    memberAvatar,
    description,
    dueDate,
    comments
});

export const updateActionItem = (
    actionItemId: string, 
    text: string, 
    memberId: string | null | undefined, 
    memberFirstName: string | null | undefined, 
    memberLastName: string | null | undefined, 
    memberColor: string | null | undefined, 
    memberEmail: string | null | undefined, 
    labelId: string | null, 
    labelName: string | null, 
    labelColor: string | null, 
    labelTeamId: string | null | undefined, 
    memberAvatar: string, 
    description: string, 
    dueDate?: Date, 
    comments?: Comment[]) => ({ 
    type: actionTypes.UPDATE_ACTION_ITEM,
    actionItemId,
    text,
    memberId,
    memberFirstName, 
    memberLastName, 
    memberColor, 
    memberEmail,
    labelId, 
    labelName, 
    labelColor, 
    labelTeamId,
    memberAvatar,
    description,
    dueDate,
    comments
});

export const deleteActionItem = (actionItemId: string) => ({ 
    type: actionTypes.DELETE_ACTION_ITEM,
    actionItemId
});

export const closeRetrospective = (history: History) => ({ 
    type: actionTypes.CLOSE_RETROSPECTIVE,
    history
});

export const changeMaxVoteCount = (maxVoteCount: number) => ({ 
    type: actionTypes.CHANGE_MAX_VOTE_COUNT,
    maxVoteCount
});

export const updateRetrospectiveName = (name: string) => ({ 
    type: actionTypes.UPDATE_RETROSPECTIVE_NAME,
    name
});

export const updateTopicViewFilter = (isTopicView: boolean) => ({
    type: actionTypes.UPDATE_TOPIC_VIEW_FILTER,
    isTopicView
});

export const updateLabelFilter = (labelId: string) => ({
    type: actionTypes.UPDATE_LABEL_FILTER,
    labelId
});

export const updateMemberFilter = (memberId: string) => ({
    type: actionTypes.UPDATE_MEMBER_FILTER,
    memberId
});

export const updateCommentTypeFilter = (commentTypeId: string) => ({
    type: actionTypes.UPDATE_COMMENT_TYPE_FILTER,
    commentTypeId
});

export const updateCommentTypesFilter = (commentTypeIds: string[]) => ({
    type: actionTypes.UPDATE_COMMENT_TYPES_FILTER,
    commentTypeIds
});

export const updateMinVoteCountFilter = (minVoteCount: number) => ({
    type: actionTypes.UPDATE_MIN_VOTE_COUNT_FILTER,
    minVoteCount
});

export const removeMemberFromRetrospective = (memberId: string) => ({
    type: actionTypes.REMOVE_MEMBER_FROM_RETROSPECTIVE,
    memberId
});

export const updateMemberCollectDone = (memberId: string, isCollectDone: boolean) => ({
    type: actionTypes.UPDATE_MEMBER_COLLECT_DONE,
    memberId,
    isCollectDone
});

export const updateIcebreakerStatus = (isDone: boolean) => ({
    type: actionTypes.UPDATE_ICEBREAKER_STATUS,
    isDone
});

export const updateIcebreakerQuestion = (questionId?: number) => ({
    type: actionTypes.UPDATE_ICEBREAKER_QUESTION,
    questionId
});

export const highlightComment = (commentId: string, memberId: string, memberEmail: string, memberFirstName: string, memberLastName: string, memberColor: string, memberAvatar: string) => ({
    type: actionTypes.HIGHLIGHT_COMMENT,
    commentId,
    memberId,
    memberEmail,
    memberFirstName,
    memberLastName,
    memberColor,
    memberAvatar
});

export const selectCommentType = (commentTypeId: string) => ({
    type: actionTypes.SELECT_COMMENT_TYPE,
    commentTypeId
});

export const unselectCommentType = (commentTypeId: string) => ({
    type: actionTypes.UNSELECT_COMMENT_TYPE,
    commentTypeId
});

export const changeCommentTypeInputText = (commentTypeId: string, text: string) => ({
    type: actionTypes.CHANGE_COMMENT_TYPE_INPUT_TEXT,
    commentTypeId,
    text
});

export const resetVotes = () => ({
    type: actionTypes.RESET_VOTES
});

export const startTimer = (timerEnd: Date, timerSound: TimerSound) => ({
    type: actionTypes.START_TIMER,
    timerEnd,
    timerSound
});

export const stopTimer = () => ({
    type: actionTypes.STOP_TIMER
});

export const pauseTimer = (timerPause: Date) => ({
    type: actionTypes.PAUSE_TIMER,
    timerPause
});

export const resumeTimer = (timerEnd: Date) => ({
    type: actionTypes.RESUME_TIMER,
    timerEnd
});

export const changeGroupingRestrictionRule = (rule: GroupingRestrictionRule) => ({
    type: actionTypes.CHANGE_GROUPING_RESTRICTION_RULE,
    rule
});

export const changeGroupingSuggestionRule = (rule: GroupingSuggestionRule) => ({
    type: actionTypes.CHANGE_GROUPING_SUGGESTION_RULE,
    rule
});

export const changeGroupingFilteringRule = (rule: GroupingFilteringRule) => ({
    type: actionTypes.CHANGE_GROUPING_FILTERING_RULE,
    rule
});

export const changeGroupSuggestionStatus = (parentCommentId: string, isApproved: boolean) => ({
    type: actionTypes.CHANGE_GROUP_SUGGESTION_STATUS,
    parentCommentId,
    isApproved
});

export const removeGroupSuggestionComment = (commentId: string) => ({
    type: actionTypes.REMOVE_GROUP_SUGGESTION_COMMENT,
    commentId
});

export const updateIcebreakerSelectedQuestionCategories = (selectedQuestionCategoryIds: string[], preventWebSocketBroadcast?: boolean) => ({
    type: actionTypes.UPDATE_ICEBREAKER_SELECTED_QUESTION_CATEGORIES,
    selectedQuestionCategoryIds,
    preventWebSocketBroadcast
});