import { HubConnectionBuilder, HubConnection } from "@microsoft/signalr";
import { toast } from "react-toastify";
import i18next from "i18next";

import * as actions from "./actions";

class RetrospectiveService {
    _connection?: HubConnection;

    constructor() {
        this._connection = undefined;
    }

    async openConnection(retrospectiveId: string) { 
        this._connection = new HubConnectionBuilder()
            .withUrl(`${process.env.API_URL}/retrospectiveHub?retrospectiveId=${retrospectiveId}`)
            .withAutomaticReconnect()
            .build();
    
        return this._connection.start().then(() => {
            return true;
        }).catch(() => {
            return false;
        });
    }

    async _invoke(methodName: string, ...args: any[]) {
        if (this._connection) {
            return this._connection.invoke(methodName, ...args).then(() => {
                if (methodName === "LeaveRetrospective") {
                    if (this._connection) {
                        this._connection.stop().then(() => {
                            this._connection = undefined;
                        });
                    }
                }
            }).catch(() => {
                toast.warn(i18next.t("retrospective:retrospectiveNoWebsocket").toString(), { autoClose: false, onClick: () => window.open(i18next.t("retrospective:retrospectiveNoWebsocketLink"), "_blank"), closeOnClick: false });
            });
        }
    }

    registerRetrospectiveJoined(callback: (...args: any[]) => void) {
        this._connection?.on("RetrospectiveJoined", callback);
    }

    registerRetrospectiveLeft(callback: (...args: any[]) => void) {
        this._connection?.on("RetrospectiveLeft", callback);
    }

    registerRetrospectiveClosed(callback: (...args: any[]) => void) {
        this._connection?.on("RetrospectiveClosed", callback);
    }
    
    registerCommentAdded(callback: (...args: any[]) => void) {
        this._connection?.on("CommentAdded", callback);
    }
    
    registerCommentsUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("CommentsUpdated", callback);
    }
    
    registerCommentsRevealed(callback: (...args: any[]) => void) {
        this._connection?.on("CommentsRevealed", callback);
    }
    
    registerCommentStacked(callback: (...args: any[]) => void) {
        this._connection?.on("CommentStacked", callback);
    }
    
    registerCommentUnstacked(callback: (...args: any[]) => void) {
        this._connection?.on("CommentUnstacked", callback);
    }
    
    registerCommentEdited(callback: (...args: any[]) => void) {
        this._connection?.on("CommentEdited", callback);
    }
    
    registerLabelsUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("LabelsUpdated", callback);
    }
    
    registerLabelDeleted(callback: (...args: any[]) => void) {
        this._connection?.on("LabelDeleted", callback);
    }
    
    registerLabelAddedToComment(callback: (...args: any[]) => void) {
        this._connection?.on("LabelAddedToComment", callback);
    }
    
    registerLabelRemovedFromComment(callback: (...args: any[]) => void) {
        this._connection?.on("LabelRemovedFromComment", callback);
    }
    
    registerMemberRemovedFromRetrospective(callback: (...args: any[]) => void) {
        this._connection?.on("MemberRemovedFromRetrospective", callback);
    }
    
    registerMaxVoteCountChanged(callback: (...args: any[]) => void) {
        this._connection?.on("MaxVoteCountChanged", callback);
    }
    
    registerActionItemsUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("ActionItemsUpdated", callback);
    }
    
    registerActionItemDeleted(callback: (...args: any[]) => void) {
        this._connection?.on("ActionItemDeleted", callback);
    }
    
    registerCommentTypesFilterUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("CommentTypesFilterUpdated", callback);
    }
    
    registerCommentTypeFilterUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("CommentTypeFilterUpdated", callback);
    }
    
    registerLabelFilterUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("LabelFilterUpdated", callback);
    }
    
    registerMemberFilterUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("MemberFilterUpdated", callback);
    }
    
    registerMinVoteCountFilterUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("MinVoteCountFilterUpdated", callback);
    }
    
    registerTopicViewFilterUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("TopicViewFilterUpdated", callback);
    }
    
    registerCommentVotesUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("CommentVotesUpdated", callback);
    }  

    registerActiveStepUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("ActiveStepUpdated", callback);
    }  

    registerRetrospectiveNameUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("RetrospectiveNameUpdated", callback);
    }

    registerMemberCollectDoneUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("MemberCollectDoneUpdated", callback);
    }

    registerIcebreakerStatusUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("IcebreakerStatusUpdated", callback);
    }

    registerIcebreakerQuestionUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("IcebreakerQuestionUpdated", callback);
    }

    registerCommentHighlighted(callback: (...args: any[]) => void) {
        this._connection?.on("CommentHighlighted", callback);
    }

    registerVotesReset(callback: (...args: any[]) => void) {
        this._connection?.on("VotesReset", callback);
    }

    registerTimerStarted(callback: (...args: any[]) => void) {
        this._connection?.on("TimerStarted", callback);
    }

    registerTimerStopped(callback: (...args: any[]) => void) {
        this._connection?.on("TimerStopped", callback);
    }

    registerTimerPaused(callback: (...args: any[]) => void) {
        this._connection?.on("TimerPaused", callback);
    }

    registerTimerResumed(callback: (...args: any[]) => void) {
        this._connection?.on("TimerResumed", callback);
    }

    registerGroupingRestrictionRuleChanged(callback: (...args: any[]) => void) {
        this._connection?.on("GroupingRestrictionRuleChanged", callback);
    }

    registerGroupingSuggestionRuleChanged(callback: (...args: any[]) => void) {
        this._connection?.on("GroupingSuggestionRuleChanged", callback);
    }

    registerGroupingFilteringRuleChanged(callback: (...args: any[]) => void) {
        this._connection?.on("GroupingFilteringRuleChanged", callback);
    }

    registerGroupSuggestionStatusChanged(callback: (...args: any[]) => void) {
        this._connection?.on("GroupSuggestionStatusChanged", callback);
    }

    registerGroupSuggestionCommentRemoved(callback: (...args: any[]) => void) {
        this._connection?.on("GroupSuggestionCommentRemoved", callback);
    }

    registerIcebreakerSelectedQuestionCategoriesUpdated(callback: (...args: any[]) => void) {
        this._connection?.on("IcebreakerSelectedQuestionCategoriesUpdated", callback);
    }

    registerHubMethodFailed(callback: (...args: any[]) => void) {
        this._connection?.on("HubMethodFailed", callback);
    }

    async joinRetrospective({ member }: ReturnType<typeof actions.joinRetrospective>) {
        await this._invoke("JoinRetrospective", member.id);
    }

    async leaveRetrospective({ memberId }: ReturnType<typeof actions.leaveRetrospective>) {
        await this._invoke("LeaveRetrospective", memberId);
    }

    async closeRetrospective() {
        await this._invoke("CloseRetrospective");
    }

    async revealComments({ commentsInfos, activeStepId }: ReturnType<typeof actions.revealComments>) {
        await this._invoke("RevealComments", commentsInfos, activeStepId);
    }

    async updateActiveStep({ activeStepId }: ReturnType<typeof actions.updateActiveStep>) {
        await this._invoke("UpdateActiveStep", activeStepId);
    }

    async addComment({ commentId, memberId, text, commentTypeId, isAnonymous, authorColor, authorFirstName, authorLastName, authorEmail, authorAvatar }: ReturnType<typeof actions.addComment>) {
        await this._invoke("AddComment", commentId, memberId, text, commentTypeId, isAnonymous, authorColor, authorFirstName, authorLastName, authorEmail, authorAvatar);
    }

    async moveComment() {
        await this._invoke("MoveComment");
    }

    async stackComment({ sourceCommentId, targetCommentId }: ReturnType<typeof actions.stackComment>) {
        await this._invoke("StackComment", sourceCommentId, targetCommentId);
    }

    async unstackComment({ commentId }: ReturnType<typeof actions.unstackComment>) {
        await this._invoke("UnstackComment", commentId);
    }

    async updateComment({ commentId, text, isAnonymous, memberId, memberColor, memberFirstName, memberLastName, memberEmail, memberAvatar }: ReturnType<typeof actions.updateComment>) {
        await this._invoke("UpdateComment", commentId, text, isAnonymous, memberId, memberColor, memberFirstName, memberLastName, memberEmail, memberAvatar);
    }

    async deleteComment() {
        await this._invoke("DeleteComment");
    }

    async addLabel() {
        await this._invoke("AddLabel");
    }

    async deleteLabel({ labelId }: ReturnType<typeof actions.deleteLabel>) {
        await this._invoke("DeleteLabel", labelId);
    }

    async addLabelToComment({ commentId, labelId }: ReturnType<typeof actions.addLabelToComment>) {
        await this._invoke("AddLabelToComment", commentId, labelId);
    }

    async removeLabelFromComment({ commentId, labelId }: ReturnType<typeof actions.removeLabelFromComment>) {
        await this._invoke("RemoveLabelFromComment", commentId, labelId);
    }

    async changeMaxVoteCount({ maxVoteCount }: ReturnType<typeof actions.changeMaxVoteCount>) {
        await this._invoke("ChangeMaxVoteCount", maxVoteCount);
    }

    async updateRetrospectiveName({ name }: ReturnType<typeof actions.updateRetrospectiveName>) {
        await this._invoke("UpdateRetrospectiveName", name);
    }

    async addActionItem() {
        await this._invoke("AddActionItem");
    }

    async updateActionItem() {
        await this._invoke("UpdateActionItem");
    }

    async deleteActionItem() {
        await this._invoke("DeleteActionItem");
    }

    async updateCommentTypesFilter({ commentTypeIds }: ReturnType<typeof actions.updateCommentTypesFilter>) {
        await this._invoke("UpdateCommentTypesFilter", commentTypeIds);
    }

    async updateCommentTypeFilter({ commentTypeId }: ReturnType<typeof actions.updateCommentTypeFilter>) {
        await this._invoke("UpdateCommentTypeFilter", commentTypeId);
    }

    async updateLabelFilter({ labelId }: ReturnType<typeof actions.updateLabelFilter>) {
        await this._invoke("UpdateLabelFilter", labelId);
    }

    async updateMemberFilter({ memberId }: ReturnType<typeof actions.updateMemberFilter>) {
        await this._invoke("UpdateMemberFilter", memberId);
    }

    async updateMinVoteCountFilter({ minVoteCount }: ReturnType<typeof actions.updateMinVoteCountFilter>) {
        await this._invoke("UpdateMinVoteCountFilter", minVoteCount);
    }

    async updateTopicViewFilter({ isTopicView }: ReturnType<typeof actions.updateTopicViewFilter>) {
        await this._invoke("UpdateTopicViewFilter", isTopicView);
    }

    async updateCommentVotes({ commentId }: ReturnType<typeof actions.broadcastCommentVotesUpdated>) {
        await this._invoke("UpdateCommentVotes", commentId);
    }

    async removeMemberFromRetrospective({ memberId }: ReturnType<typeof actions.removeMemberFromRetrospective>) {
        await this._invoke("RemoveMemberFromRetrospective", memberId);
    }

    async updateMemberCollectDone({ memberId, isCollectDone }: ReturnType<typeof actions.updateMemberCollectDone>) {
        await this._invoke("UpdateMemberCollectDone", memberId, isCollectDone);
    }

    async updateIcebreakerStatus({ isDone }: ReturnType<typeof actions.updateIcebreakerStatus>) {
        await this._invoke("UpdateIcebreakerStatus", isDone);
    }

    async updateIcebreakerQuestion({ questionId }: ReturnType<typeof actions.updateIcebreakerQuestion>) {
        await this._invoke("UpdateIcebreakerQuestion", questionId);
    }

    async highlightComment({ commentId, memberId, memberEmail, memberFirstName, memberLastName, memberColor, memberAvatar }: ReturnType<typeof actions.highlightComment>) {
        await this._invoke("HighlightComment", commentId, memberId, memberEmail, memberFirstName, memberLastName, memberColor, memberAvatar);
    }

    async startTimer({ timerEnd, timerSound }: ReturnType<typeof actions.startTimer>) {
        await this._invoke("StartTimer", timerEnd, timerSound);
    }

    async stopTimer() {
        await this._invoke("StopTimer");
    }

    async pauseTimer({ timerPause }: ReturnType<typeof actions.pauseTimer>) {
        await this._invoke("PauseTimer", timerPause);
    }

    async resumeTimer({ timerEnd }: ReturnType<typeof actions.resumeTimer>) {
        await this._invoke("ResumeTimer", timerEnd);
    }

    async resetVotes() {
        await this._invoke("ResetVotes");
    }

    async changeGroupingRestrictionRule({ rule }: ReturnType<typeof actions.changeGroupingRestrictionRule>) {
        await this._invoke("ChangeGroupingRestrictionRule", rule);
    }

    async changeGroupingSuggestionRule({ rule }: ReturnType<typeof actions.changeGroupingSuggestionRule>) {
        await this._invoke("ChangeGroupingSuggestionRule", rule);
    }

    async changeGroupingFilteringRule({ rule }: ReturnType<typeof actions.changeGroupingFilteringRule>) {
        await this._invoke("ChangeGroupingFilteringRule", rule);
    }

    async changeGroupSuggestionStatus({ parentCommentId, isApproved }: ReturnType<typeof actions.changeGroupSuggestionStatus>) {
        await this._invoke("ChangeGroupSuggestionStatus", parentCommentId, isApproved);
    }

    async removeGroupSuggestionComment({ commentId }: ReturnType<typeof actions.removeGroupSuggestionComment>) {
        await this._invoke("RemoveGroupSuggestionComment", commentId);
    }

    async updateIcebreakerSelectedQuestionCategories({ selectedQuestionCategoryIds }: ReturnType<typeof actions.updateIcebreakerSelectedQuestionCategories>) {
        await this._invoke("UpdateIcebreakerSelectedQuestionCategories", selectedQuestionCategoryIds);
    }
}

const retrospectiveService = new RetrospectiveService();

export default retrospectiveService;