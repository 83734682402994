import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Favicon from "icons/favicon.png";

import "./appLoading.scss";

const AppLoading = ({ isChild }) => {
    return (
        <div className={classnames("app-loading", {
            "app-loading--child": isChild
        })}><img src={Favicon}/></div>
    );
};

AppLoading.propTypes = {
    isChild: PropTypes.bool
};

export default AppLoading;