import { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import amplitude from "amplitude-js";

const PageViewTracking = ({ children }) => {
    const history = useHistory();

    useEffect(() => {
        amplitude.getInstance().logEvent("page_view", { location: window.location.pathname });

        return history.listen((location) => { 
            amplitude.getInstance().logEvent("page_view", { location: location.pathname });
        }); 
    },[history]); 

    return children;
};

PageViewTracking.propTypes = {
    children: PropTypes.node.isRequired
};

export default PageViewTracking;