import { createStore, combineReducers, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import integrationsReducers from "integrations/reducers";
import compose from "infrastructure/redux/compose";

import retrospectiveMiddleware from "./retrospective/retrospectiveMiddleware";
import retrospectiveReducers from "./retrospective/reducers";
import demoReducers from "./demo/reducers";
import userReducers from "./user/reducers";
import saga from "./retrospective/saga";

var sagaMiddleware = createSagaMiddleware();

const store = createStore(
    combineReducers({
        retrospective: retrospectiveReducers,
        user: userReducers,
        integrations: integrationsReducers,
        demo: demoReducers
    }),
    compose(applyMiddleware(retrospectiveMiddleware, sagaMiddleware))
);

sagaMiddleware.run(saga);

export default store;

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch