import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

import { isProdEnvironment } from "infrastructure/environment";

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: "bacde9cd-0507-4a9e-8ac9-d28e2634958e",
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

if (isProdEnvironment) {
    appInsights.loadAppInsights();
}

export { reactPlugin, appInsights };