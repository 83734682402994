import languages from "common/languages";

import { Comment } from "types/common";

import { addComment, addActionItem, addLabelToComment, spendMemberVote, stackComment, updateMemberCollectDone, suggestParentId, addLabel } from "../actions";

import { RetrospectiveSeed } from "../types";

import { TemplateType, billy, botilda, iAnne, tiboCop, LabelIds, CommentIds } from "./demoData";

enum LearningMatrixColumns {
    Sad = "sad",
    Happy = "happy",
    Ideas = "ideas",
    Kudos = "kudos"
}

enum CustomLabelIds {
    Bugs = "bugs",
    SprintPlanning = "sprintPlanning",
    SprintReview = "sprintReview",
    InterTeams = "interTeams"
}

const getLearningMatrixTemplate = (language?: string) => {
    const retroType: RetrospectiveSeed = {
        commentTypes: [
            {
                id: LearningMatrixColumns.Sad,
                name: language === languages.french ? "Triste" : "Sad",
                description: language === languages.french ? "Choses à changer" : "Things that should be changed",
                imageUrl: "https://app.neatro.io/static/media/retro-column-headers/learningMatrix-sad.png"
            },
            {
                id: LearningMatrixColumns.Happy,
                name: language === languages.french ? "Heureux" : "Happy",
                description: language === languages.french ? "Choses à continuer" : "Things that should be repeated",
                imageUrl: "https://app.neatro.io/static/media/retro-column-headers/learningMatrix-happy.png"
            },
            {
                id: LearningMatrixColumns.Ideas,
                name: language === languages.french ? "Idées" : "Ideas",
                description: language === languages.french ? "Nouvelles idées à essayer" : "New ideas to try",
                imageUrl: "https://app.neatro.io/static/media/retro-column-headers/learningMatrix-ideas.png"
            },
            {
                id: LearningMatrixColumns.Kudos,
                name: language === languages.french ? "Kudos" : "Kudos",
                description: language === languages.french ? "Remercier les meilleurs collègues" : "Thank the best team players",
                imageUrl: "https://app.neatro.io/static/media/retro-column-headers/learningMatrix-kudos.png"
            }
        ],
        filter: {
            isTopicView: false,
            commentTypeIds: [
                LearningMatrixColumns.Sad,
                LearningMatrixColumns.Happy,
                LearningMatrixColumns.Ideas,
                LearningMatrixColumns.Kudos
            ],
            minVoteCount: 1
        },
        commentTypeInputs: [
            {
                commentTypeId: LearningMatrixColumns.Sad,
                text: ""
            },
            {
                commentTypeId: LearningMatrixColumns.Happy,
                text: ""
            },
            {
                commentTypeId: LearningMatrixColumns.Ideas,
                text: ""
            },
            {
                commentTypeId: LearningMatrixColumns.Kudos,
                text: ""
            }
        ],
        background: "D4EBF4",
        retrospectiveType: language === languages.french ? "Matrice d'apprentissage" : "Learning Matrix",
        retrospectiveTypeId: TemplateType.LearningMatrix,
        name: language === languages.french ? "Rétro Sprint 1" : "Sprint Retro #1",
        member: {
            id: "1b207c62-a597-47e6-b0eb-e8d3fa40972b",
            firstName: language === languages.french ? "Vous" : "You",
            lastName: language === languages.french ? "" : "",
            email: "test@test.fr",
            avatar: "https://app.neatro.io/static/media/member-avatars/10f5.png",
            color: "FF0000",
            jobTitle: "",
            language: language ? language.toString() : languages.english,
            isAdministrator: true,
            isFacilitator: true
        }
    };

    return retroType;
};

const getLearningMatrixCommentActions = (language?: string) => {
    const comments = [
        {
            id: CommentIds.Comment1, 
            text: language === languages.french ? "Nous avons rencontré de nombreux bugs critiques." : "We encountered many critical bugs.", 
            commentTypeId: LearningMatrixColumns.Sad, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment2, 
            text: language === languages.french ? "Nous avons fait face à plusieurs problèmes techniques qui ont ralenti notre progression." : "We faced several technical issues that slowed down our progress.", 
            commentTypeId: LearningMatrixColumns.Sad, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment3, 
            text: language === languages.french ? "Les défis techniques ont été un obstacle majeur." : "Technical challenges were a major obstacle.", 
            commentTypeId: LearningMatrixColumns.Sad, 
            isAnonymous: false, 
            memberId: tiboCop.id, 
            authorColor: tiboCop.color, 
            authorFirstName: tiboCop.firstName, 
            authorLastName: tiboCop.lastName, 
            authorEmail: tiboCop.email, 
            isMemberComment: false, 
            authorAvatar: tiboCop.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment4, 
            text: language === languages.french ? "J'ai remarqué qu'il y a eu plusieurs retards à nos réunions d'équipe. Ça nous fait perdre du temps, malheureusement." : "I noticed there have been several delays in our team meetings. It's unfortunately wasting our time.", 
            commentTypeId: LearningMatrixColumns.Sad, 
            isAnonymous: true, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment5, 
            text: language === languages.french ? "On devrait être plus rigoureux et ponctuels sur l'heure d'arrivée à nos mêlées quotidiennes." : "We should be more rigorous and punctual for our daily stand-ups.", 
            commentTypeId: LearningMatrixColumns.Sad, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment6, 
            text: language === languages.french ? "On s'envoie beaucoup trop d'emails. Cela me fait perdre en focus et en efficacité dans mon travail." : "We send way too many emails. It makes me lose focus and efficiency in my work.", 
            commentTypeId: LearningMatrixColumns.Sad, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment7, 
            text: language === languages.french ? "L'objectif de Sprint était assez flou. Je pense que ça a créé de la confusion." : "The Sprint goal was quite unclear. I think it created confusion.", 
            commentTypeId: LearningMatrixColumns.Sad, 
            isAnonymous: false, 
            memberId: iAnne.id, 
            authorColor: iAnne.color, 
            authorFirstName: iAnne.firstName, 
            authorLastName: iAnne.lastName, 
            authorEmail: iAnne.email, 
            isMemberComment: false, 
            authorAvatar: iAnne.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment8, 
            text: language === languages.french ? "Je suis impressionné par notre esprit d'équipe. C'est une véritable force ! 💪" : "I am impressed by our team spirit. It's a real strength! 💪", 
            commentTypeId: LearningMatrixColumns.Happy, 
            isAnonymous: false, 
            memberId: tiboCop.id, 
            authorColor: tiboCop.color, 
            authorFirstName: tiboCop.firstName, 
            authorLastName: tiboCop.lastName, 
            authorEmail: tiboCop.email, 
            isMemberComment: false, 
            authorAvatar: tiboCop.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment9, 
            text: language === languages.french ? "J'ai le sentiment qu'on se soutient les uns les autres, dans la victoire comme dans la défaite." : "I feel that we support each other, in victory as in defeat.", 
            commentTypeId: LearningMatrixColumns.Happy, 
            isAnonymous: false, 
            memberId: iAnne.id, 
            authorColor: iAnne.color, 
            authorFirstName: iAnne.firstName, 
            authorLastName: iAnne.lastName, 
            authorEmail: iAnne.email, 
            isMemberComment: false, 
            authorAvatar: iAnne.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment10, 
            text: language === languages.french ? "Notre équipe est résiliente. Elle est toujours prête à relever les plus grands défis !" : "Our team is resilient. It's always ready to face the biggest challenges!", 
            commentTypeId: LearningMatrixColumns.Happy, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment11, 
            text: language === languages.french ? "J'ai trouvé que notre Spint Review a été très efficace. On a appris des choses intéressantes qui nous seront utiles pour la suite." : "I found our Sprint Review to be very effective. We learned some interesting things that will be useful for us going forward.", 
            commentTypeId: LearningMatrixColumns.Happy, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment12, 
            text: language === languages.french ? "Les stakeholders ont été impressionnés par ce qu'ils ont découvert dans la Sprint Review." : "Stakeholders were impressed by what they discovered in the Sprint Review.", 
            commentTypeId: LearningMatrixColumns.Happy, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment13, 
            text: language === languages.french ? "Notre revue de sprint a été un grand succès." : "Our sprint review was a great success.", 
            commentTypeId: LearningMatrixColumns.Happy, 
            isAnonymous: false, 
            memberId: iAnne.id, 
            authorColor: iAnne.color, 
            authorFirstName: iAnne.firstName, 
            authorLastName: iAnne.lastName, 
            authorEmail: iAnne.email, 
            isMemberComment: false, 
            authorAvatar: iAnne.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment14, 
            text: language === languages.french ? "Nos rencontres hebdomadaires avec l'équipe Marketing ont aidé à mieux aligner nos besoins respectifs." : "Our weekly meetings with the Marketing team have helped to better align our respective needs.", 
            commentTypeId: LearningMatrixColumns.Happy, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment15, 
            text: language === languages.french ? "Des tests automatisés pourraient nous aider à détecter les bugs plus tôt dans le processus de développement." : "Automated tests could help us detect bugs earlier in the development process.", 
            commentTypeId: LearningMatrixColumns.Ideas, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment16, 
            text: language === languages.french ? "Est-ce qu'on aurait la possibilité de faire des tests automatisés ?" : "Would it be possible for us to do automated tests?", 
            commentTypeId: LearningMatrixColumns.Ideas, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment17, 
            text: language === languages.french ? "Je propose que tout retard à une rencontre d'équipe entraîne automatiquement l'achat de bonbons pour le reste de l'équipe 🍬" : "I propose that any delay in a team meeting automatically leads to buying candies for the rest of the team 🍬", 
            commentTypeId: LearningMatrixColumns.Ideas, 
            isAnonymous: true, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment18, 
            text: language === languages.french ? "On pourrait suivre une checklist pour que nos objectifs de Sprint soient S.M.A.R.T." : "We could follow a checklist to make our Sprint goals S.M.A.R.T.", 
            commentTypeId: LearningMatrixColumns.Ideas, 
            isAnonymous: false, 
            memberId: iAnne.id, 
            authorColor: iAnne.color, 
            authorFirstName: iAnne.firstName, 
            authorLastName: iAnne.lastName, 
            authorEmail: iAnne.email, 
            isMemberComment: false, 
            authorAvatar: iAnne.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment19, 
            text: language === languages.french ? "Je voulais juste souligner que je suis heureuse de faire partie de cette belle équipe. 💜 Cœur sur vous toutes et tous." : "I just wanted to say that I am happy to be part of this great team. 💜 Thank you all for being great teammates.", 
            commentTypeId: LearningMatrixColumns.Kudos, 
            isAnonymous: false, 
            memberId: iAnne.id, 
            authorColor: iAnne.color, 
            authorFirstName: iAnne.firstName, 
            authorLastName: iAnne.lastName, 
            authorEmail: iAnne.email, 
            isMemberComment: false, 
            authorAvatar: iAnne.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment20, 
            text: language === languages.french ? "Merci à notre Scrum Master d'avoir pensé à utiliser Neatro. Ça fait plaisir d'avoir ENFIN une expérience de rétro collaborative et ludique." : "Special thanks to our Scrum Master for bringing Neatro up. It's nice to FINALLY have a collaborative and fun retro experience.", 
            commentTypeId: LearningMatrixColumns.Kudos, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment21, 
            text: language === languages.french ? "Merci TiboCop pour ton aide précieuse lors de l'audit de sécurité." : "Thank you TiboCop for your valuable help during the security audit.", 
            commentTypeId: LearningMatrixColumns.Kudos, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment22, 
            text: language === languages.french ? "Merci IAnne de m'avoir accordé un peu de ton temps pour me partager des retours de qualité sur mes maquettes." : "Thank you IAnne for taking some of your time to give me quality feedback on my mockups.", 
            commentTypeId: LearningMatrixColumns.Kudos, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment23, 
            text: language === languages.french ? "Merci à Billy le Bot pour les 🍩 beignets de vendredi dernier." : "Thank you to Billy the Bot for the 🍩 donuts we had last Friday.", 
            commentTypeId: LearningMatrixColumns.Kudos, 
            isAnonymous: false, 
            memberId: tiboCop.id, 
            authorColor: tiboCop.color, 
            authorFirstName: tiboCop.firstName, 
            authorLastName: tiboCop.lastName, 
            authorEmail: tiboCop.email, 
            isMemberComment: false, 
            authorAvatar: tiboCop.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        }
    ];

    const actions: any[] = [];

    const shuffle = (array: Comment[]) => { 
        for (let i = array.length - 1; i > 1; i--) { 
            const j = Math.floor(Math.random() * (i + 1)); 
            [array[i], array[j]] = [array[j], array[i]]; 
        } 
        return array; 
    }; 

    const shuffledComments = shuffle(comments); 

    shuffledComments.forEach(comment => actions.push(addComment(comment.id, comment.text, comment.commentTypeId, comment.isAnonymous, comment.memberId, comment.authorColor, comment.authorFirstName, comment.authorLastName, comment.authorEmail, false, comment.authorAvatar)));
    
    actions.push(updateMemberCollectDone(billy.id, true));
    actions.push(updateMemberCollectDone(botilda.id, true));
    actions.push(updateMemberCollectDone(tiboCop.id, true));
    actions.push(updateMemberCollectDone(iAnne.id, true));

    actions.push(suggestParentId(CommentIds.Comment11, CommentIds.Comment13, true));
    actions.push(suggestParentId(CommentIds.Comment12, CommentIds.Comment13, true));

    actions.push(addLabel(CustomLabelIds.Bugs, "Bugs", "FFB8B8", "teamTest"));
    actions.push(addLabel(CustomLabelIds.SprintPlanning, "Sprint Planning", "C7DEF3", "teamTest"));
    actions.push(addLabel(CustomLabelIds.SprintReview, "Sprint Review", "D1EEC7", "teamTest"));
    actions.push(addLabel(CustomLabelIds.InterTeams, language === languages.french ? "Inter-équipes" : "Inter-Squad", "CECEF2", "teamTest"));

    return actions;
};

const getLearningMatrixGroupActions = () => {
    const actions = [];

    actions.push(addLabelToComment(CommentIds.Comment4, LabelIds.Collaboration));
    actions.push(stackComment(CommentIds.Comment2, CommentIds.Comment1));
    actions.push(addLabelToComment(CommentIds.Comment6, LabelIds.Process));
    actions.push(stackComment(CommentIds.Comment3, CommentIds.Comment1));
    actions.push(addLabelToComment(CommentIds.Comment1, CustomLabelIds.Bugs));
    actions.push(addLabelToComment(CommentIds.Comment6, LabelIds.Collaboration));

    actions.push(stackComment(CommentIds.Comment5, CommentIds.Comment4));
    actions.push(addLabelToComment(CommentIds.Comment4, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment7, CustomLabelIds.SprintPlanning));

    actions.push(stackComment(CommentIds.Comment9, CommentIds.Comment8));
    actions.push(addLabelToComment(CommentIds.Comment14, LabelIds.Collaboration));
    actions.push(stackComment(CommentIds.Comment10, CommentIds.Comment8));
    actions.push(addLabelToComment(CommentIds.Comment8, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment14, CustomLabelIds.InterTeams));

    actions.push(addLabelToComment(CommentIds.Comment23, LabelIds.Fun));
    actions.push(addLabelToComment(CommentIds.Comment13, CustomLabelIds.SprintReview));
    actions.push(addLabelToComment(CommentIds.Comment19, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment20, LabelIds.Collaboration));

    actions.push(stackComment(CommentIds.Comment16, CommentIds.Comment15));
    actions.push(stackComment(CommentIds.Comment18, CommentIds.Comment17));
    actions.push(addLabelToComment(CommentIds.Comment20, LabelIds.Resources));
    actions.push(addLabelToComment(CommentIds.Comment21, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment22, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment15, LabelIds.Process));
    
    return actions;
};

const getLearningMatrixVoteActions = () => {
    const actions = [];

    actions.push(spendMemberVote(billy.id, CommentIds.Comment15));
    actions.push(spendMemberVote(botilda.id, CommentIds.Comment15));
    actions.push(spendMemberVote(tiboCop.id, CommentIds.Comment15));
    actions.push(spendMemberVote(iAnne.id, CommentIds.Comment15));

    actions.push(spendMemberVote(billy.id, CommentIds.Comment15));
    actions.push(spendMemberVote(botilda.id, CommentIds.Comment15));
    actions.push(spendMemberVote(tiboCop.id, CommentIds.Comment15));
    actions.push(spendMemberVote(iAnne.id, CommentIds.Comment17));
    
    actions.push(spendMemberVote(billy.id, CommentIds.Comment17));
    actions.push(spendMemberVote(botilda.id, CommentIds.Comment17));
    actions.push(spendMemberVote(tiboCop.id, CommentIds.Comment20));
    actions.push(spendMemberVote(iAnne.id, CommentIds.Comment20));
    
    return actions;
};

const getLearningMatrixActionPlanActions = (language?: string) => {
    const actions = [];

    actions.push(
        addActionItem(
            language === languages.french ? "Mise en place de tests automatisés sur nos fonctionnalités \"Core\"" : "Setting up automated tests for our 'Core' features.", 
            LabelIds.Process, 
            "process", 
            "000000", 
            undefined, 
            LearningMatrixColumns.Ideas, 
            iAnne.id, 
            iAnne.firstName, 
            iAnne.lastName, 
            iAnne.color, 
            iAnne.email, 
            iAnne.avatar, 
            language === languages.french ? "Mettre en place une suite de tests automatisés pour les fonctionnalités essentielles de l'application (Analytics et Gestion du compte utilisateur) d'ici la fin du prochain Sprint.\n\nAnne s'est proposée pour prendre le lead sur cette initiative." : "Implementing an automated test suite for the essential features of the application (Analytics and User Account Management) by the end of the next Sprint.\n\nIAnne has volunteered to take the lead on this initiative.", 
            new Date(new Date().getTime()+(14*24*60*60*1000)), 
            [
                {
                    id: CommentIds.Comment15, 
                    text: language === languages.french ? "Des tests automatisés pourraient nous aider à détecter les bugs plus tôt dans le processus de développement." : "Automated tests could help us detect bugs earlier in the development process.", 
                    commentTypeId: LearningMatrixColumns.Ideas, 
                    isAnonymous: false, 
                    memberId: billy.id, 
                    authorColor: billy.color, 
                    authorFirstName: billy.firstName, 
                    authorLastName: billy.lastName, 
                    authorEmail: billy.email, 
                    isMemberComment: false, 
                    authorAvatar: billy.avatar,
                    labels: [],
                    commentTypeName: "WrongDataNeedsToBeFixed",
                    isNew: true,
                    myVoteCount: 0,
                    totalVoteCount: 0,
                    position: 0
                }
            ]));
    
    return actions;
};

export { getLearningMatrixTemplate, getLearningMatrixCommentActions, getLearningMatrixGroupActions, getLearningMatrixVoteActions, getLearningMatrixActionPlanActions };