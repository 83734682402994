enum ButtonTheme {
    Default, 
    Warning,
    Black,
    Blank
};

enum ButtonSize {
    Small, 
    Medium
};

export { ButtonTheme, ButtonSize };