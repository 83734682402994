import classnames from "classnames";
import { FC, useState } from "react";
import { useLocation } from "react-router-dom";

import Button from "components/button/Button";

import useCrisp from "../../bootstrapping/hooks/use-crisp";

import QuestionIcon from "svg/icon/questionMark.svg";

import style from "./supportChatButton.module.scss";

const SupportChatButton: FC = () => {
    const [isVisible, setIsVisible] = useState(true);
    const location = useLocation();

    const handleCrispChatClosed = () => {
        setIsVisible(true);

        window.$crisp.push(["do", "chat:hide"]);
    };

    const handleButtonClick = () => {
        window.$crisp.push(["do", "chat:show"]);
        window.$crisp.push(["do", "chat:open"]);
        
        setIsVisible(false);
    
        window.$crisp.push(["on", "chat:closed", handleCrispChatClosed]);
    };

    useCrisp();

    return <Button noPadding id="support-chat-button" className={classnames(style.component, {
        [style.componentHidden]: !isVisible ||
        location.pathname.includes("/freemium") || 
        location.pathname.endsWith("icebreaker") || 
        location.pathname.endsWith("collect") || 
        location.pathname.endsWith("aggregate") || 
        location.pathname.endsWith("vote") || 
        location.pathname.endsWith("actionPlan") || 
        location.pathname.includes("icebreaker-questions/edit") 
    })} onClick={handleButtonClick}>
        <QuestionIcon className={style.icon} />
    </Button>;
};

export default SupportChatButton;