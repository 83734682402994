import * as actionTypes from "./actionTypes";

const defaultState = {
    token: null,
    selectedProject: null,
    projects: []
};

const handleSetToken = (state, { token }) => {  
    return {
        ...state,
        token
    };
};

const handleSetSelectedProject = (state, { selectedProject }) => {  
    return {
        ...state,
        selectedProject
    };
};

const handleSetProjects = (state, { projects }) => {  
    return {
        ...state,
        projects: [
            ...projects
        ]
    };
};

const reducers = (state = defaultState, action) => {
    switch (action.type) {
    case actionTypes.SET_TOKEN:
        return handleSetToken(state, action);
    case actionTypes.SET_SELECTED_PROJECT:
        return handleSetSelectedProject(state, action);   
    case actionTypes.SET_PROJECTS:
        return handleSetProjects(state, action);   
    default:
        return state;
    }
};

export default reducers;