import amplitude from "amplitude-js";

import { isProdEnvironment } from "infrastructure/environment";

const initAmplitude = () => {
    if (isProdEnvironment) {
        amplitude.getInstance().init("245116dda841d0cc411948aef377e989");
    }
};

export default initAmplitude;