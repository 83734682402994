import React, { useRef } from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

import RecaptchaContext from "./RecaptchaContext";

const RecaptchaContextProvider = ({ children }) => {
    const recaptchaRef = useRef();

    return (
        <RecaptchaContext.Provider value={recaptchaRef}>
            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6LdtmPwaAAAAAPrx3ETOqKJzkWDiSIVzyzL2c4wd"/>
            {children}
        </RecaptchaContext.Provider>
    );
};

RecaptchaContextProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default RecaptchaContextProvider;