import { lazy } from "react";

const lazyRetry = (componentImport) =>
    lazy(async() => {
        if (!window.localStorage) {
            return await componentImport();
        }

        const localStorageKey = "lazy-force-refreshed";

        const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem(localStorageKey) || "false");

        try {
            const component = await componentImport();

            window.localStorage.setItem(localStorageKey, "false");

            return component;
        }
        catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                window.localStorage.setItem(localStorageKey, "true");
          
                return window.location.reload();
            }

            throw error;
        }
    });

export default lazyRetry;