import { compose } from "redux";

import { isDevEnvironment } from "infrastructure/environment";

let composeEnchancers = compose;

if (isDevEnvironment) {
    composeEnchancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export default composeEnchancers;
