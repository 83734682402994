import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as Types from "./types";

const initialState: Types.MondayIntegration = {
    token: undefined,
    selectedWorkspace: undefined,
    selectedBoard: undefined,
    selectedGroup: undefined,
    boards: [],
    groups: [],
    workspaces: []
};

const mondaySlice = createSlice({
    name: "monday",
    initialState,
    reducers: {
        setToken(state: Types.MondayIntegration, action: PayloadAction<string>) {
            return {
                ...state,
                token: action.payload
            };
        },
        setWorkspaces(state: Types.MondayIntegration, action: PayloadAction<Types.Workspace[]>) {
            return {
                ...state,
                workspaces: action.payload
            };
        },
        setBoards(state: Types.MondayIntegration, action: PayloadAction<Types.Board[]>) {
            return {
                ...state,
                boards: action.payload
            };
        },
        setGroups(state: Types.MondayIntegration, action: PayloadAction<Types.Group[]>) {
            return {
                ...state,
                groups: action.payload
            };
        },
        setSelectedWorkspace(state: Types.MondayIntegration, action: PayloadAction<Types.Workspace>) {
            return {
                ...state,
                selectedWorkspace: action.payload
            };
        },
        setSelectedBoard(state: Types.MondayIntegration, action: PayloadAction<Types.Board>) {
            return {
                ...state,
                selectedBoard: action.payload
            };
        },
        setSelectedGroup(state: Types.MondayIntegration, action: PayloadAction<Types.Group>) {
            return {
                ...state,
                selectedGroup: action.payload
            };
        }
    },
});

export const { setToken, setBoards, setGroups, setSelectedBoard, setSelectedGroup, setWorkspaces, setSelectedWorkspace } = mondaySlice.actions;

export default mondaySlice.reducer;