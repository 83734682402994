
import { History } from "history";

import TimerSound from "components/timer/timerSound";
import { ActionItem, Label, Member, Comment } from "types/common";
import { RecaptchaContextObject } from "contexts/recaptcha/RecaptchaContext";

import * as actionTypes from "./actionTypes";
import GroupingRestrictionRule from "./groupingRestrictionRule";
import GroupingSuggestionRule from "./groupingSuggestionRule";
import GroupingFilteringRule from "./groupingFilteringRule";

import { CommentInfos, Retrospective, MemberVoteInfos } from "./types";

export const joinRetrospective = (member: Member) => ({ 
    type: actionTypes.JOIN_RETROSPECTIVE,
    member
});

export const leaveRetrospective = (memberId: string) => ({
    type: actionTypes.LEAVE_RETROSPECTIVE,
    memberId
});

export const addComment = (commentId: string, text: string, commentTypeId: string, isAnonymous: boolean, memberId: string, authorColor: string, authorFirstName: string, authorLastName: string, authorEmail: string, isMemberComment: boolean, authorAvatar: string, recaptchaRef: typeof RecaptchaContextObject) => ({ 
    type: actionTypes.ADD_COMMENT,
    commentId,
    memberId,
    text,
    commentTypeId,
    isAnonymous,
    authorColor,
    authorFirstName,
    authorLastName,
    authorEmail,
    authorAvatar,
    isMemberComment,
    isNew: true,
    recaptchaRef
});

export const moveComment = (oldCommentTypeId: string, newCommentTypeId: string, oldPosition: number, newPosition: number, commentId: string) => ({ 
    type: actionTypes.MOVE_COMMENT,
    oldCommentTypeId,
    newCommentTypeId,
    oldPosition,
    newPosition,
    commentId
});

export const stackComment = (sourceCommentId: string, targetCommentId: string) => ({ 
    type: actionTypes.STACK_COMMENT,
    sourceCommentId,
    targetCommentId
});

export const unstackComment = (commentId: string) => ({ 
    type: actionTypes.UNSTACK_COMMENT,
    commentId
});

export const updateComment = (commentId: string, text: string, isAnonymous: boolean, memberId: string, memberColor: string, memberFirstName: string, memberLastName: string, memberEmail: string, memberAvatar: string) => ({
    type: actionTypes.UPDATE_COMMENT,
    commentId,
    text,
    isAnonymous,
    memberId,
    memberColor, 
    memberFirstName, 
    memberLastName,
    memberEmail,
    memberAvatar
});

export const deleteComment = (commentId: string) => ({
    type: actionTypes.DELETE_COMMENT,
    commentId
});

export const fetchRetrospective = (retrospective: Retrospective) => ({ 
    type: actionTypes.FETCH_RETROSPECTIVE,
    retrospective
});

export const fetchMembersVote = (memberVoteInfos: MemberVoteInfos[], memberId: string) => ({ 
    type: actionTypes.FETCH_MEMBERS_VOTE,
    memberVoteInfos,
    memberId
});

export const fetchLabels = (labels: Label[]) => ({ 
    type: actionTypes.FETCH_LABELS,
    labels
});

export const fetchActionItems = (actionItems: ActionItem[]) => ({ 
    type: actionTypes.FETCH_ACTION_ITEMS,
    actionItems
});

export const revealComments = (commentsInfos: CommentInfos, memberId: string, activeStepId: string, language: string) => ({
    type: actionTypes.REVEAL_COMMENTS,
    commentsInfos,
    memberId,
    activeStepId,
    language
});

export const updateActiveStep = (activeStepId: string) => ({ 
    type: actionTypes.UPDATE_ACTIVE_STEP,
    activeStepId
});

export const addLabel = (id: string, name: string, color: string, teamId: string) => ({ 
    type: actionTypes.ADD_LABEL,
    id,
    name, 
    color,
    teamId
});

export const deleteLabel = (teamId: string, labelId: string) => ({ 
    type: actionTypes.DELETE_LABEL,
    teamId,
    labelId
});

export const addLabelToComment = (commentId: string, labelId: string) => ({ 
    type: actionTypes.ADD_LABEL_TO_COMMENT,
    commentId,
    labelId
});

export const removeLabelFromComment = (commentId: string, labelId: string) => ({ 
    type: actionTypes.REMOVE_LABEL_FROM_COMMENT,
    commentId,
    labelId
});

export const addVoteToComment = (commentId: string, memberId: string) => ({ 
    type: actionTypes.ADD_VOTE_TO_COMMENT,
    commentId,
    memberId
});

export const removeVoteFromComment = (commentId: string, memberId: string) => ({ 
    type: actionTypes.REMOVE_VOTE_FROM_COMMENT,
    commentId,
    memberId
});

export const addActionItem = (
    text: string, 
    labelId: string | null, 
    labelName: string | null, 
    labelColor: string | null, 
    labelTeamId: string | null | undefined, 
    commentTypeId: string, 
    memberId: string | null, 
    memberFirstName: string | null | undefined, 
    memberLastName: string | null | undefined, 
    memberColor: string | null | undefined, 
    memberEmail: string | null | undefined, 
    memberAvatar: string | null | undefined, 
    description: string, 
    dueDate?: Date, 
    comments?: Comment[]) => ({ 
    type: actionTypes.ADD_ACTION_ITEM,
    text,
    memberId,
    labelId,
    labelName,
    labelColor,
    labelTeamId,
    commentTypeId,
    memberFirstName, 
    memberLastName, 
    memberColor, 
    memberEmail,
    memberAvatar,
    description,
    dueDate,
    comments
});

export const updateActionItem = (
    actionItemId: string, 
    text: string, 
    memberId: string | null | undefined, 
    memberFirstName: string | null | undefined, 
    memberLastName: string | null | undefined, 
    memberColor: string | null | undefined, 
    memberEmail: string | null | undefined, 
    labelId: string | null, 
    labelName: string | null, 
    labelColor: string | null, 
    labelTeamId: string | null | undefined, 
    memberAvatar: string, 
    description: string, 
    dueDate?: Date, 
    comments?: Comment[]) => ({ 
    type: actionTypes.UPDATE_ACTION_ITEM,
    actionItemId,
    text,
    memberId,
    memberFirstName, 
    memberLastName, 
    memberColor, 
    memberEmail,
    labelId, 
    labelName, 
    labelColor, 
    labelTeamId,
    memberAvatar,
    description,
    dueDate,
    comments
});

export const deleteActionItem = (actionItemId: string) => ({ 
    type: actionTypes.DELETE_ACTION_ITEM,
    actionItemId
});

export const closeRetrospective = (history: History) => ({ 
    type: actionTypes.CLOSE_RETROSPECTIVE,
    history
});

export const changeMaxVoteCount = (maxVoteCount: number) => ({ 
    type: actionTypes.CHANGE_MAX_VOTE_COUNT,
    maxVoteCount
});

export const updateRetrospectiveName = (name: string) => ({ 
    type: actionTypes.UPDATE_RETROSPECTIVE_NAME,
    name
});

export const startWebSocketWatch = (retrospectiveId: string) => ({
    type: actionTypes.START_WEB_SOCKET_WATCH,
    retrospectiveId
});

export const stopWebSocketWatch = (retrospectiveId: string) => ({
    type: actionTypes.STOP_WEB_SOCKET_WATCH,
    retrospectiveId
});

export const updateTopicViewFilter = (isTopicView: boolean) => ({
    type: actionTypes.UPDATE_TOPIC_VIEW_FILTER,
    isTopicView
});

export const updateLabelFilter = (labelId: string) => ({
    type: actionTypes.UPDATE_LABEL_FILTER,
    labelId
});

export const updateMemberFilter = (memberId: string) => ({
    type: actionTypes.UPDATE_MEMBER_FILTER,
    memberId
});

export const updateCommentTypeFilter = (commentTypeId: string) => ({
    type: actionTypes.UPDATE_COMMENT_TYPE_FILTER,
    commentTypeId
});

export const updateCommentTypesFilter = (commentTypeIds: string[]) => ({
    type: actionTypes.UPDATE_COMMENT_TYPES_FILTER,
    commentTypeIds
});

export const updateMinVoteCountFilter = (minVoteCount: number) => ({
    type: actionTypes.UPDATE_MIN_VOTE_COUNT_FILTER,
    minVoteCount
});

export const removeMemberFromRetrospective = (memberId: string) => ({
    type: actionTypes.REMOVE_MEMBER_FROM_RETROSPECTIVE,
    memberId
});

export const updateMemberCollectDone = (memberId: string, isCollectDone: boolean) => ({
    type: actionTypes.UPDATE_MEMBER_COLLECT_DONE,
    memberId,
    isCollectDone
});

export const updateIcebreakerStatus = (isDone: boolean) => ({
    type: actionTypes.UPDATE_ICEBREAKER_STATUS,
    isDone
});

export const updateIcebreakerQuestion = (questionId?: number) => ({
    type: actionTypes.UPDATE_ICEBREAKER_QUESTION,
    questionId
});

export const highlightComment = (commentId: string, memberId: string, memberEmail: string, memberFirstName: string, memberLastName: string, memberColor: string, memberAvatar: string) => ({
    type: actionTypes.HIGHLIGHT_COMMENT,
    commentId,
    memberId,
    memberEmail,
    memberFirstName,
    memberLastName,
    memberColor,
    memberAvatar
});

export const selectCommentType = (commentTypeId: string) => ({
    type: actionTypes.SELECT_COMMENT_TYPE,
    commentTypeId
});

export const unselectCommentType = (commentTypeId: string) => ({
    type: actionTypes.UNSELECT_COMMENT_TYPE,
    commentTypeId
});

export const changeCommentTypeInputText = (commentTypeId: string, text: string) => ({
    type: actionTypes.CHANGE_COMMENT_TYPE_INPUT_TEXT,
    commentTypeId,
    text
});

export const resetVotes = () => ({
    type: actionTypes.RESET_VOTES
});

export const startTimer = (timerEnd: Date, timerSound: TimerSound) => ({
    type: actionTypes.START_TIMER,
    timerEnd,
    timerSound
});

export const stopTimer = () => ({
    type: actionTypes.STOP_TIMER
});

export const pauseTimer = (timerPause: Date) => ({
    type: actionTypes.PAUSE_TIMER,
    timerPause
});

export const resumeTimer = (timerEnd: Date) => ({
    type: actionTypes.RESUME_TIMER,
    timerEnd
});

export const changeGroupingRestrictionRule = (rule: GroupingRestrictionRule) => ({
    type: actionTypes.CHANGE_GROUPING_RESTRICTION_RULE,
    rule
});

export const changeGroupingSuggestionRule = (rule: GroupingSuggestionRule) => ({
    type: actionTypes.CHANGE_GROUPING_SUGGESTION_RULE,
    rule
});

export const changeGroupingFilteringRule = (rule: GroupingFilteringRule) => ({
    type: actionTypes.CHANGE_GROUPING_FILTERING_RULE,
    rule
});

export const changeGroupSuggestionStatus = (parentCommentId: string, isApproved: boolean) => ({
    type: actionTypes.CHANGE_GROUP_SUGGESTION_STATUS,
    parentCommentId,
    isApproved
});

export const removeGroupSuggestionComment = (commentId: string) => ({
    type: actionTypes.REMOVE_GROUP_SUGGESTION_COMMENT,
    commentId
});

export const updateIcebreakerSelectedQuestionCategories = (selectedQuestionCategoryIds: string[], preventWebSocketBroadcast?: boolean) => ({
    type: actionTypes.UPDATE_ICEBREAKER_SELECTED_QUESTION_CATEGORIES,
    selectedQuestionCategoryIds,
    preventWebSocketBroadcast
});

export const broadcastCommentVotesUpdated = (commentId: string) => ({
    type: actionTypes.BROADCAST_COMMENT_VOTES_UPDATED,
    commentId
});

export const broadcastActiveStepUpdated = (activeStepId: string) => ({ 
    type: actionTypes.BROADCAST_ACTIVE_STEP_UPDATED,
    activeStepId
});

export const broadcastRetrospectiveLeft = (memberId: string) => ({
    type: actionTypes.BROADCAST_RETROSPECTIVE_LEFT,
    memberId
});

export const broadcastRetrospectiveClosed = () => ({
    type: actionTypes.BROADCAST_RETROSPECTIVE_CLOSED
});

export const broadcastCommentAdded = (commentId: string, memberId: string, text: string, commentTypeId: string, isAnonymous: boolean, authorColor: string, authorFirstName: string, authorLastName: string, authorEmail: string, isMemberComment: boolean, authorAvatar: string) => ({
    type: actionTypes.BROADCAST_COMMENT_ADDED,
    commentId,
    memberId, 
    text, 
    commentTypeId, 
    isAnonymous, 
    authorColor, 
    authorFirstName, 
    authorLastName, 
    authorEmail, 
    isMemberComment,
    authorAvatar
});

export const broadcastCommentMoved = () => ({
    type: actionTypes.BROADCAST_COMMENT_MOVED
});

export const broadcastCommentStacked = (sourceCommentId: string, targetCommentId: string) => ({
    type: actionTypes.BROADCAST_COMMENT_STACKED,
    sourceCommentId, 
    targetCommentId
});

export const broadcastCommentUnstacked = (commentId: string) => ({
    type: actionTypes.BROADCAST_COMMENT_UNSTACKED,
    commentId
});

export const broadcastCommentUpdated = (commentId: string, text: string, isAnonymous: boolean, memberId: string, memberColor: string, memberFirstName: string, memberLastName: string, memberEmail: string, memberAvatar: string) => ({
    type: actionTypes.BROADCAST_COMMENT_UPDATED,
    commentId,
    text,
    isAnonymous,
    memberId,
    memberColor, 
    memberFirstName, 
    memberLastName,
    memberEmail,
    memberAvatar
});

export const broadcastCommentDeleted = (commentId: string) => ({
    type: actionTypes.BROADCAST_COMMENT_DELETED,
    commentId
});

export const broadcastLabelDeleted = (labelId: string) => ({
    type: actionTypes.BROADCAST_LABEL_DELETED,
    labelId
});

export const broadcastLabelAddedToComment = (commentId: string, labelId: string) => ({
    type: actionTypes.BROADCAST_LABEL_ADDED_TO_COMMENT,
    commentId,
    labelId
});

export const broadcastLabelRemovedFromComment = (commentId: string, labelId: string) => ({
    type: actionTypes.BROADCAST_LABEL_REMOVED_FROM_COMMENT,
    commentId,
    labelId
});

export const broadcastActionItemAdded = () => ({
    type: actionTypes.BROADCAST_ACTION_ITEM_ADDED
});

export const broadcastActionItemUpdated = () => ({
    type: actionTypes.BROADCAST_ACTION_ITEM_UPDATED
});

export const broadcastActionItemDeleted = (actionItemId: string) => ({
    type: actionTypes.BROADCAST_ACTION_ITEM_DELETED,
    actionItemId
});

export const broadcastMaxVoteCountChanged = (maxVoteCount: number) => ({
    type: actionTypes.BROADCAST_MAX_VOTE_COUNT_CHANGED,
    maxVoteCount
});

export const broadcastRetrospectiveNameUpdated = (name: string) => ({
    type: actionTypes.BROADCAST_RETROSPECTIVE_NAME_UPDATED,
    name
});

export const broadcastMemberRemovedFromRetrospective = (memberId: string) => ({
    type: actionTypes.BROADCAST_MEMBER_REMOVED_FROM_RETROSPECTIVE,
    memberId
});

export const broadcastCommentTypesFilterUpdated = (commentTypeIds: string[]) => ({
    type: actionTypes.BROADCAST_COMMENT_TYPES_FILTER_UPDATED,
    commentTypeIds
});

export const broadcastCommentTypeFilterUpdated = (commentTypeId: string) => ({
    type: actionTypes.BROADCAST_COMMENT_TYPE_FILTER_UPDATED,
    commentTypeId
});

export const broadcastLabelFilterUpdated = (labelId: string) => ({
    type: actionTypes.BROADCAST_LABEL_FILTER_UPDATED,
    labelId
});

export const broadcastMemberFilterUpdated = (memberId: string) => ({
    type: actionTypes.BROADCAST_MEMBER_FILTER_UPDATED,
    memberId
});

export const broadcastMinVoteCountFilterUpdated = (minVoteCount: number) => ({
    type: actionTypes.BROADCAST_MIN_VOTE_COUNT_FILTER_UPDATED,
    minVoteCount
});

export const broadcastTopicViewFilterUpdated = (isTopicView: boolean) => ({
    type: actionTypes.BROADCAST_TOPIC_VIEW_FILTER_UPDATED,
    isTopicView
});

export const broadcastLabelAdded = () => ({
    type: actionTypes.BROADCAST_LABEL_ADDED
});

export const broadcastMemberCollectDoneUpdated = (memberId: string, isCollectDone: boolean) => ({
    type: actionTypes.BROADCAST_MEMBER_COLLECT_DONE_UPDATED,
    memberId,
    isCollectDone
});

export const broadcastIcebreakerStatusUpdated = (isDone: boolean) => ({
    type: actionTypes.BROADCAST_ICEBREAKER_STATUS_UPDATED,
    isDone
});

export const broadcastIcebreakerQuestionUpdated = (questionId: string) => ({
    type: actionTypes.BROADCAST_ICEBREAKER_QUESTION_UPDATED,
    questionId
});

export const broadcastCommentHighlighted = (commentId: string, memberId: string, memberEmail: string, memberFirstName: string, memberLastName: string, memberColor: string, memberAvatar: string) => ({
    type: actionTypes.BROADCAST_COMMENT_HIGHLIGHTED,
    commentId,
    memberId,
    memberEmail,
    memberFirstName,
    memberLastName,
    memberColor,
    memberAvatar
});

export const broadcastVotesReset = () => ({
    type: actionTypes.BROADCAST_VOTES_RESET
});

export const listenCommentAdded = (commentId: string, memberId: string, text: string, commentTypeId: string, isAnonymous: boolean, authorColor: string, authorFirstName: string, authorLastName: string, authorEmail: string, isMemberComment: boolean, authorAvatar: string) => ({
    type: actionTypes.LISTEN_COMMENT_ADDED,
    commentId,
    memberId, 
    text, 
    commentTypeId, 
    isAnonymous, 
    authorColor, 
    authorFirstName, 
    authorLastName, 
    authorEmail, 
    isMemberComment,
    authorAvatar
});

export const listenCommentsUpdated = (commentsInfos: CommentInfos, memberId: string, language: string) => ({
    type: actionTypes.LISTEN_COMMENTS_UPDATED,
    commentsInfos,
    memberId,
    language
});

export const listenActiveStepUpdated = (activeStepId: string) => ({ 
    type: actionTypes.LISTEN_ACTIVE_STEP_UPDATED,
    activeStepId
});

export const listenCommentsRevealed = (commentsInfos: CommentInfos, memberId: string, activeStepId: string, language: string) => ({
    type: actionTypes.LISTEN_COMMENTS_REVEALED,
    commentsInfos,
    memberId,
    activeStepId,
    language
});

export const listenRetrospectiveJoined = (member: Member) => ({
    type: actionTypes.LISTEN_RETROSPECTIVE_JOINED,
    member
});

export const listenRetrospectiveLeft = (memberId: string) => ({
    type: actionTypes.LISTEN_RETROSPECTIVE_LEFT,
    memberId
});

export const listenCommentStacked = (sourceCommentId: string, targetCommentId: string) => ({
    type: actionTypes.LISTEN_COMMENT_STACKED,
    sourceCommentId,
    targetCommentId
});

export const listenCommentUnstacked = (commentId: string) => ({
    type: actionTypes.LISTEN_COMMENT_UNSTACKED,
    commentId
});

export const listenCommentUpdated = (commentId: string, text: string, isAnonymous: boolean, memberId: string, memberColor: string, memberFirstName: string, memberLastName: string, memberEmail: string, memberAvatar: string) => ({
    type: actionTypes.LISTEN_COMMENT_UPDATED,
    commentId,
    text,
    isAnonymous,
    memberId,
    memberColor, 
    memberFirstName, 
    memberLastName,
    memberEmail,
    memberAvatar
});

export const listenLabelDeleted = (labelId: string) => ({
    type: actionTypes.LISTEN_LABEL_DELETED,
    labelId
});

export const listenLabelAddedToComment = (commentId: string, labelId: string) => ({
    type: actionTypes.LISTEN_LABEL_ADDED_TO_COMMENT,
    labelId,
    commentId
});

export const listenLabelRemovedFromComment = (commentId: string, labelId: string) => ({
    type: actionTypes.LISTEN_LABEL_REMOVED_FROM_COMMENT,
    labelId,
    commentId
});

export const listenActionItemUpdated = (actionItemId: string, memberId: string, text: string, labelId: string) => ({
    type: actionTypes.LISTEN_ACTION_ITEM_UPDATED,
    actionItemId, 
    memberId, 
    text, 
    labelId
});

export const listenActionItemDeleted = (actionItemId: string) => ({
    type: actionTypes.LISTEN_ACTION_ITEM_DELETED,
    actionItemId
});

export const listenMaxVoteCountChanged = (maxVoteCount: number) => ({
    type: actionTypes.LISTEN_MAX_VOTE_COUNT_CHANGED,
    maxVoteCount
});

export const listenRetrospectiveNameUpdated = (name: string) => ({
    type: actionTypes.LISTEN_RETROSPECTIVE_NAME_UPDATED,
    name
});

export const listenMemberRemovedFromRetrospective = (memberId: string) => ({
    type: actionTypes.LISTEN_MEMBER_REMOVED_FROM_RETROSPECTIVE,
    memberId
});

export const listenCommentTypesFilterUpdated = (commentTypeIds: string[]) => ({
    type: actionTypes.LISTEN_COMMENT_TYPES_FILTER_UPDATED,
    commentTypeIds
});

export const listenCommentTypeFilterUpdated = (commentTypeId: string) => ({
    type: actionTypes.LISTEN_COMMENT_TYPE_FILTER_UPDATED,
    commentTypeId
});

export const listenLabelFilterUpdated = (labelId: string) => ({
    type: actionTypes.LISTEN_LABEL_FILTER_UPDATED,
    labelId
});

export const listenMemberFilterUpdated = (memberId: string) => ({
    type: actionTypes.LISTEN_MEMBER_FILTER_UPDATED,
    memberId
});

export const listenMinVoteCountFilterUpdated = (minVoteCount: number) => ({
    type: actionTypes.LISTEN_MIN_VOTE_COUNT_FILTER_UPDATED,
    minVoteCount
});

export const listenTopicViewFilterUpdated = (isTopicView: boolean) => ({
    type: actionTypes.LISTEN_TOPIC_VIEW_FILTER_UPDATED,
    isTopicView
});

export const listenLabelAdded = () => ({
    type: actionTypes.LISTEN_LABEL_ADDED
});

export const listenMemberCollectDoneUpdated = (memberId: string, isCollectDone: boolean) => ({
    type: actionTypes.LISTEN_MEMBER_COLLECT_DONE_UPDATED,
    memberId,
    isCollectDone
});

export const listenIcebreakerStatusUpdated = (isDone: boolean) => ({
    type: actionTypes.LISTEN_ICEBREAKER_STATUS_UPDATED,
    isDone
});

export const listenIcebreakerQuestionUpdated = (questionId: string) => ({
    type: actionTypes.LISTEN_ICEBREAKER_QUESTION_UPDATED,
    questionId
});

export const listenRetrospectiveClosed = () => ({
    type: actionTypes.LISTEN_RETROSPECTIVE_CLOSED
});

export const listenCommentHighlighted = (commentId: string, memberId: string, memberEmail: string, memberFirstName: string, memberLastName: string, memberColor: string, memberAvatar: string) => ({
    type: actionTypes.LISTEN_COMMENT_HIGHLIGHTED,
    commentId,
    memberId,
    memberEmail,
    memberFirstName,
    memberLastName,
    memberColor,
    memberAvatar
});

export const listenVotesReset = () => ({
    type: actionTypes.LISTEN_VOTES_RESET
});

export const listenStartTimer = (timerEnd: Date, timerSound: Date) => ({
    type: actionTypes.LISTEN_START_TIMER,
    timerEnd,
    timerSound
});

export const listenStopTimer = () => ({
    type: actionTypes.LISTEN_STOP_TIMER
});

export const listenPauseTimer = (timerPause: Date) => ({
    type: actionTypes.LISTEN_PAUSE_TIMER,
    timerPause
});

export const listenResumeTimer = (timerEnd: Date) => ({
    type: actionTypes.LISTEN_RESUME_TIMER,
    timerEnd
});

export const listenGroupingRestrictionRuleChanged = (rule: GroupingRestrictionRule) => ({
    type: actionTypes.LISTEN_GROUPING_RESTRICTION_RULE_CHANGED,
    rule
});

export const listenGroupingSuggestionRuleChanged = (rule: GroupingRestrictionRule) => ({
    type: actionTypes.LISTEN_GROUPING_SUGGESTION_RULE_CHANGED,
    rule
});

export const listenGroupingFilteringRuleChanged = (rule: GroupingFilteringRule) => ({
    type: actionTypes.LISTEN_GROUPING_FILTERING_RULE_CHANGED,
    rule
});

export const listenGroupSuggestionStatusChanged = (parentCommentId: string, isApproved: boolean) => ({
    type: actionTypes.LISTEN_GROUP_SUGGESTION_STATUS_CHANGED,
    parentCommentId,
    isApproved
});

export const listenGroupSuggestionCommentRemoved = (commentId: string) => ({
    type: actionTypes.LISTEN_GROUP_SUGGESTION_COMMENT_REMOVED,
    commentId
});

export const listenIcebeakerSelectedQuestionCategoriesUpdated = (selectedQuestionCategoryIds: string[]) => ({
    type: actionTypes.LISTEN_ICEBREAKER_SELECTED_QUESTION_CATEGORIES_UPDATED,
    selectedQuestionCategoryIds
});