import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Button from "./Button";
import { ButtonTheme, ButtonSize } from "./styling";

import "./outlineButton.scss";

const propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    theme: PropTypes.oneOf(Object.values(ButtonTheme)),
    size: PropTypes.oneOf(Object.values(ButtonSize)),
    children: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

type OutlineButtonProps = PropTypes.InferProps<typeof propTypes>;

const OutlineButton = React.forwardRef<HTMLButtonElement, OutlineButtonProps>(({ className, isLoading, theme, size, onClick, ...props }: OutlineButtonProps, ref) => {
    const buttonClassName = classNames("outline-button", className, {
        "outline-button--loading": isLoading,
        "outline-button--small": size === ButtonSize.Small,
        "outline-button--warning": theme === ButtonTheme.Warning,
        "outline-button--black": theme === ButtonTheme.Black,
        "outline-button--blank": theme === ButtonTheme.Blank
    });
    
    return <Button ref={ref} className={buttonClassName} isLoading={isLoading} onClick={onClick} {...props}></Button>;
});

OutlineButton.propTypes = propTypes;

export default OutlineButton;