const NAMESPACE = "DEMO";

export const SUGGEST_PARENT_ID = `${NAMESPACE}/SUGGEST_PARENT_ID`;
export const SKIP_ROTI = `${NAMESPACE}/SKIP_ROTI`;
export const ADD_ROTI_NOTE = `${NAMESPACE}/ADD_ROTI_NOTE`;
export const SPEND_MEMBER_VOTE = `${NAMESPACE}/SPEND_MEMBER_VOTE`;
export const SEED_RETROSPECTIVE = `${NAMESPACE}/SEED_RETROSPECTIVE`;
export const JOIN_RETROSPECTIVE = `${NAMESPACE}/JOIN_RETROSPECTIVE`;
export const LEAVE_RETROSPECTIVE = `${NAMESPACE}/LEAVE_RETROSPECTIVE`;
export const ADD_COMMENT = `${NAMESPACE}/ADD_COMMENT`;
export const MOVE_COMMENT = `${NAMESPACE}/MOVE_COMMENT`;
export const STACK_COMMENT = `${NAMESPACE}/STACK_COMMENT`;
export const UNSTACK_COMMENT = `${NAMESPACE}/UNSTACK_COMMENT`;
export const UPDATE_COMMENT = `${NAMESPACE}/UPDATE_COMMENT`;
export const DELETE_COMMENT = `${NAMESPACE}/DELETE_COMMENT`;
export const UPDATE_ACTIVE_STEP = `${NAMESPACE}/UPDATE_ACTIVE_STEP`;
export const ADD_LABEL = `${NAMESPACE}/ADD_LABEL`;
export const DELETE_LABEL = `${NAMESPACE}/DELETE_LABEL`;
export const ADD_LABEL_TO_COMMENT = `${NAMESPACE}/ADD_LABEL_TO_COMMENT`;
export const REMOVE_LABEL_FROM_COMMENT = `${NAMESPACE}/REMOVE_LABEL_FROM_COMMENT`;
export const ADD_VOTE_TO_COMMENT = `${NAMESPACE}/ADD_VOTE_TO_COMMENT`;
export const REMOVE_VOTE_FROM_COMMENT = `${NAMESPACE}/REMOVE_VOTE_FROM_COMMENT`;
export const ADD_ACTION_ITEM = `${NAMESPACE}/ADD_ACTION_ITEM`;
export const UPDATE_ACTION_ITEM = `${NAMESPACE}/UPDATE_ACTION_ITEM`;
export const DELETE_ACTION_ITEM = `${NAMESPACE}/DELETE_ACTION_ITEM`;
export const CLOSE_RETROSPECTIVE = `${NAMESPACE}/CLOSE_RETROSPECTIVE`;
export const CHANGE_MAX_VOTE_COUNT = `${NAMESPACE}/CHANGE_MAX_VOTE_COUNT`;
export const UPDATE_RETROSPECTIVE_NAME = `${NAMESPACE}/UPDATE_RETROSPECTIVE_NAME`;
export const UPDATE_TOPIC_VIEW_FILTER = `${NAMESPACE}/UPDATE_TOPIC_VIEW_FILTER`;
export const UPDATE_LABEL_FILTER = `${NAMESPACE}/UPDATE_LABEL_FILTER`;
export const UPDATE_MEMBER_FILTER = `${NAMESPACE}/UPDATE_MEMBER_FILTER`;
export const UPDATE_COMMENT_TYPE_FILTER = `${NAMESPACE}/UPDATE_COMMENT_TYPE_FILTER`;
export const UPDATE_COMMENT_TYPES_FILTER = `${NAMESPACE}/UPDATE_COMMENT_TYPES_FILTER`;
export const UPDATE_MIN_VOTE_COUNT_FILTER = `${NAMESPACE}/UPDATE_MIN_VOTE_COUNT_FILTER`;
export const REMOVE_MEMBER_FROM_RETROSPECTIVE = `${NAMESPACE}/REMOVE_MEMBER_FROM_RETROSPECTIVE`;
export const UPDATE_RETROSPECTIVE = `${NAMESPACE}/UPDATE_RETROSPECTIVE`;
export const UPDATE_OTHERS_RETROSPECTIVE = `${NAMESPACE}/UPDATE_OTHERS_RETROSPECTIVE`;
export const REVEAL_COMMENTS = `${NAMESPACE}/REVEAL_COMMENTS`;
export const UPDATE_MEMBER_COLLECT_DONE = `${NAMESPACE}/UPDATE_MEMBER_COLLECT_DONE`;
export const UPDATE_ICEBREAKER_STATUS = `${NAMESPACE}/UPDATE_ICEBREAKER_STATUS`;
export const UPDATE_ICEBREAKER_QUESTION = `${NAMESPACE}/UPDATE_ICEBREAKER_QUESTION`;
export const HIGHLIGHT_COMMENT = `${NAMESPACE}/HIGHLIGHT_COMMENT`;
export const SELECT_COMMENT_TYPE = `${NAMESPACE}/SELECT_COMMENT_TYPE`;
export const UNSELECT_COMMENT_TYPE = `${NAMESPACE}/UNSELECT_COMMENT_TYPE`;
export const CHANGE_COMMENT_TYPE_INPUT_TEXT = `${NAMESPACE}/CHANGE_COMMENT_TYPE_INPUT_TEXT`;
export const RESET_VOTES = `${NAMESPACE}/RESET_VOTES`;
export const START_TIMER = `${NAMESPACE}/START_TIMER`;
export const STOP_TIMER = `${NAMESPACE}/STOP_TIMER`;
export const PAUSE_TIMER = `${NAMESPACE}/PAUSE_TIMER`;
export const RESUME_TIMER = `${NAMESPACE}/RESUME_TIMER`;
export const CHANGE_GROUPING_RESTRICTION_RULE = `${NAMESPACE}/CHANGE_GROUPING_RESTRICTION_RULE`;
export const CHANGE_GROUPING_SUGGESTION_RULE = `${NAMESPACE}/CHANGE_GROUPING_SUGGESTION_RULE`;
export const CHANGE_GROUPING_FILTERING_RULE = `${NAMESPACE}/CHANGE_GROUPING_FILTERING_RULE`;
export const CHANGE_GROUP_SUGGESTION_STATUS = `${NAMESPACE}/CHANGE_GROUP_SUGGESTION_STATUS`;
export const REMOVE_GROUP_SUGGESTION_COMMENT = `${NAMESPACE}/REMOVE_GROUP_SUGGESTION_COMMENT`;
export const UPDATE_ICEBREAKER_SELECTED_QUESTION_CATEGORIES = `${NAMESPACE}/UPDATE_ICEBREAKER_SELECTED_QUESTION_CATEGORIES`;

export const SET_SCROLL_VIEW = `${NAMESPACE}/SET_SCROLL_VIEW`;