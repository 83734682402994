import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as Types from "./types";

const initialState: Types.GithubIntegration = {
    token: undefined,
    selectedRepo: undefined,
    selectedLabels: [],
    labels: [],
    repos: []
};

const githubSlice = createSlice({
    name: "github",
    initialState,
    reducers: {
        setToken(state: Types.GithubIntegration, action: PayloadAction<string>) {
            return {
                ...state,
                token: action.payload
            };
        },
        setRepos(state: Types.GithubIntegration, action: PayloadAction<Types.Repo[]>) {
            return {
                ...state,
                repos: action.payload
            };
        },
        setLabels(state: Types.GithubIntegration, action: PayloadAction<Types.Label[]>) {
            return {
                ...state,
                labels: action.payload
            };
        },
        setSelectedRepo(state: Types.GithubIntegration, action: PayloadAction<Types.Repo>) {
            return {
                ...state,
                selectedRepo: action.payload
            };
        },
        setSelectedLabels(state: Types.GithubIntegration, action: PayloadAction<Types.Label[]>) {
            return {
                ...state,
                selectedLabels: action.payload
            };
        }
    },
});

export const { setToken, setRepos, setSelectedRepo, setLabels, setSelectedLabels } = githubSlice.actions;

export default githubSlice.reducer;