import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "./Button";
import { ButtonTheme, ButtonSize } from "./styling";

import "./fullButton.scss";

const propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(Object.values(ButtonSize)),
    children: PropTypes.node,
    theme: PropTypes.oneOf(Object.values(ButtonTheme)),
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    id: PropTypes.string
};

type FullButtonProps = PropTypes.InferProps<typeof propTypes> & {
    type?: "button" | "submit" | "reset"
};

const FullButton = React.forwardRef<HTMLButtonElement, FullButtonProps>(({ className, size, children, theme, ...props }: FullButtonProps, ref) => {
    const fullButtonClassName = classnames("full-button", className, {
        "full-button--black": theme === ButtonTheme.Black,
        "full-button--warning": theme === ButtonTheme.Warning,
        "full-button--blank": theme === ButtonTheme.Blank
    });

    return <Button ref={ref} className={fullButtonClassName} size={size} {...props}>{children}</Button>;
});

FullButton.propTypes = propTypes;

export default FullButton;