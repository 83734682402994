import { combineReducers } from "redux";

import azureReducers from "./azure/reducers";
import jiraReducers from "./jira/reducers";
import iceScrumReducers from "./iceScrum/reducers";
import asanaReducers from "./asana/reducers";
import githubReducers from "./github/reducers";
import mondayReducers from "./monday/reducers";

const integrationReducers = combineReducers({
    azure: azureReducers,
    jira: jiraReducers,
    iceScrum: iceScrumReducers,
    asana: asanaReducers,
    github: githubReducers,
    monday: mondayReducers
});

export default integrationReducers;