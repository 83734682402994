import languages from "common/languages";

import { Comment } from "types/common";
import { addComment, addActionItem, addLabelToComment, spendMemberVote, stackComment, updateMemberCollectDone, suggestParentId, addLabel } from "../actions";

import { RetrospectiveSeed } from "../types";

import { TemplateType, billy, botilda, iAnne, tiboCop, LabelIds, CommentIds } from "./demoData";

enum EndOfYearColumns {
    Facts = "facts",
    Learnings = "learnings",
    Wishes = "wishes",
    Resolutions = "resolutions"
}

enum CustomLabelIds {
    Recognition = "recognition",
    RocketStatistics = "rocketStatistics",
    InterTeams = "interTeams"
}

const getEndOfYearTemplate = (language?: string) => {
    const retroType: RetrospectiveSeed = {
        commentTypes: [
            {
                id: EndOfYearColumns.Facts,
                name: language === languages.french ? "Les faits marquants" : "Highlights",
                description: language === languages.french ? "Quels sont les moments forts de l'année ?" : "What are the most significant moments of the year?",
                imageUrl: "https://app.neatro.io/static/media/retro-column-headers/endOfYear-highlights.png"
            },
            {
                id: EndOfYearColumns.Learnings,
                name: language === languages.french ? "Les enseignements tirés" : "Lessons learned",
                description: language === languages.french ? "Ce que j'ai appris durant l'année." : "What I've learned through the year.",
                imageUrl: "https://app.neatro.io/static/media/retro-column-headers/endOfYear-lessons.png"
            },
            {
                id: EndOfYearColumns.Wishes,
                name: language === languages.french ? "Mes souhaits" : "My Hopes",
                description: language === languages.french ? "Ce que j'espère pour l'équipe pour l'an prochain." : "Everything I hope for the team for next year.",
                imageUrl: "https://app.neatro.io/static/media/retro-column-headers/endOfYear-myHopes.png"
            },
            {
                id: EndOfYearColumns.Resolutions,
                name: language === languages.french ? "Mes résolutions" : "My Resolutions",
                description: language === languages.french ? "Comment puis-je aider l'équipe à s'améliorer ?" : "Anything I could do to help the team improve?",
                imageUrl: "https://app.neatro.io/static/media/retro-column-headers/endOfYear-resolutions.png"
            }
        ],
        filter: {
            isTopicView: false,
            commentTypeIds: [
                EndOfYearColumns.Facts,
                EndOfYearColumns.Learnings,
                EndOfYearColumns.Wishes,
                EndOfYearColumns.Resolutions
            ],
            minVoteCount: 1
        },
        commentTypeInputs: [
            {
                commentTypeId: EndOfYearColumns.Facts,
                text: ""
            },
            {
                commentTypeId: EndOfYearColumns.Learnings,
                text: ""
            },
            {
                commentTypeId: EndOfYearColumns.Wishes,
                text: ""
            },
            {
                commentTypeId: EndOfYearColumns.Resolutions,
                text: ""
            }
        ],
        background: "C6DAD7",
        retrospectiveType: language === languages.french ? "La rétro de fin d'année" : "The end-of-year retro",
        retrospectiveTypeId: TemplateType.EndOfYear,
        name: language === languages.french ? "Rétro Sprint 1" : "Sprint Retro #1",
        member: {
            id: "1b207c62-a597-47e6-b0eb-e8d3fa40972b",
            firstName: language === languages.french ? "Vous" : "You",
            lastName: language === languages.french ? "" : "",
            email: "test@test.fr",
            avatar: "https://app.neatro.io/static/media/member-avatars/10f5.png",
            color: "FF0000",
            jobTitle: "",
            language: language ? language.toString() : languages.english,
            isAdministrator: true,
            isFacilitator: true
        }
    };

    return retroType;
};

const getEndOfYearCommentActions = (language?: string) => {
    const comments = [
        {
            id: CommentIds.Comment1, 
            text: language === languages.french ? "La sortie de Rocket Statistics, notre tout nouveau produit ! 🚀📈" : "The release of Rocket Statistics, our whole new product! 🚀📈", 
            commentTypeId: EndOfYearColumns.Facts, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment2, 
            text: language === languages.french ? "Je suis si fier de faire partie de l'aventure Rocket Statistics." : "I'm so proud to be part of the Rocket Statistics adventure.", 
            commentTypeId: EndOfYearColumns.Facts, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment3, 
            text: language === languages.french ? "Le lancement de Rocket Statistics !" : "Launching Rocket Statistics!", 
            commentTypeId: EndOfYearColumns.Facts, 
            isAnonymous: false, 
            memberId: tiboCop.id, 
            authorColor: tiboCop.color, 
            authorFirstName: tiboCop.firstName, 
            authorLastName: tiboCop.lastName, 
            authorEmail: tiboCop.email, 
            isMemberComment: false, 
            authorAvatar: tiboCop.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment4, 
            text: language === languages.french ? "Notre équipe a remporté le 'Prix de la Meilleure Innovation' pour notre expérience d'onboarding utilisateur assistée par IA." : "Our team won the 'Best Innovation Award' at the annual company retreat for our AI-driven user onboarding experience.", 
            commentTypeId: EndOfYearColumns.Facts, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment5, 
            text: language === languages.french ? "Le Prix de la meilleure innovation ! 🏆 #NousLeMéritions" : "Winning the Best Innovation Award! 🏆 #WeDeservedIt", 
            commentTypeId: EndOfYearColumns.Facts, 
            isAnonymous: false, 
            memberId: iAnne.id, 
            authorColor: iAnne.color, 
            authorFirstName: iAnne.firstName, 
            authorLastName: iAnne.lastName, 
            authorEmail: iAnne.email, 
            isMemberComment: false, 
            authorAvatar: iAnne.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment6, 
            text: language === languages.french ? "Nous avons étendu notre base de clients à de nouveaux marchés, ce qui nous aidera à stimuler la croissance de l'entreprise à l'avenir." : "We expanded our customer base into new markets, which will help us drive company growth in the future.", 
            commentTypeId: EndOfYearColumns.Facts, 
            isAnonymous: false, 
            memberId: tiboCop.id, 
            authorColor: tiboCop.color, 
            authorFirstName: tiboCop.firstName, 
            authorLastName: tiboCop.lastName, 
            authorEmail: tiboCop.email, 
            isMemberComment: false, 
            authorAvatar: tiboCop.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment7, 
            text: language === languages.french ? "Réunir des développeurs, des designers et un chef de projet dans notre équipe nous a été très bénéfique." : "Bringing together developers, designers and a project manager in our team has been a decisive move.", 
            commentTypeId: EndOfYearColumns.Learnings, 
            isAnonymous: false, 
            memberId: tiboCop.id, 
            authorColor: tiboCop.color, 
            authorFirstName: tiboCop.firstName, 
            authorLastName: tiboCop.lastName, 
            authorEmail: tiboCop.email, 
            isMemberComment: false, 
            authorAvatar: tiboCop.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment8, 
            text: language === languages.french ? "Assembler une équipe multidisciplinaire nous a permis de livrer de la valeur beaucoup plus rapidement." : "Assembling a multidisciplinary team has helped us deliver value much faster.", 
            commentTypeId: EndOfYearColumns.Learnings, 
            isAnonymous: false, 
            memberId: iAnne.id, 
            authorColor: iAnne.color, 
            authorFirstName: iAnne.firstName, 
            authorLastName: iAnne.lastName, 
            authorEmail: iAnne.email, 
            isMemberComment: false, 
            authorAvatar: iAnne.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment9, 
            text: language === languages.french ? "Notre équipe possède désormais toutes les compétences nécessaires pour livrer un travail de haute qualité." : "Our team now possesses all the skills needed to deliver high-quality work.", 
            commentTypeId: EndOfYearColumns.Learnings, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment10, 
            text: language === languages.french ? "Nous avons revu notre mode de collaboration en télétravail, ce qui a réduit les malentendus et les retards sur nos projets." : "Clear communication in remote work significantly reduced misunderstandings and project delays", 
            commentTypeId: EndOfYearColumns.Learnings, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment11, 
            text: language === languages.french ? "Je pense que nous avons amélioré notre approche du télétravail cette année. Nous avons tiré des leçons de l'année dernière." : "I think we've stepped up our remote work game this year. We learned from some tough experiences encountered last year.", 
            commentTypeId: EndOfYearColumns.Learnings, 
            isAnonymous: false, 
            memberId: iAnne.id, 
            authorColor: iAnne.color, 
            authorFirstName: iAnne.firstName, 
            authorLastName: iAnne.lastName, 
            authorEmail: iAnne.email, 
            isMemberComment: false, 
            authorAvatar: iAnne.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment12, 
            text: language === languages.french ? "J'ai appris que l'échec est une opportunité de croissance et d'apprentissage." : "I learned that failure is an opportunity for growth and learning.", 
            commentTypeId: EndOfYearColumns.Learnings, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment13, 
            text: language === languages.french ? "J'aimerais une collaboration plus étroite avec notre équipe Marketing pour améliorer l'onboarding des utilisateurs de Rocket Statistics." : "I hope for deeper collaboration with our Marketing team to improve user onboarding for Rocket Statistics.", 
            commentTypeId: EndOfYearColumns.Wishes, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment14, 
            text: language === languages.french ? "Plus de points de suivi entre notre équipe et la team Marketing l'année prochaine." : "I'd like to see more integrated efforts between our team and Marketing in the coming year.", 
            commentTypeId: EndOfYearColumns.Wishes, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment15, 
            text: language === languages.french ? "Coordonner les efforts de Marketing avec ceux de notre équipe devrait améliorer l'expérience d'onboarding des utilisateurs." : "Coordinating marketing with our team should enhance the user onboarding experience.", 
            commentTypeId: EndOfYearColumns.Wishes, 
            isAnonymous: true, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment16, 
            text: language === languages.french ? "Nos rétrospectives sont devenues plus efficaces avec Neatro. J'espère que nous continuerons à l'utiliser l'année prochaine." : "Our retrospectives have become more effective with Neatro. I hope we continue using it next year.", 
            commentTypeId: EndOfYearColumns.Wishes, 
            isAnonymous: false, 
            memberId: iAnne.id, 
            authorColor: iAnne.color, 
            authorFirstName: iAnne.firstName, 
            authorLastName: iAnne.lastName, 
            authorEmail: iAnne.email, 
            isMemberComment: false, 
            authorAvatar: iAnne.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment17, 
            text: language === languages.french ? "Je consacrerai du temps à encadrer les membres juniors de l'équipe pour les aider à grandir." : "I will dedicate time to mentor junior team members to help them grow.", 
            commentTypeId: EndOfYearColumns.Resolutions, 
            isAnonymous: true, 
            memberId: iAnne.id, 
            authorColor: iAnne.color, 
            authorFirstName: iAnne.firstName, 
            authorLastName: iAnne.lastName, 
            authorEmail: iAnne.email, 
            isMemberComment: false, 
            authorAvatar: iAnne.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment18, 
            text: language === languages.french ? "J'aimerais contribuer à un environnement d'équipe plus inclusif en développant mon écoute active." : "I aim to foster a more inclusive team environment through active listening and support.", 
            commentTypeId: EndOfYearColumns.Resolutions, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment19, 
            text: language === languages.french ? "J'organiserai des sessions mensuelles de partage de connaissances pour que tout le monde soit au courant des dernières tendances du secteur." : "I'll organize monthly knowledge-sharing sessions to keep everyone updated on industry trends.", 
            commentTypeId: EndOfYearColumns.Resolutions, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment20, 
            text: language === languages.french ? "Je vais améliorer notre processus de documentation pour que tout le monde ait accès facilement aux éléments-clés de nos projets." : "I will enhance our documentation process to ensure all workflows are well-documented and accessible.", 
            commentTypeId: EndOfYearColumns.Resolutions, 
            isAnonymous: false, 
            memberId: tiboCop.id, 
            authorColor: tiboCop.color, 
            authorFirstName: tiboCop.firstName, 
            authorLastName: tiboCop.lastName, 
            authorEmail: tiboCop.email, 
            isMemberComment: false, 
            authorAvatar: tiboCop.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        }
    ];

    const actions: any[] = [];

    const shuffle = (array: Comment[]) => { 
        for (let i = array.length - 1; i > 1; i--) { 
            const j = Math.floor(Math.random() * (i + 1)); 
            [array[i], array[j]] = [array[j], array[i]]; 
        } 
        return array; 
    }; 

    const shuffledComments = shuffle(comments); 

    shuffledComments.forEach(comment => actions.push(addComment(comment.id, comment.text, comment.commentTypeId, comment.isAnonymous, comment.memberId, comment.authorColor, comment.authorFirstName, comment.authorLastName, comment.authorEmail, false, comment.authorAvatar)));
    
    actions.push(updateMemberCollectDone(billy.id, true));
    actions.push(updateMemberCollectDone(botilda.id, true));
    actions.push(updateMemberCollectDone(tiboCop.id, true));
    actions.push(updateMemberCollectDone(iAnne.id, true));

    actions.push(suggestParentId(CommentIds.Comment14, CommentIds.Comment13, true));
    actions.push(suggestParentId(CommentIds.Comment15, CommentIds.Comment13, true));

    actions.push(addLabel(CustomLabelIds.RocketStatistics, language === languages.french ? "Rocket Statistics" : "Rocket Statistics", "FFB8B8", "teamTest"));
    actions.push(addLabel(CustomLabelIds.Recognition, language === languages.french ? "Reconnaissance" : "Recognition", "C7DEF3", "teamTest"));
    actions.push(addLabel(CustomLabelIds.InterTeams, language === languages.french ? "Inter-équipes" : "Inter-Squad", "CECEF2", "teamTest"));

    return actions;
};

const getEndOfYearGroupActions = () => {
    const actions = [];

    actions.push(addLabelToComment(CommentIds.Comment4, CustomLabelIds.Recognition));
    actions.push(stackComment(CommentIds.Comment2, CommentIds.Comment1));
    actions.push(stackComment(CommentIds.Comment3, CommentIds.Comment1));
    actions.push(addLabelToComment(CommentIds.Comment1, CustomLabelIds.RocketStatistics));
    actions.push(stackComment(CommentIds.Comment5, CommentIds.Comment4));
    actions.push(addLabelToComment(CommentIds.Comment6, LabelIds.Mission));

    actions.push(stackComment(CommentIds.Comment8, CommentIds.Comment7));
    actions.push(stackComment(CommentIds.Comment9, CommentIds.Comment7));
    actions.push(addLabelToComment(CommentIds.Comment7, LabelIds.Roles));
    actions.push(addLabelToComment(CommentIds.Comment7, LabelIds.Ownership));
    actions.push(stackComment(CommentIds.Comment11, CommentIds.Comment10));
    actions.push(addLabelToComment(CommentIds.Comment10, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment10, LabelIds.Process));
    actions.push(addLabelToComment(CommentIds.Comment12, LabelIds.Learnings));

    actions.push(addLabelToComment(CommentIds.Comment13, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment13, CustomLabelIds.InterTeams));
    actions.push(addLabelToComment(CommentIds.Comment16, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment16, LabelIds.Resources));

    actions.push(addLabelToComment(CommentIds.Comment17, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment17, LabelIds.Learnings));
    actions.push(addLabelToComment(CommentIds.Comment18, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment19, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment19, LabelIds.Learnings));
    actions.push(addLabelToComment(CommentIds.Comment20, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment20, LabelIds.Process));
    
    return actions;
};

const getEndOfYearVoteActions = () => {
    const actions = [];

    actions.push(spendMemberVote(botilda.id, CommentIds.Comment12));
    actions.push(spendMemberVote(billy.id, CommentIds.Comment12));
    actions.push(spendMemberVote(tiboCop.id, CommentIds.Comment15));
    actions.push(spendMemberVote(iAnne.id, CommentIds.Comment15));

    actions.push(spendMemberVote(botilda.id, CommentIds.Comment15));
    actions.push(spendMemberVote(billy.id, CommentIds.Comment13));
    actions.push(spendMemberVote(tiboCop.id, CommentIds.Comment13));
    actions.push(spendMemberVote(iAnne.id, CommentIds.Comment13));
    
    actions.push(spendMemberVote(botilda.id, CommentIds.Comment13));
    actions.push(spendMemberVote(billy.id, CommentIds.Comment13));
    actions.push(spendMemberVote(tiboCop.id, CommentIds.Comment13));
    actions.push(spendMemberVote(iAnne.id, CommentIds.Comment13));
    
    return actions;
};

const getEndOfYearActionPlanActions = (language?: string) => {
    const actions = [];

    actions.push(
        addActionItem(
            language === languages.french ? "Créer un journal des modifications du projet" : "Create a Project Change Log", 
            LabelIds.Collaboration, 
            "process", 
            "000000", 
            undefined, 
            EndOfYearColumns.Wishes, 
            iAnne.id, 
            iAnne.firstName, 
            iAnne.lastName, 
            iAnne.color, 
            iAnne.email, 
            iAnne.avatar, 
            language === languages.french ? "Mettre en place un journal des modifications centralisé pour documenter tous les changements significatifs du projet, y compris la justification et l'impact. Rendre le journal des modifications opérationnel d'ici 14 jours.\n\nAnne s'est proposée pour prendre le lead sur cette initiative." : "Set up a change log in a centralized location to document all significant project changes, including rationale and impact, within one day of each change. Make the change log live within 14 days.\n\nIAnne has volunteered to take the lead on this initiative.", 
            new Date(new Date().getTime()+(14*24*60*60*1000)), 
            [
                {
                    id: CommentIds.Comment13, 
                    text: language === languages.french ? "J'aimerais une collaboration plus étroite avec notre équipe Marketing pour améliorer l'onboarding des utilisateurs de Rocket Statistics." : "I hope for deeper collaboration with our Marketing team to improve user onboarding for Rocket Statistics.", 
                    commentTypeId: EndOfYearColumns.Wishes, 
                    isAnonymous: false, 
                    memberId: billy.id, 
                    authorColor: billy.color, 
                    authorFirstName: billy.firstName, 
                    authorLastName: billy.lastName, 
                    authorEmail: billy.email, 
                    isMemberComment: false, 
                    authorAvatar: billy.avatar,
                    labels: [],
                    commentTypeName: "WrongDataNeedsToBeFixed",
                    isNew: true,
                    myVoteCount: 0,
                    totalVoteCount: 0,
                    position: 0
                }
            ]));
    
    return actions;
};

export { getEndOfYearTemplate, getEndOfYearCommentActions, getEndOfYearGroupActions, getEndOfYearVoteActions, getEndOfYearActionPlanActions };