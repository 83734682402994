import languages from "common/languages";

import { Comment } from "types/common";
import { addComment, addActionItem, addLabelToComment, spendMemberVote, stackComment, updateMemberCollectDone, suggestParentId, addLabel } from "../actions";

import { RetrospectiveSeed } from "../types";

import { TemplateType, billy, botilda, iAnne, tiboCop, LabelIds, CommentIds } from "./demoData";

enum DakiColumns {
    Drop = "drop",
    Add = "add",
    Keep = "keep",
    Improve = "improve"
}

enum CustomLabelIds {
    Recognition = "recognition",
    ProjectUpdates = "projectUpdates",
}

const getDakiTemplate = (language?: string) => {
    const retroType: RetrospectiveSeed = {
        commentTypes: [
            {
                id: DakiColumns.Drop,
                name: language === languages.french ? "Drop" : "Drop",
                description: language === languages.french ? "Choses que vous souhaitez arrêter" : "Things you would like to drop",
                imageUrl: "https://app.neatro.io/static/media/retro-column-headers/daki-drop.png"
            },
            {
                id: DakiColumns.Add,
                name: language === languages.french ? "Add" : "Add",
                description: language === languages.french ? "Choses que vous aimeriez essayer" : "Things you would like to add",
                imageUrl: "https://app.neatro.io/static/media/retro-column-headers/daki-add.png"
            },
            {
                id: DakiColumns.Keep,
                name: language === languages.french ? "Keep" : "Keep",
                description: language === languages.french ? "Choses que vous souhaitez garder" : "Things you would like to keep",
                imageUrl: "https://app.neatro.io/static/media/retro-column-headers/daki-keep.png"
            },
            {
                id: DakiColumns.Improve,
                name: language === languages.french ? "Improve" : "Improve",
                description: language === languages.french ? "Choses que vous souhaitez améliorer" : "Things you would like to improve",
                imageUrl: "https://app.neatro.io/static/media/retro-column-headers/daki-improve.png"
            }
        ],
        filter: {
            isTopicView: false,
            commentTypeIds: [
                DakiColumns.Drop,
                DakiColumns.Add,
                DakiColumns.Keep,
                DakiColumns.Improve
            ],
            minVoteCount: 1
        },
        commentTypeInputs: [
            {
                commentTypeId: DakiColumns.Drop,
                text: ""
            },
            {
                commentTypeId: DakiColumns.Add,
                text: ""
            },
            {
                commentTypeId: DakiColumns.Keep,
                text: ""
            },
            {
                commentTypeId: DakiColumns.Improve,
                text: ""
            }
        ],
        background: "D4EBF4",
        retrospectiveType: language === languages.french ? "D.A.K.I" : "D.A.K.I",
        retrospectiveTypeId: TemplateType.Daki,
        name: language === languages.french ? "Rétro Sprint 1" : "Sprint Retro #1",
        member: {
            id: "1b207c62-a597-47e6-b0eb-e8d3fa40972b",
            firstName: language === languages.french ? "Vous" : "You",
            lastName: language === languages.french ? "" : "",
            email: "test@test.fr",
            avatar: "https://app.neatro.io/static/media/member-avatars/10f5.png",
            color: "FF0000",
            jobTitle: "",
            language: language ? language.toString() : languages.english,
            isAdministrator: true,
            isFacilitator: true
        }
    };

    return retroType;
};

const getDakiCommentActions = (language?: string) => {
    const comments = [
        {
            id: CommentIds.Comment1, 
            text: language === languages.french ? "Des modifications non documentées de scope du projet ont entraîné des malentendus importants." : "Undocumented project scope changes led to significant misunderstandings.", 
            commentTypeId: DakiColumns.Drop, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment2, 
            text: language === languages.french ? "Nous n'avons pas réussi à communiquer clairement sur certains changements de dernière minute du périmètre de projet." : "We failed to communicate clearly about unexpected project changes.", 
            commentTypeId: DakiColumns.Drop, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment3, 
            text: language === languages.french ? "Il est acceptable d'apporter des mises à jour sur le scope de projet, mais nous devons mieux les communiquer pour éviter toute confusion." : "Bringing scope updates is fine, but we need to communicate them more effectively.", 
            commentTypeId: DakiColumns.Drop, 
            isAnonymous: false, 
            memberId: tiboCop.id, 
            authorColor: tiboCop.color, 
            authorFirstName: tiboCop.firstName, 
            authorLastName: tiboCop.lastName, 
            authorEmail: tiboCop.email, 
            isMemberComment: false, 
            authorAvatar: tiboCop.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment4, 
            text: language === languages.french ? "Éliminons les réunions non structurées - utilisons des ordres du jour pour gagner en temps et en efficacité !" : "Drop unstructured meetings - use agendas to save time.", 
            commentTypeId: DakiColumns.Drop, 
            isAnonymous: false, 
            memberId: iAnne.id, 
            authorColor: iAnne.color, 
            authorFirstName: iAnne.firstName, 
            authorLastName: iAnne.lastName, 
            authorEmail: iAnne.email, 
            isMemberComment: false, 
            authorAvatar: iAnne.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment5, 
            text: language === languages.french ? "Mettre en place des tests utilisateur en amont du projet pour identifier rapidement les problèmes et améliorer la qualité du livrable." : "Implement early user testing to identify issues quickly and enhance deliverable quality.", 
            commentTypeId: DakiColumns.Add, 
            isAnonymous: false, 
            memberId: tiboCop.id, 
            authorColor: tiboCop.color, 
            authorFirstName: tiboCop.firstName, 
            authorLastName: tiboCop.lastName, 
            authorEmail: tiboCop.email, 
            isMemberComment: false, 
            authorAvatar: tiboCop.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment6, 
            text: language === languages.french ? "Introduire des sessions de retour d'expérience utilisateur pour mieux détecter et résoudre les problèmes." : "Introduce preliminary user feedback sessions to better detect and resolve problems.", 
            commentTypeId: DakiColumns.Add, 
            isAnonymous: false, 
            memberId: iAnne.id, 
            authorColor: iAnne.color, 
            authorFirstName: iAnne.firstName, 
            authorLastName: iAnne.lastName, 
            authorEmail: iAnne.email, 
            isMemberComment: false, 
            authorAvatar: iAnne.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment7, 
            text: language === languages.french ? "Mettre en place un programme de mentorat pour soutenir les nouveaux membres de l'équipe." : "Introduce a mentorship program to support new team members.", 
            commentTypeId: DakiColumns.Add, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment8, 
            text: language === languages.french ? "Je ne dirais pas non à une sortie pizza en équipe tous les vendredis. 🍕" : "I wouldn't say no to a Pizza dinner every Friday. 🍕", 
            commentTypeId: DakiColumns.Add, 
            isAnonymous: true, 
            memberId: tiboCop.id, 
            authorColor: tiboCop.color, 
            authorFirstName: tiboCop.firstName, 
            authorLastName: tiboCop.lastName, 
            authorEmail: tiboCop.email, 
            isMemberComment: false, 
            authorAvatar: tiboCop.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment9, 
            text: language === languages.french ? "Continuons à documenter les comptes-rendus de réunion pour que tout le monde soit informé en temps réel." : "Let's continue the practice of documenting meeting minutes to ensure everyone is informed.", 
            commentTypeId: DakiColumns.Keep, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment10, 
            text: language === languages.french ? "Poursuivons la célébration des petites victoires ! Cela renforce le moral de l'équipe." : "Keep celebrating small wins to boost team morale and motivation.", 
            commentTypeId: DakiColumns.Keep, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment11, 
            text: language === languages.french ? "Je suggère de continuer à faire nos rétrospectives sur Neatro. Elles sont très efficaces !" : "I suggest we continue running our retrospectives on Neatro; they are very effective!", 
            commentTypeId: DakiColumns.Keep, 
            isAnonymous: false, 
            memberId: tiboCop.id, 
            authorColor: tiboCop.color, 
            authorFirstName: tiboCop.firstName, 
            authorLastName: tiboCop.lastName, 
            authorEmail: tiboCop.email, 
            isMemberComment: false, 
            authorAvatar: tiboCop.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment12, 
            text: language === languages.french ? "Continuons à utiliser Neatro. C'est un moyen facile pour nous de documenter les étapes clés de nos projets." : "Let's keep using Neatro. It's an easy way for us to document key milestones of our projects.", 
            commentTypeId: DakiColumns.Keep, 
            isAnonymous: false, 
            memberId: iAnne.id, 
            authorColor: iAnne.color, 
            authorFirstName: iAnne.firstName, 
            authorLastName: iAnne.lastName, 
            authorEmail: iAnne.email, 
            isMemberComment: false, 
            authorAvatar: iAnne.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment13, 
            text: language === languages.french ? "Je pense qu'un journal des modifications du projet pourrait nous aider à mieux gérer les changements de scope." : "I think a Project Change Log could help us improve scope update management.", 
            commentTypeId: DakiColumns.Improve, 
            isAnonymous: false, 
            memberId: botilda.id, 
            authorColor: botilda.color, 
            authorFirstName: botilda.firstName, 
            authorLastName: botilda.lastName, 
            authorEmail: botilda.email, 
            isMemberComment: false, 
            authorAvatar: botilda.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment14, 
            text: language === languages.french ? "On devrait améliorer la documentation des décisions en cours de projet pour plus de transparence et de clarté." : "Improve documentation of decisions and changes.", 
            commentTypeId: DakiColumns.Improve, 
            isAnonymous: false, 
            memberId: billy.id, 
            authorColor: billy.color, 
            authorFirstName: billy.firstName, 
            authorLastName: billy.lastName, 
            authorEmail: billy.email, 
            isMemberComment: false, 
            authorAvatar: billy.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment15, 
            text: language === languages.french ? "Assurons-nous que chaque invitation à une réunion inclut un ordre du jour pour garder nos réunions structurées et productives." : "Make sure we share a meeting agenda in every invitation.", 
            commentTypeId: DakiColumns.Improve, 
            isAnonymous: false, 
            memberId: iAnne.id, 
            authorColor: iAnne.color, 
            authorFirstName: iAnne.firstName, 
            authorLastName: iAnne.lastName, 
            authorEmail: iAnne.email, 
            isMemberComment: false, 
            authorAvatar: iAnne.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        },
        {
            id: CommentIds.Comment16, 
            text: language === languages.french ? "Améliorer le processus d'onboarding des nouveaux membres de l'équipe pour les aider à s'intégrer rapidement et facilement." : "Better onboarding process for new team members.", 
            commentTypeId: DakiColumns.Improve, 
            isAnonymous: false, 
            memberId: tiboCop.id, 
            authorColor: tiboCop.color, 
            authorFirstName: tiboCop.firstName, 
            authorLastName: tiboCop.lastName, 
            authorEmail: tiboCop.email, 
            isMemberComment: false, 
            authorAvatar: tiboCop.avatar,
            labels: [],
            commentTypeName: "WrongDataNeedsToBeFixed",
            isNew: true,
            myVoteCount: 0,
            totalVoteCount: 0,
            position: 0
        }
    ];

    const actions: any[] = [];

    const shuffle = (array: Comment[]) => { 
        for (let i = array.length - 1; i > 1; i--) { 
            const j = Math.floor(Math.random() * (i + 1)); 
            [array[i], array[j]] = [array[j], array[i]]; 
        } 
        return array; 
    }; 

    const shuffledComments = shuffle(comments); 

    shuffledComments.forEach(comment => actions.push(addComment(comment.id, comment.text, comment.commentTypeId, comment.isAnonymous, comment.memberId, comment.authorColor, comment.authorFirstName, comment.authorLastName, comment.authorEmail, false, comment.authorAvatar)));
    
    actions.push(updateMemberCollectDone(billy.id, true));
    actions.push(updateMemberCollectDone(botilda.id, true));
    actions.push(updateMemberCollectDone(tiboCop.id, true));
    actions.push(updateMemberCollectDone(iAnne.id, true));

    actions.push(suggestParentId(CommentIds.Comment14, CommentIds.Comment13, true));

    actions.push(addLabel(CustomLabelIds.ProjectUpdates, language === languages.french ? "MAJ Projet" : "Project Updates", "CECEF2", "teamTest"));
    actions.push(addLabel(CustomLabelIds.Recognition, language === languages.french ? "Reconnaissance" : "Recognition", "C7DEF3", "teamTest"));

    return actions;
};

const getDakiGroupActions = () => {
    const actions = [];

    actions.push(addLabelToComment(CommentIds.Comment4, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment1, LabelIds.Process));
    actions.push(stackComment(CommentIds.Comment2, CommentIds.Comment1));
    actions.push(stackComment(CommentIds.Comment3, CommentIds.Comment1));
    actions.push(addLabelToComment(CommentIds.Comment1, CustomLabelIds.ProjectUpdates));

    actions.push(stackComment(CommentIds.Comment6, CommentIds.Comment5));
    actions.push(addLabelToComment(CommentIds.Comment5, LabelIds.Process));
    actions.push(addLabelToComment(CommentIds.Comment5, LabelIds.Value));

    actions.push(addLabelToComment(CommentIds.Comment7, LabelIds.Roles));
    actions.push(addLabelToComment(CommentIds.Comment7, LabelIds.Collaboration));

    actions.push(addLabelToComment(CommentIds.Comment8, LabelIds.Fun));

    actions.push(addLabelToComment(CommentIds.Comment9, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment10, CustomLabelIds.Recognition));

    actions.push(stackComment(CommentIds.Comment12, CommentIds.Comment11));
    actions.push(addLabelToComment(CommentIds.Comment11, LabelIds.Resources));
    actions.push(addLabelToComment(CommentIds.Comment11, LabelIds.Collaboration));

    actions.push(addLabelToComment(CommentIds.Comment13, LabelIds.Process));
    actions.push(addLabelToComment(CommentIds.Comment13, CustomLabelIds.ProjectUpdates));

    actions.push(addLabelToComment(CommentIds.Comment15, LabelIds.Collaboration));
    actions.push(addLabelToComment(CommentIds.Comment16, LabelIds.Roles));
    actions.push(addLabelToComment(CommentIds.Comment16, LabelIds.Collaboration));

    return actions;
};

const getDakiVoteActions = () => {
    const actions = [];

    actions.push(spendMemberVote(botilda.id, CommentIds.Comment12));
    actions.push(spendMemberVote(billy.id, CommentIds.Comment12));
    actions.push(spendMemberVote(tiboCop.id, CommentIds.Comment15));
    actions.push(spendMemberVote(iAnne.id, CommentIds.Comment15));

    actions.push(spendMemberVote(botilda.id, CommentIds.Comment15));
    actions.push(spendMemberVote(billy.id, CommentIds.Comment13));
    actions.push(spendMemberVote(tiboCop.id, CommentIds.Comment13));
    actions.push(spendMemberVote(iAnne.id, CommentIds.Comment13));
    
    actions.push(spendMemberVote(botilda.id, CommentIds.Comment13));
    actions.push(spendMemberVote(billy.id, CommentIds.Comment13));
    actions.push(spendMemberVote(tiboCop.id, CommentIds.Comment13));
    actions.push(spendMemberVote(iAnne.id, CommentIds.Comment13));
    
    return actions;
};

const getDakiActionPlanActions = (language?: string) => {
    const actions = [];

    actions.push(
        addActionItem(
            language === languages.french ? "Créer un journal des modifications du projet" : "Create a Project Change Log", 
            LabelIds.Process, 
            "process", 
            "000000", 
            undefined, 
            DakiColumns.Improve, 
            iAnne.id, 
            iAnne.firstName, 
            iAnne.lastName, 
            iAnne.color, 
            iAnne.email, 
            iAnne.avatar, 
            language === languages.french ? "Mettre en place un journal des modifications centralisé pour documenter tous les changements significatifs du projet, y compris la justification et l'impact. Rendre le journal des modifications opérationnel d'ici 14 jours.\n\nAnne s'est proposée pour prendre le lead sur cette initiative." : "Set up a change log in a centralized location to document all significant project changes, including rationale and impact, within one day of each change. Make the change log live within 14 days.\n\nIAnne has volunteered to take the lead on this initiative.", 
            new Date(new Date().getTime()+(14*24*60*60*1000)), 
            [
                {
                    id: CommentIds.Comment13, 
                    text: language === languages.french ? "Je pense qu'un journal des modifications du projet pourrait nous aider à mieux gérer les changements de scope." : "I think a Project Change Log could help us improve scope update management.", 
                    commentTypeId: DakiColumns.Improve, 
                    isAnonymous: false, 
                    memberId: botilda.id, 
                    authorColor: botilda.color, 
                    authorFirstName: botilda.firstName, 
                    authorLastName: botilda.lastName, 
                    authorEmail: botilda.email, 
                    isMemberComment: false, 
                    authorAvatar: botilda.avatar,
                    labels: [],
                    commentTypeName: "WrongDataNeedsToBeFixed",
                    isNew: true,
                    myVoteCount: 0,
                    totalVoteCount: 0,
                    position: 0
                }
            ]));
    
    return actions;
};

export { getDakiTemplate, getDakiCommentActions, getDakiGroupActions, getDakiVoteActions, getDakiActionPlanActions };